import PropTypes from 'prop-types'
import styles from './CustomerTag.module.css'
import { defaultCustomerTags } from '../../utils/constants'

const CustomerTag = ({ name }) => {
	return (
		<span
			className={
				`${styles.name} 
				${name === defaultCustomerTags.NEW && styles.new}
				${name === defaultCustomerTags.VIP && styles.vip}
				${name === defaultCustomerTags.FRAUD && styles.fraud}`
			}
		>
			{name}
		</span>
	)
}

CustomerTag.propTypes = {
	name: PropTypes.string
}

export default CustomerTag
