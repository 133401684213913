import { Select, Spin } from 'antd'
import { useRef } from 'react'
import PropTypes from 'prop-types'
require('./CustomSelectSecondary.less')
import styles from './CustomSelectSecondary.module.css'
import { LoadingOutlined } from '@ant-design/icons'

const CustomSelectSecondary = ({
	style,
	className,
	containerClassName,
	dropdownClassName,
	title,
	placeholder,
	disabled,
	allowClear,
	clearIcon,
	options,
	value,
	onChange,
	loading,
	optionLabelProp,
	optionRender,
	dropdownRender,
	popupContainer,
	filterOption,
	filterSort,
	showSearch
}) => {
	const selectRef = useRef(null)

	return (
		<div className={`${styles.customSelectSecondary} ${containerClassName} custom-select-secondary`}>
			<span className={styles.title}>{title}</span>
			{
				loading ?
					<div className={styles.chevronDown}>
						<Spin
							indicator={
								<LoadingOutlined
									style={{ fontSize: 20 }}
									spin
								/>
							}
						/>
					</div> :
					<img className={styles.chevronDown} src='/img/chevron-down.svg' />
			}
			<Select
				style={style}
				className={className}
				dropdownClassName={dropdownClassName}
				placeholder={placeholder}
				showArrow={false}
				allowClear={allowClear}
				disabled={disabled}
				clearIcon={clearIcon}
				options={optionLabelProp && optionRender ? undefined : options}
				value={value}
				onChange={onChange}
				dropdownRender={dropdownRender ? () => dropdownRender(selectRef) : null}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
				ref={selectRef}
				optionLabelProp={optionLabelProp}
				filterOption={filterOption}
				filterSort={filterSort}
				showSearch={showSearch}
			>
				{(optionLabelProp && optionRender) && options.map(option => {
					return (
						<Select.Option key={option.value} value={option.value} label={option.label}>
							{optionRender(option)}
						</Select.Option>
					)
				})}
			</Select>
		</div>
	)
}

CustomSelectSecondary.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
	containerClassName: PropTypes.string,
	dropdownClassName: PropTypes.string,
	title: PropTypes.node,
	placeholder: PropTypes.node,
	disabled: PropTypes.bool,
	allowClear: PropTypes.bool,
	clearIcon: PropTypes.node,
	options: PropTypes.arrayOf(PropTypes.object),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]))]),
	onChange: PropTypes.func,
	loading: PropTypes.bool,
	optionLabelProp: PropTypes.string,
	optionRender: PropTypes.func,
	dropdownRender: PropTypes.func,
	popupContainer: PropTypes.func
}

CustomSelectSecondary.defaultProps = {
	onChange: () => {}
}

export default CustomSelectSecondary
