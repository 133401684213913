import { notification } from 'antd'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SalesOrders } from '../../services/api/sales-orders'
import { orderStatuses, paths } from '../../utils/constants'
import CustomTable from '../table'
import styles from './InvoicePopoverContent.module.css'
import { useSelector } from 'react-redux'

const orderItemColumns = [
	{
		title: 'SL',
		dataIndex: 'sl',
		key: 'sl',
		width: 70
	},
	{
		title: 'Product ID',
		dataIndex: 'productId',
		key: 'productId',
		render: ({ productId, href }) => {
			return (
				<Link href={`${paths.PRODUCTS}/${href}`}>
					<a>{productId ? `${productId}` : 'No ID'}</a>
				</Link>
			)
		}
	},
	{
		title: 'Product Name',
		dataIndex: 'productName',
		key: 'productName'
	},
	{
		title: 'Requested Product Qty',
		dataIndex: 'productQty',
		key: 'productQty'
	},
	{
		title: 'Available Qty',
		dataIndex: 'availableQty',
		key: 'availableQty'
	}
]

const InvoicePopoverContent = ({
	id,
	status
}) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [orderDetails, setOrderDetails] = useState()

	useEffect(() => {
		getSalesOrderDetails(id)
	}, [id])

	const getSalesOrderDetails = async (id) => {
		try {
			const { data } = await SalesOrders.single(id, {}, true)
			setOrderDetails(data)
		} catch (e) {
			notification.error({
				message: 'Could not get order details.',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const getOrderItemColumns = () => {
		if (
			(status === orderStatuses.SALES_ORDER_REQUESTED_STATUS ||
			status === orderStatuses.SALES_ORDER_APPROVED_STATUS ||
			status === orderStatuses.SALES_ORDER_PROCESSING_STATUS) &&
			companyDetails?.isInventoryEnabled
		) {
			return orderItemColumns
		} else {
			return orderItemColumns.filter(column => column.key !== 'availableQty')
		}
	}
	return (
		<>
			{
				orderDetails &&
                <div className={styles.invoiceNo}>
                Invoice No&nbsp;
                	<Link href={`${paths.SALES_ORDERS}/${orderDetails.id}`}>
                		<a>
                        #{orderDetails.internalId}
                		</a>
                	</Link>
                </div>
			}
			<CustomTable
				pagination={false}
				scroll={{ y: '275px' }}
				columns={getOrderItemColumns()}
				loading={orderDetails === undefined}
				dataSource={orderDetails?.salesOrderItems.map(({ id, quantity, product: { name, sku, id: productId, inventoryItems } }, i) => ({
					id,
					sl: i + 1,
					productId: { productId: sku || 'No SKU', href: productId },
					productName: name,
					productQty: quantity,
					availableQty: inventoryItems ? inventoryItems[0]?.quantity ?? 0 : 0
				}))}
				rowKey='id'
				size='small'
			/>
		</>
	)
}

InvoicePopoverContent.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	status: PropTypes.string
}

export default InvoicePopoverContent
