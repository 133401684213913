import PropTypes from 'prop-types'
import Image from 'next/image'
import { getDeliveryPartnerLogo } from '../../utils'
import styles from './DeliveryPartnerOption.module.css'

const DeliveryPartnerOption = ({ label, value, type }) => {
	const { src, alt } = getDeliveryPartnerLogo(type || value)

	return (
		<div className={styles.option}>
			<Image
				src={src}
				width={32}
				height={32}
				alt={alt}
			/>
			{label}
		</div>
	)
}

DeliveryPartnerOption.propTypes = {
	label: PropTypes.node,
	value: PropTypes.string,
	type: PropTypes.string
}

export default DeliveryPartnerOption
