import { Popover, Tooltip } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import { FileTextOutlined, PlusOutlined } from '@ant-design/icons'
import InternalNotesInput from './internal-notes-input'

const InternalNotesPopover = ({ id, notes, onSave, style }) => {
	return (
		<Popover
			style={style}
			key={id}
			placement='topRight'
			content={
				<InternalNotesInput
					id={id}
					addNote={!notes || !notes?.trim().length}
					notes={notes}
					onSave={onSave}
				/>
			}
			trigger='click'
			overlayStyle={{ width: 450 }}
		>
			{
				notes && notes?.trim().length > 0 ?
					<Tooltip title={`${notes}`}>
						<FileTextOutlined
							style={{ fontSize: 20, color: '#288EA5' }}
						/>
					</Tooltip>
					:
					<Tooltip title='Internal Notes'>
						<PlusOutlined
							style={{ fontSize: 20 }}
						/>
					</Tooltip>
			}
		</Popover>
	)
}

InternalNotesPopover.propTypes = {
	id: PropTypes.string,
	notes: PropTypes.string,
	onSave: PropTypes.func,
	style: PropTypes.object
}

InternalNotesPopover.defaultProps = {
	id: '',
	notes: '',
	onSave: () => {},
	style: {}
}

export default InternalNotesPopover
