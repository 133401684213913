import { Button, Checkbox, Modal, notification } from 'antd'
import styles from './ClearCompanyDataModal.module.css'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { industries } from '../../utils/constants'
import { Companies } from '../../services/api/companies'

const eCommerceDataOptions = [
	{
		label: 'Orders',
		value: 'salesOrder'
	},
	{
		label: 'Customers',
		value: 'distributor'
	},
	{
		label: 'Products',
		value: 'product'
	},
	{
		label: 'Purchase Orders',
		value: 'purchaseOrder'
	},
	{
		label: 'Vendors',
		value: 'vendor'
	},
	{
		label: 'Inventory',
		value: 'inventoryItem'
	},
	{
		label: 'Expenses',
		value: 'expense'
	},
	{
		label: 'Reports',
		value: 'report'
	}
]

const dataOptions = [
	{
		label: 'Orders',
		value: 'salesOrder'
	},
	{
		label: 'Customers',
		value: 'distributor'
	},
	{
		label: 'Products',
		value: 'product'
	},
	{
		label: 'Inventory',
		value: 'inventoryItem'
	},
	{
		label: 'Purchase',
		value: 'purchaseOrder'
	},
	{
		label: 'Vendors',
		value: 'vendor'
	},
	{
		label: 'Reports',
		value: 'report'
	},
	{
		label: 'Fleet',
		value: 'vehicle'
	},
	{
		label: 'Transport Vendors',
		value: 'transportVendor'
	},
	{
		label: 'Transport Quotations',
		value: 'transportQuotation'
	},
	{
		label: 'Delivery Plan',
		value: 'deliveryPlan'
	}
]

const ClearCompanyDataModal = ({
	companyId,
	visible,
	onCancel,
	industry
}) => {
	const [selectedData, setSelectedData] = useState({})
	const [isClearingData, setIsClearingData] = useState(false)

	const onSelectData = (value, checked) => {
		const newSelectedData = {
			...selectedData,
			[value]: checked
		}
		if (industry !== industries.E_COMMERCE) {
			switch (value) {
				case 'salesOrder':
					if (checked) {
						newSelectedData['report'] = checked
						newSelectedData['inventoryItem'] = checked
						newSelectedData['deliveryPlan'] = checked
					}
					break
				case 'distributor':
					if (checked) {
						newSelectedData['report'] = checked
						newSelectedData['salesOrder'] = checked
						newSelectedData['inventoryItem'] = checked
						newSelectedData['deliveryPlan'] = checked
					}
					break
				case 'product':
					if (checked) {
						newSelectedData['report'] = checked
						newSelectedData['inventoryItem'] = checked
						newSelectedData['salesOrder'] = checked
						newSelectedData['purchaseOrder'] = checked
						newSelectedData['deliveryPlan'] = checked
					}
					break
				case 'vendor':
					if (checked) {
						newSelectedData['purchaseOrder'] = checked
					}
					break
				case 'vehicle':
					if (checked) {
						if (!newSelectedData['report']) {
							newSelectedData['report'] = 'indeterminate'
						}
					}
					break
				case 'transportVendor':
					if (checked) {
						newSelectedData['transportQuotation'] = checked
					}
					break
				case 'deliveryPlan':
					if (checked) {
						if (!newSelectedData['report']) {
							newSelectedData['report'] = 'indeterminate'
						}
					}
					break
			}
		} else {
			switch (value) {
				case 'salesOrder':
					if (checked) {
						newSelectedData['report'] = checked
						newSelectedData['inventoryItem'] = checked
					}
					break
				case 'distributor':
					if (checked) {
						newSelectedData['report'] = checked
						newSelectedData['salesOrder'] = checked
						newSelectedData['inventoryItem'] = checked
					}
					break
				case 'product':
					if (checked) {
						newSelectedData['report'] = checked
						newSelectedData['inventoryItem'] = checked
						newSelectedData['salesOrder'] = checked
						newSelectedData['purchaseOrder'] = checked
					}
					break
				case 'vendor':
					if (checked) {
						newSelectedData['purchaseOrder'] = checked
					}
					break
			}
		}
		setSelectedData(newSelectedData)
	}

	const isDisabled = (value) => {
		if (industry !== industries.E_COMMERCE) {
			switch (value) {
				case 'report':
					if (selectedData['salesOrder'] || selectedData['distributor'] || selectedData['product'] || selectedData['deliveryPlan'] || selectedData['vehicle']) {
						return true
					}
					break
				case 'inventoryItem':
					if (selectedData['salesOrder'] || selectedData['distributor'] || selectedData['product']) {
						return true
					}
					break
				case 'salesOrder':
					if (selectedData['distributor'] || selectedData['product']) {
						return true
					}
					break
				case 'purchaseOrder':
					if (selectedData['vendor'] || selectedData['product']) {
						return true
					}
					break
				case 'deliveryPlan':
					if (selectedData['salesOrder'] || selectedData['distributor']) {
						return true
					}
					break
				case 'transportQuotation':
					if (selectedData['transportVendor']) {
						return true
					}
					break
			}
		} else {
			switch (value) {
				case 'report':
					if (selectedData['salesOrder'] || selectedData['distributor'] || selectedData['product']) {
						return true
					}
					break
				case 'inventoryItem':
					if (selectedData['salesOrder'] || selectedData['distributor'] || selectedData['product']) {
						return true
					}
					break
				case 'salesOrder':
					if (selectedData['distributor'] || selectedData['product']) {
						return true
					}
					break
				case 'purchaseOrder':
					if (selectedData['vendor'] || selectedData['product']) {
						return true
					}
					break
			}
		}
		return false
	}

	const onClearData = async () => {
		Modal.confirm({
			title: 'Clear Company Data?',
			onOk: async () => {
				try {
					setIsClearingData(true)
					await Companies.clearData(companyId, {
						...selectedData
					})
					notification.success({
						message: 'Company Data Cleared',
						description: 'Company data has been cleared successfully.',
						placement: 'bottomLeft'
					})
					onCancel()
				} catch (e) {
					notification.error({
						message: 'Unable to Clear Company Data',
						description: e.message,
						placement: 'bottomLeft'
					})
				} finally {
					setIsClearingData(false)
				}
			}
		})
	}

	const options = industry === industries.E_COMMERCE ? eCommerceDataOptions : dataOptions

	const buttons = [
		<Button
			key='not-now'
			onClick={onCancel}
			loading={isClearingData}
		>
            Not Now
		</Button>,
		<Button
			key='okay'
			type='primary'
			style={{ marginLeft: '12px' }}
			onClick={onClearData}
			disabled={Object.values(selectedData).every(value => !value)}
			loading={isClearingData}
		>
            Clear
		</Button>
	]
	return (
		<Modal
			visible={visible}
			title='Clear Company Data'
			closable={!isClearingData}
			footer={buttons}
			onCancel={onCancel}
		>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
				{
					options.map(option => {
						return (
							<Checkbox
								style={{ marginLeft: '0' }}
								key={option.value}
								onChange={(e) => onSelectData(option.value, e.target.checked)}
								checked={selectedData[option.value]}
								disabled={isDisabled(option.value)}
								indeterminate={selectedData[option.value] === 'indeterminate'}
							>
								{option.label}
							</Checkbox>
						)
					})
				}
				<p className={styles.title}>Are you sure you want to clear all company data? This action cannot be undone.</p>
			</div>
		</Modal>
	)
}

ClearCompanyDataModal.propTypes = {
	companyId: PropTypes.string,
	visible: PropTypes.bool,
	onCancel: PropTypes.func,
	industry: PropTypes.string
}

export default ClearCompanyDataModal
