import PropTypes from 'prop-types'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styles from './ExpandButton.module.css'

const ExpandButton = ({ isExpanded, onClick, style }) => {
	return (
		<button
			className={styles.expandButton}
			onClick={onClick}
			style={style}
		>
			{
				isExpanded ?
					<RightOutlined style={{ color: '#00ba88' }} />
					:
					<LeftOutlined style={{ color: '#00ba88' }} />
			}
			{
				isExpanded ?
					<LeftOutlined style={{ color: '#00ba88' }} />
					:
					<RightOutlined style={{ color: '#00ba88' }} />
			}
		</button>
	)
}

ExpandButton.propTypes = {
	isExpanded: PropTypes.bool,
	onClick: PropTypes.func,
	style: PropTypes.object
}

ExpandButton.defaultProps = {
	isExpanded: false,
	onClick: () => {},
	style: {}
}

export default ExpandButton
