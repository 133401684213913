import { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, notification } from 'antd'
import Input from '../input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './GeneralCustomerFilter.module.css'
import { customerTypeOptions, customerTypes as CustomerTypes, industries, roles } from '../../utils/constants'
import { Users } from '../../services/api/users'
import { useEffect } from 'react'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { useSelector } from 'react-redux'
import { agricultureSpecificCustomerTypes } from '../../utils/customers'
import CustomSelect from '../custom-select'
import { Locations } from '../../services/api/locations'
import { capitalizeWord } from '../../utils'

const GeneralCustomerFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen
}) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [customerTypes, setCustomerTypes] = useState(searchParams.types || [])
	const [fromDate, setFromDate] = useState(searchParams.fromDate || null)
	const [toDate, setToDate] = useState(searchParams.toDate || null)
	const [lastOrderFromDate, setLastOrderFromDate] = useState(searchParams.lastOrderFromDate || null)
	const [lastOrderToDate, setLastOrderToDate] = useState(searchParams.lastOrderFromDate || null)
	const [noOrderSinceDate, setNoOrderSinceDate] = useState(searchParams.noOrderSinceDate || null)
	const [accountManagers, setAccountManagers] = useState([])
	const [accountManager, setAccountManager] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [districts, setDistricts] = useState([])
	const [divisions, setDivisions] = useState([])
	const [selectedDivision, setSelectedDivision] = useState()
	const [selectedDistrict, setSelectedDistrict] = useState()
	const [isGettingAccountManagers, setIsGettingAccountManagers] = useState(false)

	useEffect(() => {
		if (isFilterDropdownOpen) {
			getInitialAccountManagers()
			fetchDivisions()
			fetchDistricts()
		}
		if (searchParams?.division && searchParams?.district) {
			setSelectedDivision({ value: searchParams?.division?.split(' ')[0].toLowerCase() || ' ', label: searchParams?.division?.split(' ')[0].toUpperCase() || ' ' })
			setSelectedDistrict({ value: searchParams?.district?.split(' ')[0].toLowerCase() || ' ', label: searchParams?.district?.split(' ')[0].toUpperCase() || ' ' })
		}
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!accountManagers.length || !searchParams.accountManagerId) {
			return
		}
		const accountManager = accountManagers.find(({ id }) => id === searchParams.accountManagerId)

		setAccountManager({
			id: accountManager.id,
			key: accountManager.id,
			value: accountManager.id,
			label: accountManager.name,
			data: accountManager
		})
	}, [accountManagers])

	const getInitialAccountManagers = async () => {
		try {
			setIsGettingAccountManagers(true)
			const response = await Users.index({ role: roles.SALES_MANAGER })
			const accountManagers = response.data.results
			setAccountManagers(accountManagers)
			if (searchParams.accountManagerId && accountManagers?.length && isAreaManagerVisible()) {
				const accountManager = accountManagers.find(({ id }) => id === searchParams.accountManagerId)
				setAccountManager(
					accountManager ?
						{
							key: accountManager.id,
							value: accountManager.id,
							label: accountManager.name,
							data: accountManager
						} :
						null
				)
			}
		} finally {
			setIsGettingAccountManagers(false)
		}
	}

	const searchAccountManagers = value => {
		return Users.index({ searchTerm: value, role: roles.SALES_MANAGER })
	}

	const isAreaManagerVisible = () => {
		if (customerTypes.includes(CustomerTypes.E_COMMERCE_CUSTOMER)) {
			return false
		}
		return true
	}

	const fetchDivisions = async (district = {}) => {
		setSelectedDistrict(district)
		try {
			const { data } = await Locations.divisions({ district: district?.value || '' })
			if (district?.value) {
				setSelectedDivision(data[0])
			} else {
				setDivisions(data)
			}
		} catch (e) {
			notification.error({
				message: 'Fetching Divisions Failed',
				description: e.message,
				placement: 'bottomLeft' })
		}
	}

	const fetchDistricts = async (division = {}) => {
		if (division.value) {
			setSelectedDivision(division)
		}
		setSelectedDistrict()
		try {
			const { data } = await Locations.districts({ division: division?.value || '' })
			setDistricts(data)
		} catch (e) {
			notification.error({
				message: 'Fetching Districts Failed',
				description: e.message,
				placement: 'bottomLeft' })
		}
	}

	const isFilterEnabled = () => {
		return searchTerm.trim() || (fromDate && toDate) || (isAreaManagerVisible() && accountManager) || (lastOrderFromDate && lastOrderToDate) || (selectedDivision && selectedDistrict) || customerTypes.length || noOrderSinceDate
	}

	const handleApply = async () => {
		const filters = {
			searchTerm,
			types: customerTypes
		}
		if (fromDate && toDate) {
			filters.fromDate = moment(fromDate).startOf('day').toDate()
			filters.toDate = moment(toDate).endOf('day').toDate()
		}
		if (lastOrderFromDate && lastOrderToDate) {
			filters.lastOrderFromDate = moment(lastOrderFromDate).startOf('day').toDate()
			filters.lastOrderToDate = moment(lastOrderToDate).endOf('day').toDate()
		}
		if (noOrderSinceDate) {
			filters.noOrderSinceDate = moment(noOrderSinceDate).startOf('day').toDate()
		}
		if (selectedDivision?.value && selectedDistrict?.value) {
			filters.division = capitalizeWord(selectedDivision.value) + ' Division'
			filters.district = capitalizeWord(selectedDistrict.value) + ' District'
		}
		if (accountManager && isAreaManagerVisible()) {
			filters.accountManagerId = accountManager.value
		}
		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setLastOrderFromDate(null)
		setLastOrderToDate(null)
		setNoOrderSinceDate(null)
		setAccountManager(null)
		setCustomerTypes([])
		clearFilter()
		setSelectedDistrict()
		setSelectedDivision()
		fetchDivisions()
		fetchDistricts()
	}

	const modifiedCustomerTypeOptions = useMemo(() => {
		if (companyDetails && companyDetails.industry === industries.AGRICULTURE) {
			return customerTypeOptions
		}
		return customerTypeOptions.filter(option => !agricultureSpecificCustomerTypes.includes(option.value))
	}, [companyDetails])

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Customer Types</p>
				<Checkbox.Group
					className={styles.inputGroup}
					value={[...customerTypes]}
					onChange={setCustomerTypes}
				>
					{
						modifiedCustomerTypeOptions.map(option => {
							return (
								<Checkbox
									key={option.value}
									className={styles.inputTitle}
									value={option.value}
								>
									{option.label}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Customers Location</p>
				<CustomSelect
					title='Division'
					style={{ width: '100%', marginBottom:'10px' }}
					placeholder='Select Division'
					value={selectedDivision}
					options={divisions}
					labelInValue
					onChange={option => fetchDistricts(option)}
					showSearch={true}
					filterOption={(input, option) => (option?.children?.toLowerCase() ?? '').includes(input?.toLowerCase())}
				/>
				<CustomSelect
					title='District'
					style={{ width: '100%' }}
					className='sub-area-select'
					placeholder='Select District'
					value={selectedDistrict}
					options={districts}
					labelInValue
					onChange={option => fetchDivisions(option)}
					notFoundContent={<div>Please select a division first.</div>}
					showSearch={true}
					filterOption={(input, option) => (option?.children?.toLowerCase() ?? '').includes(input?.toLowerCase())}
				/>

			</div>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						title='From'
						type='date'
						value={fromDate ? moment(fromDate) : null }
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						title='To'
						type='date'
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Last Order Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						title='From'
						type='date'
						value={lastOrderFromDate ? moment(lastOrderFromDate) : null }
						onChange={(date) => setLastOrderFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						title='To'
						type='date'
						value={lastOrderToDate ? moment(lastOrderToDate) : null }
						onChange={(date) => setLastOrderToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>No Order Since</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='No Order Since'
						tooltip='Customer who have not placed order since this date.'
						value={noOrderSinceDate ? moment(noOrderSinceDate) : null }
						onChange={(date) => setNoOrderSinceDate(date?.toDate())}
					/>
				</div>
			</div>
			{
				isAreaManagerVisible() &&
				<div>
					<p className={styles.title}>Area Manager</p>
					<CustomSearchableSelectSecondary
						searchTask={searchAccountManagers}
						labelIndex='name'
						valueIndex='id'
						placeholder='Select Area Manager'
						onChange={option => {
							if (option?.value) {
								setAccountManager(option)
							}
						}}
						isLoading={isGettingAccountManagers}
						allowClear={true}
						onClear={() => setAccountManager(null)}
						defaultOptions={accountManagers}
						value={accountManager}
					/>
				</div>
			}

			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

GeneralCustomerFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func
}

export default GeneralCustomerFilter
