import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Map from '../map'
import { Vehicles } from '../../services/api/firebase'
import { Row, Popover, Checkbox, Divider, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { paths } from '../../utils/constants'
import { useRouter } from 'next/router'
import { usePrevious } from '../../hooks/usePrevious'
import { fetchGeofences } from '../../store/geofences/actions'
import styles from './FleetMapView.module.css'
import './FleetMapView.less'
import { getStatusColor, getVehicleStatusLabel, VEHICLE_ACTIVE_STATUS, VEHICLE_INACTIVE_STATUS, VEHICLE_IN_MAINTENANCE_STATUS } from '../../utils/vehicles'

let currentGeofence = undefined
let currentGeofences = {}
let editableCurrentGeofence = undefined

const FleetMapView = ({
	geofenceEnabled,
	drawingEnabled,
	geofenceColor,
	geofenceShape,
	geofenceRadius,
	enableGeofences,
	onGeofenceRadiusChanged,
	onGeofenceCenterChanged,
	onGeofencePointsChanged,
	onGeofenceEdit,
	editableGeofence
}) => {
	const dispatch = useDispatch()
	const { geofences } = useSelector(state => state.geofencesReducer)
	const [vehiclesMap, setVehiclesMap] = useState({})
	const { companyDetails } = useSelector(state => state.authReducer)
	const [isFilterControlVisible, setIsFilterControlVisible] = useState(false)
	const [statuses, setStatuses] = useState([VEHICLE_ACTIVE_STATUS, VEHICLE_INACTIVE_STATUS, VEHICLE_IN_MAINTENANCE_STATUS])
	const [map, setMap] = useState()
	const [maps, setMaps] = useState()

	useEffect(() => {
		let unsubscribeVehicles
		if (companyDetails) {
			if (companyDetails.companyId) {
				unsubscribeVehicles = Vehicles.listenToVehicles(companyDetails.companyId,
					(createdVehicle) => {
						setVehiclesMap(previousVehiclesMap => ({ ...previousVehiclesMap, [createdVehicle.id]: createdVehicle }))
					},
					(updatedVehicle) => {
						setVehiclesMap(previousVehiclesMap => ({ ...previousVehiclesMap, [updatedVehicle.id]: updatedVehicle }))
					}
				)
			}
		}
		return () => {
			if (unsubscribeVehicles) {
				unsubscribeVehicles()
			}
		}
	}, [companyDetails])

	useEffect(() => {
		if (maps) {
			const vehicles = getFilteredVehicles()
			if (vehicles.length) {
				const bounds = new maps.LatLngBounds()
				vehicles.forEach(vehicle => {
					const latLng = {
						lat: vehicle.tracker.position.latitude,
						lng: vehicle.tracker.position.longitude
					}
					bounds.extend(latLng)
				})
				map.fitBounds(bounds)
			}
		}
	}, [statuses, vehiclesMap, maps])

	useEffect(() => {
		if (!drawingEnabled) {
			clearGeofence()
		}
	}, [drawingEnabled])

	useEffect(() => {
		if (geofenceEnabled) {
			dispatch(fetchGeofences())
		} else {
			clearGeofence()
			clearGeofences()
		}
	}, [geofenceEnabled])

	useEffect(() => {
		if (geofenceColor) {
			if (currentGeofence) {
				currentGeofence.setOptions({
					fillColor: geofenceColor,
					strokeColor: geofenceColor
				})
			}
			if (editableCurrentGeofence) {
				editableCurrentGeofence.setOptions({
					fillColor: geofenceColor
				})
			}
		}
	}, [geofenceColor])

	const previousGeofenceShape = usePrevious(geofenceShape)
	useEffect(() => {
		if (geofenceShape) {
			if (geofenceShape != previousGeofenceShape) {
				if (!editableGeofence) {
					clearGeofence()
				}
			}
		}
	}, [geofenceShape])

	useEffect(() => {
		if (geofenceRadius) {
			if (currentGeofence) {
				currentGeofence.setOptions({
					radius: 1000 * geofenceRadius
				})
			}
			if (editableCurrentGeofence) {
				editableCurrentGeofence.setOptions({
					radius: 1000 * geofenceRadius
				})
			}
		}
	}, [geofenceRadius])

	useEffect(() => {
		if (geofences) {
			createGeofences()
		}
	}, [geofences])

	const previousEditableGeofence = usePrevious(editableGeofence)
	useEffect(() => {
		if (editableGeofence) {
			if (previousEditableGeofence) {
				if (previousEditableGeofence.id !== editableGeofence.id) {
					createGeofences()
				}
			}
			const geofence = currentGeofences[editableGeofence.id]
			if (geofence) {
				geofence.setMap(null)
				if (currentGeofence) {
					currentGeofence.setMap(null)
				}
				createEditableGeofence()
			}
		} else {
			if (previousEditableGeofence && !editableGeofence) {
				const geofence = currentGeofences[previousEditableGeofence.id]
				if (geofence) {
					geofence.setMap(map)
				}
			}
			clearGeofence()
		}
	}, [editableGeofence])

	const createGeofences = () => {
		clearGeofences()
		geofences.forEach(geofence => {
			const mapGeofence = createMapGeofence(geofence)
			if (mapGeofence) {
				mapGeofence.setMap(map)
				maps.event.addListener(mapGeofence, 'click', () => {
					onGeofenceEdit(geofence)
				})
				currentGeofences[geofence.id] = mapGeofence
			}
		})
	}

	const createMapGeofence = (geofence, customOptions = {}) => {
		const options = {
			strokeColor: geofence.color,
			strokeOpacity: 0.85,
			strokeWeight: 2,
			fillColor: geofence.color,
			fillOpacity: 0.5,
			...customOptions
		}
		if (geofence.shape === 'polygon') {
			return new maps.Polygon({
				...options,
				paths: geofence.points.map(point => ({ lat: point.lat, lng: point.lng }))
			})
		} else if (geofence.shape === 'rectangle') {
			const bounds = new maps.LatLngBounds()
			geofence.points.forEach(point => {
				bounds.extend(new maps.LatLng(point.lat, point.lng))
			})
			return new maps.Rectangle({
				...options,
				bounds
			})
		} else if (geofence.shape === 'circle') {
			return new maps.Circle({
				...options,
				center: { lat: geofence.center.lat, lng: geofence.center.lng },
				radius: 1000 * geofence.radius
			})
		} else {
			return null
		}
	}

	const createEditableGeofence = () => {
		if (editableCurrentGeofence) {
			editableCurrentGeofence.setMap(null)
		}
		editableCurrentGeofence = createMapGeofence(editableGeofence, {
			editable: true,
			draggable: true,
			strokeColor: '#1890ff',
			strokeOpacity: 1.0,
			strokeWeight: 3
		})
		editableCurrentGeofence.setMap(map)
		addGeofenceListeners(editableCurrentGeofence, editableGeofence.shape)
	}

	const clearGeofence = () => {
		if (currentGeofence) {
			currentGeofence.setMap(null)
			currentGeofence = undefined
		}
		if (editableCurrentGeofence) {
			editableCurrentGeofence.setMap(null)
			editableCurrentGeofence = null
		}
	}

	const clearGeofences = () => {
		for (const id in currentGeofences) {
			const geofence = currentGeofences[id]
			geofence.setMap(null)
			delete currentGeofences[id]
		}
	}

	const addGeofenceListeners = (geofence, shape) => {
		if (shape === 'polygon') {
			const polygonListener = () => {
				const path = geofence.getPath()
				const points = []
				path.forEach(point => {
					points.push(point.toJSON())
				})
				onGeofencePointsChanged(points)
			}
			polygonListener()
			maps.event.addListener(geofence, 'dragend', polygonListener)
			const path = geofence.getPath()
			maps.event.addListener(path, 'set_at', polygonListener)
			maps.event.addListener(path, 'insert_at', polygonListener)
		} else if (shape === 'rectangle') {
			const rectangleListener = () => {
				const bounds = geofence.getBounds()
				const ne = bounds.getNorthEast()
				const sw = bounds.getSouthWest()
				onGeofencePointsChanged([
					ne.toJSON(),
					sw.toJSON()
				])
			}
			rectangleListener()
			maps.event.addListener(geofence, 'dragend', rectangleListener)
			maps.event.addListener(geofence, 'bounds_changed', rectangleListener)
		} else if (shape === 'circle') {
			const center = geofence.getCenter()
			onGeofenceCenterChanged({ lat: center.lat(), lng: center.lng() })
			maps.event.addListener(geofence, 'center_changed', () => {
				const center = geofence.getCenter()
				onGeofenceCenterChanged({ lat: center.lat(), lng: center.lng() })
			})
			maps.event.addListener(geofence, 'radius_changed', () => {
				onGeofenceRadiusChanged(geofence.getRadius() / 1000)
			})
		}
	}

	const onMapClick = (e) => {
		if (drawingEnabled) {
			if (currentGeofence) {
				if (geofenceShape === 'polygon') {
					currentGeofence.getPath().push(new maps.LatLng(e.lat, e.lng))
				} else if (geofenceShape === 'rectangle') {
					const bounds = currentGeofence.getBounds()
					const point = new maps.LatLng(e.lat, e.lng)
					bounds.extend(point)
					currentGeofence.setBounds(bounds)
				} else if (geofenceShape === 'circle') {
					const point = new maps.LatLng(e.lat, e.lng)
					currentGeofence.setCenter(point)
				}
			} else if (!editableGeofence) {
				const options = {
					strokeColor: geofenceColor,
					strokeOpacity: 0.85,
					strokeWeight: 2,
					fillColor: geofenceColor,
					fillOpacity: 0.5,
					editable: true,
					draggable: true
				}
				if (geofenceShape === 'polygon') {
					currentGeofence = new maps.Polygon({
						...options,
						paths: [
							{ lat: e.lat, lng: e.lng }
						]
					})
				} else if (geofenceShape === 'rectangle') {
					currentGeofence = new maps.Rectangle({
						...options,
						bounds: {
							north: e.lat,
							south: e.lat,
							east: e.lng,
							west: e.lng
						}
					})
					const bounds = currentGeofence.getBounds()
					const ne = bounds.getNorthEast()
					const sw = bounds.getSouthWest()
					onGeofencePointsChanged([
						ne.toJSON(),
						sw.toJSON()
					])
				} else if (geofenceShape === 'circle') {
					currentGeofence = new maps.Circle({
						...options,
						center: { lat: e.lat, lng: e.lng },
						radius: 1000 * geofenceRadius
					})
					onGeofenceCenterChanged({ lat: e.lat, lng: e.lng })
				}
				if (currentGeofence) {
					currentGeofence.setMap(map)
					addGeofenceListeners(currentGeofence, geofenceShape)
				}
			}
		}
	}

	const onMapsLoaded = (map, maps) => {
		setMap(map)
		setMaps(maps)
	}

	const filterVehicle = (vehicle) => {
		if (!vehicle.tracker || !vehicle.tracker.position) {
			return false
		}
		return isStatusChecked(vehicle.status)
	}

	const isStatusChecked = (status) => {
		return statuses.includes(status)
	}

	const onStatusesChanged = (status) => {
		const newStatuses = [...statuses]
		if (isStatusChecked(status)) {
			setStatuses(newStatuses.filter(s => s !== status))
		} else {
			newStatuses.push(status)
			setStatuses(newStatuses)
		}
	}

	const getFilteredVehicles = () => {
		return Object.values(vehiclesMap).filter(filterVehicle)
	}

	return (
		<div className={styles.mapContainer}>
			<Map
				containerStyle={{
					width: '100%',
					height: 'calc(100vh - 200px)',
					margin: '24px 0'
				}}
				onMapsLoaded={onMapsLoaded}
				draggableCursor={drawingEnabled ? 'crosshair' : 'grab'}
				onMapClick={onMapClick}
			>
				{
					Object.values(vehiclesMap).filter(filterVehicle).map(vehicle => {
						const position = vehicle.tracker.position
						return (
							<VehicleMarker
								showStatusIndicator={true}
								vehicle={vehicle}
								key={vehicle.id}
								lat={position.latitude}
								lng={position.longitude}
							/>
						)
					})
				}
			</Map>
			<div className={styles.filterControl}>
				<Popover
					content={
						<div className={styles.filterOptions}>
							<div className={styles.filterOption}>
								<Checkbox
									className='active-checkbox'
									checked={isStatusChecked(VEHICLE_ACTIVE_STATUS)}
									onChange={() => onStatusesChanged(VEHICLE_ACTIVE_STATUS)}
								>
									Active
								</Checkbox>
							</div>
							<div className={styles.filterOption}>
								<Checkbox
									className='inactive-checkbox'
									checked={isStatusChecked(VEHICLE_INACTIVE_STATUS)}
									onChange={() => onStatusesChanged(VEHICLE_INACTIVE_STATUS)}
								>
									Inactive
								</Checkbox>
							</div>
							<div className={styles.filterOption}>
								<Checkbox
									className='in-maintenance-checkbox'
									checked={isStatusChecked(VEHICLE_IN_MAINTENANCE_STATUS)}
									onChange={() => onStatusesChanged(VEHICLE_IN_MAINTENANCE_STATUS)}
								>
									Under Maintenance
								</Checkbox>
							</div>
							<Divider style={{ margin: '10px 0px' }} />
							<div className={styles.filterOption}>
								<Checkbox
									checked={geofenceEnabled}
									onChange={e => enableGeofences(e.target.checked)}
								>
									Geofences
								</Checkbox>
							</div>
						</div>
					}
					trigger='click'
					placement='bottomLeft'
					visible={isFilterControlVisible}
					onVisibleChange={setIsFilterControlVisible}
				>
					<img
						className={styles.filterControlIcon}
						src='/img/control.svg'
						alt='...'
					/>
				</Popover>
			</div>
		</div>
	)
}

FleetMapView.propTypes = {
	geofenceEnabled: PropTypes.bool,
	drawingEnabled: PropTypes.bool,
	geofenceColor: PropTypes.string,
	geofenceShape: PropTypes.string,
	geofenceRadius: PropTypes.number,
	enableGeofences: PropTypes.func,
	onGeofenceRadiusChanged: PropTypes.func,
	onGeofenceCenterChanged: PropTypes.func,
	onGeofencePointsChanged: PropTypes.func,
	onGeofenceEdit: PropTypes.func,
	editableGeofence: PropTypes.object
}

FleetMapView.defaultProps = {
	geofenceEnabled: false,
	drawingEnabled: false,
	geofenceRadius: 1,
	enableGeofences: () => {},
	onGeofenceRadiusChanged: () => {},
	onGeofenceCenterChanged: () => {},
	onGeofencePointsChanged: () => {},
	onGeofenceEdit: () => {}
}

export default FleetMapView

export const VehicleMarker = ({ vehicle, showStatusIndicator }) => {
	const router = useRouter()
	const [showHoverDialog, setShowHoverDialog] = useState(false)
	const [showClickDialog, setShowClickDialog] = useState(false)
	const { tracker } = vehicle

	const getIndicatorClass = () => {
		if (showStatusIndicator) {
			if (vehicle.status === VEHICLE_ACTIVE_STATUS) {
				return styles.activeIndicator
			}
			if (vehicle.status === VEHICLE_INACTIVE_STATUS) {
				return styles.inactiveIndicator
			}
			if (vehicle.status === VEHICLE_IN_MAINTENANCE_STATUS) {
				return styles.inMaintenanceIndicator
			}
		} else {
			return vehicle.currentTripId ? styles.assignedIndicator : styles.unassignedIndicator
		}
	}

	const renderVehicleInfo = () => {
		return (
			<div className={styles.vehicleInfo}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className={styles.dialogTitle}>{vehicle.plateNumber}</div>
					{
						showClickDialog ?
							<Button
								style={{ marginLeft: '12px' }}
								onClick={() => setShowClickDialog(false)}
								size='small'
								type='primary'
								icon={<CloseOutlined style={{ fontSize: 10 }} />}
							/> : null
					}
				</div>
				<div className={styles.dialogInfoContainer}>
					<Row style={{ margin: '3px 0' }}>
						<span className={styles.valueTitle}>Status:</span>
						<span
							style={{ color: getStatusColor(vehicle.status) }}
							className={styles.value}
						>
							{getVehicleStatusLabel(vehicle.status)}
						</span>
					</Row>
					<Row style={{ margin: '3px 0' }}>
						<span className={styles.valueTitle}>Speed:</span>
						<span
							className={styles.value}
						>
							{tracker.position.speed} km/h
						</span>
					</Row>
					{
						vehicle.currentTripId ?
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Assigned</span>
							</Row>
							:
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Unassigned</span>
							</Row>
					}
					<Button
						style={{ marginTop: 12 }}
						type='primary'
						onClick={() => router.push(`${paths.FLEET}/${vehicle.id}`)}
					>
						View Details
					</Button>
				</div>
			</div>
		)
	}

	return (
		<div className={styles.vehicleMarker}>
			<Popover
				content={renderVehicleInfo()}
				trigger='hover'
				visible={showHoverDialog}
				onVisibleChange={setShowHoverDialog}
			>
				<Popover
					content={renderVehicleInfo()}
					trigger='click'
					visible={showClickDialog}
					onVisibleChange={setShowClickDialog}
				>
					<div style={{ transform: `rotate(${tracker.position.direction}deg)` }}>
						<img
							src='/img/truck-top.svg'
							height={44}
						/>
						<div className={getIndicatorClass()} />
					</div>
				</Popover>
			</Popover>
		</div>
	)
}

VehicleMarker.propTypes = {
	vehicle: PropTypes.object,
	showStatusIndicator: PropTypes.bool
}

VehicleMarker.defaultProps = {
	vehicle: {},
	showStatusIndicator: false
}
