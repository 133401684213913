import { Button, notification, Modal, Form, Select as AntSelect, DatePicker } from 'antd'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { assignTransportVendorToDeliveryPlanItem } from '../../store/delivery-plans/actions'
import CustomSearchableSelect from '../custom-searchable-select'
import { TransportVendors } from '../../services/api/transport-vendors'
import { fetchTransportVendors } from '../../store/transport-vendors/actions'
import { ThirdPartyLogisticsType } from '../../utils/constants'
import { convertEnumToString, range } from '../../utils'
import { getVehicleTypeLabel } from '../../utils/vehicles'
import { usePrevious } from '../../hooks/usePrevious'
import { Custom } from '../../services/api/custom'
import styles from './AssignTransportVendorModal.module.css'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'

const AssignTransportVendorModal = ({
	visible,
	onCancel,
	deliveryPlan,
	deliveryPlanItemId,
	deliveryPlanItemInternalId,
	assignedTransportVendors,
	vehicleType
}) => {
	const dispatch = useDispatch()
	const [isAssigningTransportVendor, setIsAssigningTransportVendor] = useState(false)
	const { transportVendors } = useSelector(state => state.transportVendorsReducer)
	const [isLoading, setIsLoading] = useState(false)
	const [vendorData, setVendorData] = useState()
	const [vendor, setVendor] = useState()
	const [isLoadingIntegrationData, setIsLoadingIntegrationData] = useState(false)
	const [integrationData, setIntegrationData] = useState({})
	const [selectedTransportVendors, setSelectedTransportVendors] = useState(assignedTransportVendors)
	const [selectedTransportVendorsMap, setSelectedTransportVendorsMap] = useState({})
	const [transportVendorData, setTransportVendorData] = useState({})

	useEffect(() => {
		getTransportVendors()
	}, [])

	useEffect(() => {
		const selectedTransportVendorsMap = {}
		for (const selectedVendor of selectedTransportVendors) {
			selectedTransportVendorsMap[selectedVendor.id] = true
		}
		setSelectedTransportVendorsMap(selectedTransportVendorsMap)
	}, [selectedTransportVendors])

	const previousVendorData = usePrevious(vendorData)
	useEffect(() => {
		if (!previousVendorData && vendorData) {
			fetchIntegrationData()
		} else if (previousVendorData && vendorData) {
			if (previousVendorData.thirdPartyLogisticsType !== vendorData.thirdPartyLogisticsType) {
				fetchIntegrationData()
			}
		}
	}, [vendorData])

	const is3plIntegrationDataValid = () => {
		switch (vendorData.thirdPartyLogisticsType) {
			case ThirdPartyLogisticsType.GIM: {
				if (!vendorData.integrationConfig) {
					return false
				} else if (!vendorData.integrationConfig.goodsType) {
					return false
				}
				const tranportVendorDataPayload = transportVendorData[vendorData.id]
				const deliveryDate = tranportVendorDataPayload ? tranportVendorDataPayload.deliveryDate : deliveryPlan?.deliveryDate
				if (!deliveryDate) {
					return false
				} else if (moment(deliveryDate).isBefore(moment())) {
					return false
				}
				return true
			}
			default:
				return true
		}
	}

	const getTransportVendors = async () => {
		setIsLoading(true)
		await dispatch(fetchTransportVendors({ page: 0, is3pl: true }))
		setIsLoading(false)
	}

	const searchTransportVendors = value => {
		return TransportVendors.index({ searchTerm: value, is3pl: true })
	}

	const fetchIntegrationData = async () => {
		setIsLoadingIntegrationData(true)
		switch (vendorData.thirdPartyLogisticsType) {
			case ThirdPartyLogisticsType.GIM: {
				const { data } = await Custom.getGimGoodTypes()
				setIntegrationData({
					goodsTypes: data.data
				})
				break
			}
		}
		setIsLoadingIntegrationData(false)
	}

	const assignTransportVendors = async () => {
		setIsAssigningTransportVendor(true)
		try {
			await Promise.all(selectedTransportVendors.map(async transportVendor => {
				if (transportVendor && transportVendor.thirdPartyLogisticsType !== ThirdPartyLogisticsType.OTHER) {
					await TransportVendors.update(transportVendor.id, {
						type: transportVendor.thirdPartyLogisticsType,
						integrationConfig: transportVendor.integrationConfig
					})
				}
			}))
			await dispatch(assignTransportVendorToDeliveryPlanItem(deliveryPlanItemId, {
				transportVendorIds: selectedTransportVendors.map(transportVendor => transportVendor.id),
				transportVendorData: Object.values(transportVendorData)
			}))
			onCancel()
			notification.success({
				message: 'Transport Vendor Assigned to Delivery Plan',
				description: 'Vehicle has been successfully assigned to a route.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Assign Transport Vendor to Route',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsAssigningTransportVendor(false)
		}
	}

	const render3plIntegrationConfig = (type) => {
		switch (type) {
			case ThirdPartyLogisticsType.GIM: {
				const goodsTypes = integrationData.goodsTypes || []
				const tranportVendorDataPayload = transportVendorData[vendorData.id]
				const deliveryDate = tranportVendorDataPayload?.deliveryDate || deliveryPlan?.deliveryDate
				return (
					<div style={{ marginTop: 12 }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Form.Item
								label='Goods Type'
								colon={false}
								required
							>
								<AntSelect
									loading={isLoadingIntegrationData}
									showSearch={true}
									style={{ width: 200 }}
									options={goodsTypes.map(goodType => ({ label: goodType.text, value: `${goodType.id}` }))}
									value={goodsTypes.length > 0 ? vendorData.integrationConfig.goodsType : null}
									onChange={value => setVendorData({
										...vendorData,
										integrationConfig: {
											...vendorData.integrationConfig,
											goodsType: value
										}
									})}
									filterOption={(input, option) => {
										return (
											option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
										)
									}}
								/>
							</Form.Item>
							<Form.Item
								label='Pick Up Time'
								colon={false}
								tooltip='Pick up time has to be in the future.'
								required
							>
								<DatePicker
									showTime
									disabledDate={current => current && current.isBefore(moment().startOf('day'))}
									disabledTime={() => {
										const current = moment().add(1, 'hour')
										const hour = current.hour()
										return {
											disabledHours: () => range(0, hour),
											disabledMinutes: () => [],
											disabledSeconds: () => []
										}
									}}
									value={deliveryDate ? moment(deliveryDate) : null}
									onChange={(date) => {
										setTransportVendorData({
											...transportVendorData,
											[vendorData.id]: {
												...transportVendorData[vendorData.id],
												deliveryDate: date?.toDate()
											}
										})
									}}
									onOk={(date) => {
										setTransportVendorData({
											...transportVendorData,
											[vendorData.id]: {
												...transportVendorData[vendorData.id],
												deliveryDate: date?.toDate()
											}
										})
									}}
									showNow={false}
								/>
							</Form.Item>
						</div>
					</div>
				)
			}
			default:
				return null
		}
	}

	return (
		<Modal
			title={vehicleType ? <span>{'Assign Transport Vendors'}<i style={{ margin: '0 8px' }}>{ `(${getVehicleTypeLabel(vehicleType)})`}</i>{deliveryPlanItemInternalId ? ` to ${deliveryPlanItemInternalId}` : ''}</span> : 'Assign Transport Vendors'}
			visible={visible}
			onCancel={onCancel}
			onOk={assignTransportVendors}
			okText='Assign Transport Vendors'
			width={420}
			okButtonProps={{
				disabled: isLoading
			}}
			confirmLoading={isAssigningTransportVendor}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Form.Item>
					<CustomSearchableSelect
						searchTask={searchTransportVendors}
						defaultOptions={transportVendors}
						isLoading={isLoading}
						labelIndex='name'
						valueIndex='id'
						title='Transport Vendor'
						placeholder='Select Transport Vendor'
						onChangeData={data => {
							setVendorData(data)
							if (data) {
								setTransportVendorData({
									...transportVendorData,
									[data.id]: {
										id: data.id
									}
								})
							}
						}}
						onChange={option => setVendor(option)}
						onClear={() => setVendor(null)}
						value={vendor}
						disabledOptions={selectedTransportVendorsMap}
					/>
				</Form.Item>
				{
					vendorData && vendorData.thirdPartyLogisticsType !== ThirdPartyLogisticsType.OTHER ?
						<Form.Item>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<h4 style={{ marginBottom: 0, marginRight: 12 }}>
									3PL Integration
								</h4>
								<div>
									{convertEnumToString(vendorData.thirdPartyLogisticsType)}
								</div>
							</div>
							{render3plIntegrationConfig(vendorData.thirdPartyLogisticsType)}
						</Form.Item> : null
				}
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{
						selectedTransportVendors.length > 0 &&
						<h4 style={{ marginBottom: 0 }}>
						Selected Transport Vendors
						</h4>
					}
					<Button
						type='primary'
						style={{ marginLeft: 'auto' }}
						disabled={!vendorData || isLoadingIntegrationData || !is3plIntegrationDataValid()}
						onClick={() => {
							setSelectedTransportVendors([vendorData, ...selectedTransportVendors])
							setVendor(null)
							setVendorData(null)
						}}
					>
					Use
					</Button>
				</div>
				{
					selectedTransportVendors.length > 0 &&
					<div className={styles.selectedVendorContainer}>
						{
							selectedTransportVendors.map(vendor => {
								return (
									<div key={vendor.id} className={styles.selectedVendor}>
										<CloseOutlined
											onClick={() => {
												setSelectedTransportVendors(selectedTransportVendors.filter(selectedVendor => selectedVendor.id !== vendor.id))
											}}
											className={styles.removeVendor}
										/>
										{vendor.name}
									</div>
								)
							})
						}
					</div>
				}
			</div>
		</Modal>
	)
}

AssignTransportVendorModal.propTypes = {
	visible: PropTypes.bool,
	onCancel: PropTypes.func,
	deliveryPlan: PropTypes.object,
	deliveryPlanItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	deliveryPlanItemInternalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	assignedTransportVendors: PropTypes.arrayOf(PropTypes.object),
	vehicleType: PropTypes.string
}

AssignTransportVendorModal.defaultProps = {
	assignedTransportVendors: []
}

export default AssignTransportVendorModal
