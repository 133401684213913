import { Button, notification, Modal, Collapse, Form } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { Users } from '../../services/api/users'
import { assignDriverToDeliveryPlanItem } from '../../store/delivery-plans/actions'
import CustomSearchableSelect from '../custom-searchable-select'
import { PlusOutlined } from '@ant-design/icons'
import CustomPhoneInput from '../phone-input'
import { claimsRoles } from '../../utils/constants'
import { Vehicles } from '../../services/api/vehicles'
import { VEHICLE_ACTIVE_STATUS, getVehicleTypeLabel } from '../../utils/vehicles'
import TextInput from '../text-input'

const { Panel } = Collapse

const AssignDriverModal = ({ visible, onCancel, deliveryPlanItemId, onDriverAssigned }) => {
	const dispatch = useDispatch()
	const { userProfile } = useSelector(state => state.authReducer)
	const [isLoadingDrivers, setIsLoadingDrivers] = useState(false)
	const [isCreatingNewDriver, setIsCreatingNewDriver] = useState(false)
	const [isAssigningDriver, setIsAssigningDriver] = useState(false)
	const [driver, setDriver] = useState()
	const [drivers, setDrivers] = useState([])
	const [driverName, setDriverName] = useState('')
	const [driverPhone, setDriverPhone] = useState('')
	const [helper, setHelper] = useState(null)
	const [vehicle, setVehicle] = useState(null)
	const [isLoadingVehicles, setIsLoadingVehicles] = useState(false)
	const [vehicles, setVehicles] = useState([])
	const [isLoadingHelpers, setIsLoadingHelpers] = useState(false)
	const [helpers, setHelpers] = useState([])
	const locationIds = useMemo(() => {
		const locationIds = userProfile ? userProfile.locations.map(({ id }) => id) : undefined
		return locationIds?.length ? locationIds : undefined
	}, [userProfile])

	useEffect(() => {
		fetchVehicles()
	}, [])

	useEffect(() => {
		if (userProfile) {
			fetchDrivers(),
			fetchHelpers()
		}
	}, [userProfile])

	useEffect(() => {
		if (driver) {
			fetchDriverVehicle(driver.value)
		} else {
			setVehicle(null)
			setHelper(null)
		}
	}, [driver])

	const fetchDriverVehicle = async (driverId) => {
		try {
			const { data } = await Vehicles.fetchUserVehicle(driverId)
			setVehicle({ value: data.id, label: data.plateNumber })
			if (data.helper) {
				setHelper({ value: data.helper.id, label: data.helper.name })
			}
		} catch (e) {
			setVehicle(null)
			setHelper(null)
		}
	}

	const fetchDrivers = async () => {
		setIsLoadingDrivers(true)
		const response = await Users.index({
			role: claimsRoles.DRIVER,
			locationIds,
			available: true
		})
		setDrivers(response.data.results)
		setIsLoadingDrivers(false)
	}

	const searchDrivers = value => {
		return Users.index({
			searchTerm: value,
			role: claimsRoles.DRIVER,
			locationIds,
			available: true
		})
	}

	const fetchHelpers = async () => {
		setIsLoadingHelpers(true)
		const response = await Users.index({
			role: claimsRoles.HELPER,
			locationIds,
			available: true
		})
		setHelpers(response.data.results)
		setIsLoadingHelpers(false)
	}

	const searchHelpers = value => {
		return Users.index({
			searchTerm: value,
			role: claimsRoles.HELPER,
			locationIds,
			available: true
		})
	}

	const fetchVehicles = async () => {
		setIsLoadingVehicles(true)
		const { data } = await Vehicles.index({
			available: true,
			deliveryPlanItemId
		})
		setVehicles(data.results)
		setIsLoadingVehicles(false)
	}

	const searchVehicles = value => {
		return Vehicles.index({
			searchTerm: value,
			available: true,
			deliveryPlanItemId
		})
	}

	const createDriver = async () => {
		setIsCreatingNewDriver(true)
		try {
			const response = await Users.create({
				name: driverName,
				phone: driverPhone,
				role: 'driver'
			})
			const createdDriver = response.data
			setDrivers([createdDriver, ...drivers])
			setDriver({
				key: createdDriver.id,
				label: createdDriver.name,
				value: createdDriver.id
			})
			clearForm()
			notification.success({
				message: 'New Driver Created',
				description: 'New Driver successfully created!',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Create New Driver',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCreatingNewDriver(false)
		}
	}

	const assignDriver = async () => {
		setIsAssigningDriver(true)
		try {
			await dispatch(assignDriverToDeliveryPlanItem(deliveryPlanItemId, {
				driverId: driver.value,
				helperId: helper?.value,
				vehicleId: vehicle?.value
			}))
			if (onDriverAssigned) {
				onDriverAssigned(deliveryPlanItemId, driver.value)
			}
			onCancel()
			notification.success({
				message: 'Driver Assigned to Delivery Plan',
				description: 'Driver has been successfully assigned to a route.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Assign Driver to Route',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsAssigningDriver(false)
		}
	}

	const clearForm = () => {
		setDriverName('')
		setDriverPhone('')
	}

	const isDriverInputValid = () => {
		if (!driverName) {
			return false
		}

		if (!driverPhone) {
			return false
		} else {
			if (!isValidPhoneNumber(driverPhone)) {
				return false
			}
		}
		return true
	}

	const renderCustomUserLabel = (data) => {
		return (
			<div>
				<div>{`${data.name} (${data.lastUnassignedTripId ? 'Unassigned' : 'Available'})`}</div>
			</div>
		)
	}

	const renderCustomVehicleLabel = (data) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div>
					<div>{`${data.plateNumber} (${data.status === VEHICLE_ACTIVE_STATUS ? 'Available' : data.currentTripId ? 'Assigned' : 'Unassigned'})`}</div>
					<div>{data.location ? `${data.location.internalName ? `${data.location.label} (${data.location.internalName})` : data.location.label}` : 'No Base Location'}</div>
				</div>
				<div style={{ marginLeft: 'auto', fontSize: 12 }}>
					{getVehicleTypeLabel(data.type)}
				</div>
			</div>
		)
	}

	const renderModalFooter = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
				<Button
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					type='primary'
					loading={isAssigningDriver}
					disabled={!driver}
					onClick={assignDriver}
				>
					Assign Driver
				</Button>
			</div>
		)
	}

	return (
		<Modal
			title='Assign Driver'
			visible={visible}
			onCancel={onCancel}
			footer={renderModalFooter()}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Form.Item>
					<CustomSearchableSelect
						searchTask={searchDrivers}
						defaultOptions={drivers}
						isLoading={isLoadingDrivers}
						labelIndex='name'
						valueIndex='id'
						title='Driver'
						placeholder='Select Driver'
						customLabel={renderCustomUserLabel}
						onChange={option => setDriver(option)}
						onClear={() => setDriver(null)}
						allowClear={true}
						value={driver}
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<Form.Item>
					<CustomSearchableSelect
						searchTask={searchVehicles}
						defaultOptions={vehicles}
						isLoading={isLoadingVehicles}
						title='Vehicle'
						className='vehicle-select'
						labelIndex='plateNumber'
						valueIndex='id'
						customLabel={renderCustomVehicleLabel}
						placeholder='Select Vehicle'
						onChange={setVehicle}
						onClear={() => setVehicle(null)}
						value={vehicle}
						allowClear={true}
						popupContainer={() => document.querySelector('.ant-modal-body')}
					/>
				</Form.Item>
				<Form.Item>
					<CustomSearchableSelect
						searchTask={searchHelpers}
						defaultOptions={helpers}
						isLoading={isLoadingHelpers}
						labelIndex='name'
						valueIndex='id'
						title='Helper'
						customLabel={renderCustomUserLabel}
						placeholder='Select Helper'
						onChange={option => setHelper(option)}
						onClear={() => setHelper(null)}
						value={helper}
						allowClear={true}
					/>
				</Form.Item>
				<Collapse style={{ marginTop: 24 }}>
					<Panel header='Create New Driver'>
						<TextInput
							title='Driver Name'
							value={driverName}
							onChange={e => {
								let text = e.target.value.toLowerCase()
								text = text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
								setDriverName(text)
							}}
						/>
						<div style={{ marginTop: 24 }} />
						<CustomPhoneInput
							title='Phone Number'
							value={driverPhone}
							onChange={setDriverPhone}
						/>
						<Button
							onClick={createDriver}
							loading={isCreatingNewDriver}
							disabled={!isDriverInputValid()}
							type='primary'
							style={{ alignSelf: 'flex-start', marginTop: 24 }}
							icon={<PlusOutlined />}
						>
							Create
						</Button>
					</Panel>
				</Collapse>
			</div>
		</Modal>
	)
}

AssignDriverModal.propTypes = {
	visible: PropTypes.bool,
	onCancel: PropTypes.func,
	deliveryPlanItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onDriverAssigned: PropTypes.func
}

AssignDriverModal.defaultProps = {
	visible: false
}

export default AssignDriverModal
