import { Upload, message, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
require('./ImageUpload.less')
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { getFirestore, collection, doc } from 'firebase/firestore'
import Cropper from 'react-cropper'
import Head from 'next/head'
import getFirebaseApp from '../../config/initFirebase'

const app = getFirebaseApp()
const storage = getStorage(app)
const db = getFirestore(app)

const ImageUpload = ({
	title,
	width,
	height,
	image,
	images,
	onImageUploaded,
	onImagesUploaded,
	pathname,
	cropper,
	square,
	accept,
	cacheControl,
	/** File size is in MB */
	maxFileSize,
	maxFiles,
	disabled
}) => {
	const [fileList, setFileList] = useState([])
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [imgSrc, setImgSrc] = useState('')
	const [croppedImgData, setCroppedImgData] = useState('')
	const cropperRef = useRef(null)

	useEffect(() => {
		if (image) {
			setFileList([
				{
					uid: '-1',
					name: 'company-logo',
					status: 'done',
					url: image
				}
			])
		} else {
			setFileList([])
		}
	}, [image])

	useEffect(() => {
		if (images) {
			const fileList = images.map((imageUrl, index) => {
				return {
					uid: `-${index + 1}`,
					name: `product-${index}`,
					status: 'done',
					url: imageUrl
				}
			})
			setFileList(fileList)
		} else {
			setFileList([])
		}
	}, [images])

	const validateFile = (file, showMessage = true) => {
		const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || 'image/svg'
		if (!isJpgOrPngOrSvg && showMessage) {
			message.error('You can only upload JPG/PNG/SVG file.')
		}
		const isLt2M = file.size / 1024 / 1024 < maxFileSize
		if (!isLt2M && showMessage) {
			message.error(`Image must be smaller than ${maxFileSize}MB.`)
		}
		return isJpgOrPngOrSvg && isLt2M
	}

	const beforeUpload = (file) => {
		const reader = new FileReader()
		reader.onload = e => setImgSrc(e.target.result)
		reader.readAsDataURL(file)

		return validateFile(file)
	}

	const onChange = ({ fileList: newFileList }) => {
		if (newFileList.length === 0) {
			setFileList(newFileList)
			if (onImageUploaded) {
				onImageUploaded(null)
			}
			if (onImagesUploaded) {
				onImagesUploaded([])
			}
		} else {
			// Single file upload
			if (maxFiles === 1) {
				const newFile = newFileList[0]
				if (validateFile(newFile, false)) {
					setFileList(newFileList)
					if (cropper) {
						if (!newFileList[0].type.includes('svg')) {
							setIsModalVisible(true)
						}
					}
				}
			} else {
				setFileList(newFileList)
				if (onImagesUploaded) {
					const imageUrls = newFileList
						.filter(file => file.url)
						.map(file => file.url)
					onImagesUploaded(imageUrls)
				}
			}
		}
	}

	const onPreview = async ({ url }) => {
		if (!croppedImgData) {
			return window.open(url, '_blank')
		}

		const image = new Image()
		image.src = croppedImgData
		const imgWindow = window.open()

		if (imgWindow) {
			imgWindow.document.write(image.outerHTML)
		} else {
			window.location.href = undefined
		}
	}

	const onFileUpload = async ({ onSuccess, onError, onProgress, file }) => {
		const fileName = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
		const collectionRef = collection(db, pathname)
		const docRef = doc(collectionRef)
		const storageRef = ref(storage, `${pathname}/${docRef.id}`)
		const uploadTask = uploadBytesResumable(storageRef, file, {
			customMetadata: {
				name: fileName
			},
			cacheControl
		})
		uploadTask.on('state_changed',
			snapshot => {
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
				onProgress({ percent: progress })
			},
			error => {
				onError({ err: error })
			},
			async () => {
				const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
				file.url = downloadUrl
				if (onImageUploaded) {
					onImageUploaded(downloadUrl)
				}
				if (onImagesUploaded) {
					const imageUrls = fileList
						.filter(file => (file.originFileObj && file.originFileObj.url) || file.url)
						.map(file => file.url || file.originFileObj.url)
					onImagesUploaded(imageUrls)
				}
				onSuccess(undefined, file)
			}
		)
	}

	const handleCrop = () => {
		const imageElement = cropperRef?.current
		const cropper = imageElement?.cropper
		setCroppedImgData(cropper.getCroppedCanvas().toDataURL())
	}

	return (
		<>
			<Head>
				<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/cropperjs/2.0.0-alpha.2/cropper.css' />
			</Head>
			<Upload
				accept={accept}
				onChange={onChange}
				fileList={fileList}
				onPreview={onPreview}
				beforeUpload={(file) => beforeUpload(file, false)}
				customRequest={onFileUpload}
				listType='picture-card'
				className={square ? '' : 'upload-container'}
				disabled={disabled}
			>
				{
					fileList.length < maxFiles &&
                    <div className='upload-container'>
                    	<PlusOutlined style={{ marginBottom: square ? 0 : 12 }} />
                    	{title}
                    	{width && height && <div className='heading'>{`(${width} x ${height})`}</div>}
                    </div>
				}
			</Upload>
			<Modal
				title='Crop Image'
				visible={isModalVisible}
				onOk={() => {
					const croppedImage = { ...fileList[0], thumbUrl: croppedImgData }

					setFileList([croppedImage])
					setIsModalVisible(false)
				}}
				onCancel={() => {
					setFileList([])
					setIsModalVisible(false)
				}}
			>
				<Cropper
					style={{ height: 'auto', width: '100%' }}
					zoomTo={0}
					src={imgSrc}
					viewMode={1}
					minCropBoxHeight={10}
					minCropBoxWidth={10}
					background={false}
					autoCropArea={1}
					checkOrientation={false}
					responsive
					ref={cropperRef}
					ready={handleCrop}
					cropend={handleCrop}
				/>
			</Modal>
		</>
	)
}

ImageUpload.propTypes = {
	title: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	image: PropTypes.string,
	images: PropTypes.arrayOf(PropTypes.string),
	onImageUploaded: PropTypes.func,
	onImagesUploaded: PropTypes.func,
	pathname: PropTypes.string,
	cropper: PropTypes.bool,
	square: PropTypes.bool,
	accept: PropTypes.string,
	cacheControl: PropTypes.string,
	maxFileSize: PropTypes.number,
	maxFiles: PropTypes.number,
	disabled: PropTypes.bool
}

ImageUpload.defaultProps = {
	cropper: true,
	square: false,
	accept: 'image/*, .png, .jpg, .jpeg, .svg',
	cacheControl: 'public,max-age=31536000',
	maxFileSize: 2,
	maxFiles: 1,
	disabled: false
}

export default ImageUpload
