import { post, patch, get } from './index'

export const DeliveryOrders = {
	create: (data) => {
		return post('/delivery-orders', data)
	},
	checkStatus: (data) => {
		return patch('/delivery-orders/status', data)
	},
	downloadCSV: async (params = {}) => {
		const response = await get('/delivery-orders/csv', {
			responseType: 'blob',
			params
		})
		const binaryData = [response.data]
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }))
		const fileName = `delivery_orders_${new Date().toISOString()}.csv`
		link.download = fileName
		link.click()
		link.remove()
	},
	fetchCSV: async (params = {}) => {
		return get('/delivery-orders/csv', {
			responseType: 'blob',
			params
		})
	}
}
