import { Modal, notification, Popover, Table, Typography, Alert, Button } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { SalesOrders } from '../../services/api/sales-orders'
import { calculateDiscount, getFormattedCurrency, capitalizeWord, isNotEmpty } from '../../utils'
import { PartnerType, onHoldReasons, orderStatuses, paths, returnOrCancelReasons, localStorageKeys } from '../../utils/constants'
import CustomSelectSecondary from '../custom-select-secondary'
import Input from '../input'
import CustomTable from '../table'
require('./ECommerceUpdateOrderStatusModal.less')
import styles from './ECommerceUpdateOrderStatusModal.module.css'
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import Link from 'next/link'
import DeliveryPartnerOption from '../delivery-partner-option'
import { determineDeliveryCharge, convertDeliveryAreaOptionToDeliveryArea, convertDeliveryAreaToDeliveryAreaOption } from '../../utils/delivery-partners'
import { useSelector } from 'react-redux'
import DeliveryAreaSelect from '../delivery-area-select'
import DeliveryAreaSelectRedX from '../delivery-area-select-redx'
import DeliveryAreaSelectECourier from '../delivery-area-select-e-courier'
import DeliveryAreaSelectPidex from '../delivery-area-select-pidex'
import DeliveryAreaSelectTCSCourier from '../delivery-area-select-tcs-courier'
import DeliveryAreaSelectRiderCourier from '../delivery-area-select-rider-courier'
import DeliveryAreaSelectPostEx from '../delivery-area-select-post-ex'
import DeliveryAreaSelectMpCourier from '../delivery-area-select-mp-courier'
import DeliveryAreaSelectLeopards from '../delivery-area-select-leopards'
import DeliveryAreaSelectBlueEx from '../delivery-area-select-blue-ex'
import TextArea from '../text-area'
import NumberInput from '../number-input'
import moment from 'moment'
import ECommerceUpdateOrderDisabledPartnerModal from '../e-commerce-update-order-disabled-partner-modal'
import { Locations } from '../../services/api/locations'
import DeliveryAreaSelectMoveX from '../delivery-area-select-movex'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import DeliveryAreaSelectPaperfly from '../delivery-area-select-paperfly'

const { Summary } = Table

const options = [
	{
		label: 'Pending',
		status: orderStatuses.SALES_ORDER_REQUESTED_STATUS,
		background: 'var(--black-100)'
	},
	{
		label: 'On Hold',
		status: orderStatuses.SALES_ORDER_ON_HOLD_STATUS,
		background: '#FFCDCD'
	},
	{
		label: 'Approved',
		status: orderStatuses.SALES_ORDER_APPROVED_STATUS,
		background: 'var(--caution-100)'
	},
	{
		label: 'Processing',
		status: orderStatuses.SALES_ORDER_PROCESSING_STATUS,
		background: 'var(--success-60)'
	},
	{
		label: 'Shipped',
		status: orderStatuses.SALES_ORDER_SHIPPED_STATUS,
		background: 'var(--success-130)'
	},
	{
		label: 'In-Transit',
		status: orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS,
		background: 'var(--success-150)'
	},
	{
		label: 'Delivered',
		status: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
		background: 'var(--primary-100)',
		dropdownItems: [
			{
				label: 'Payment Due',
				status: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
				subStatus: orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS,
				background: '#F6BA5F'
			},
			{
				label: 'Payment Collected',
				status: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
				subStatus: orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS,
				background: '#18A188'
			}
		]
	},
	{
		label: 'Flagged',
		status: orderStatuses.SALES_ORDER_FLAGGED_STATUS,
		background: 'var(--error-80)',
		dropdownItems: [
			{
				label: 'Returned',
				status: orderStatuses.SALES_ORDER_FLAGGED_STATUS,
				subStatus: orderStatuses.SALES_ORDER_RETURNED_STATUS,
				background: 'var(--error-120)'
			}
		]
	},
	{
		label: 'Cancelled',
		status: orderStatuses.SALES_ORDER_CANCELLED_STATUS,
		background: 'var(--error-100)'
	}
]

const flaggedStatusOptions = options.map((option) =>
	option.status === orderStatuses.SALES_ORDER_FLAGGED_STATUS ? {
		...option,
		dropdownItems: [
			{
				label: 'Returned',
				status: orderStatuses.SALES_ORDER_FLAGGED_STATUS,
				subStatus: orderStatuses.SALES_ORDER_RETURNED_STATUS,
				background: 'var(--error-120)'
			},
			{
				label: 'Damaged',
				status: orderStatuses.SALES_ORDER_FLAGGED_STATUS,
				subStatus: orderStatuses.SALES_ORDER_DAMAGED_STATUS,
				background: 'var(--error-100)'
			}
		]
	} : { ...option }
)
export const approveTableColumns = [
	{
		title: 'Item Name',
		dataIndex: 'itemName',
		key: 'itemName',
		render: ({ name, id }) => {
			return (
				<Link href={`${paths.PRODUCTS}/${id}`}>
					<a>{name}</a>
				</Link>
			)
		}
	},
	{
		title: 'Requested Qty',
		dataIndex: 'requestedQty',
		key: 'requestedQty'
	},
	{
		title: 'Available Qty',
		key: 'availableQty',
		render: (item) =>
			<span className={item.requestedQty > item.availableQty && styles.underStocked}>{item.availableQty}</span>
	},
	{
		title: 'Warehouse',
		dataIndex: 'warehouse',
		key: 'warehouse'
	}
]

const itemNameColumn = {
	title: 'Item Name',
	key: 'itemName',
	dataIndex: 'itemName',
	render: ({ name, id }) => {
		return (
			<Link href={`${paths.PRODUCTS}/${id}`}>
				<a>{name}</a>
			</Link>
		)
	}
}

const underStockedTableColumns = [
	itemNameColumn,
	{
		title: 'Shortage Qty',
		key: 'shortageQty',
		dataIndex: 'shortageQty'
	}
]

const inactiveProductsTableColumns = [itemNameColumn]

const returnReasons = [
	{ value: returnOrCancelReasons.DAMAGED_PRODUCT },
	{ value: returnOrCancelReasons.DELAY_DELIVERY },
	{ value: returnOrCancelReasons.WRONG_PRODUCT },
	{ value: returnOrCancelReasons.OUT_OF_ZONE },
	{ value: returnOrCancelReasons.FRAUD_CUSTOMER },
	{ value: returnOrCancelReasons.DELIVERY_MAN_CARELESSNESS },
	{ value: returnOrCancelReasons.OTHER_REASON }
]

const cancelReasons = [
	{ value: returnOrCancelReasons.HIGH_PRICE },
	{ value: returnOrCancelReasons.SHORT_TIME_DELIVERY },
	{ value: returnOrCancelReasons.OUT_OF_ZONE },
	{ value: returnOrCancelReasons.DUPLICATE_ORDER },
	{ value: returnOrCancelReasons.FAKE_ORDER },
	{ value: returnOrCancelReasons.CHANGED_MIND },
	{ value: returnOrCancelReasons.OTHER_REASON }
]

const onHoldReasonOptions = [
	{ value: onHoldReasons.CUSTOMER_UNREACHABLE },
	{ value: onHoldReasons.CALL_NOT_RECEIVED },
	{ value: onHoldReasons.CALL_SCHEDULED },
	{ value: onHoldReasons.INVALID_PHONE_NUMBER },
	{ value: onHoldReasons.CUSTOMER_DECISION_PENDING },
	{ value: onHoldReasons.PRE_ORDER },
	{ value: onHoldReasons.OTHER }
]

const modalViews = {
	CHANGE_STATUS: 0,
	DAMAGED: 1,
	APPROVED: 2,
	PROCESSING: 3,
	DELIVERED: 4,
	DELIVERED_PAYMENT_DUE: 5,
	DELIVERED_PAYMENT_COLLECTED: 6,
	RETURNED: 7,
	ON_HOLD: 8
}

const allowedStatusesForDeliveryPartnerSelection = [
	orderStatuses.SALES_ORDER_PROCESSING_STATUS,
	orderStatuses.SALES_ORDER_SHIPPED_STATUS,
	orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS
]

export const getApprovedTableData = ({ pickUpLocationId, salesOrderItems, pickUpLocation: { label, address } }, i) =>
	salesOrderItems.map(({ quantity, product: { id, name, inventoryItems } }) => ({
		key: i,
		itemName: { name, id },
		requestedQty: +quantity,
		availableQty: getAvailableQuantity(pickUpLocationId, inventoryItems),
		warehouse: label || address
	}))

export function getAvailableQuantity (pickUpLocationId, inventoryItems = []) {
	let stock = inventoryItems.find(({ locationId }) => locationId === pickUpLocationId)
	if (!stock) {
		if (inventoryItems.some(item => item.location)) {
			stock = inventoryItems.find(({ location: { id: locationId } }) => locationId === pickUpLocationId)
		} else {
			return 0
		}
	}

	return stock?.quantity || 0
}

export const renderUnderStockedTable = (salesOrders) => {
	return (
		<>
			<p className={styles.underStockedTableTitle}>Understocked Products</p>
			<CustomTable
				columns={underStockedTableColumns}
				dataSource={getUnderStockedTableData(salesOrders)}
				pagination={false}
				size='small'
				scroll={{ y: '300px' }}
			/>
		</>
	)
}

export const getUnderStockedTableData = (salesOrders) => {
	const dataSource = salesOrders.reduce((acc, { pickUpLocationId, salesOrderItems }) => {
		const underStockedProducts = salesOrderItems.reduce((acc, { quantity, product: { id, name, inventoryItems } }) => {
			const availableQty = getAvailableQuantity(pickUpLocationId, inventoryItems)
			const underStockedProducts = quantity > availableQty ?
				[...acc, { productId: id, itemName: { name, id }, shortageQty: quantity - availableQty }] : acc
			return underStockedProducts
		}, [])
		return [...acc, ...underStockedProducts]
	}, [])
		.reduce((underStockedProducts, { shortageQty, productId, itemName }) => {
			const duplicateProduct = underStockedProducts.find((item) => item.productId === productId)
			duplicateProduct && (duplicateProduct.shortageQty = duplicateProduct.shortageQty + shortageQty)

			return duplicateProduct ? underStockedProducts : [...underStockedProducts, { shortageQty, productId, itemName }]
		}, [])

	return dataSource
}

const ECommerceUpdateOrderStatusModal = ({
	visible,
	salesOrderIds,
	defaultStatus,
	status,
	subStatus,
	disableStatusSelect,
	onCancel,
	onComplete,
	permissions
}) => {
	const { companyDetails, preferences } = useSelector(state => state.authReducer)
	const [salesOrders, setSalesOrders] = useState([])
	const [deliveryPartners, setDeliveryPartners] = useState([])
	const [selectedStatus, setSelectedStatus] = useState(null)
	const [locationDeliveryPartnersMap, setLocationDeliveryPartnersMap] = useState({})
	const [isLoadingDeliveryPartners, setIsLoadingDeliveryPartners] = useState(false)
	const [pickUpLocations, setPickUpLocations] = useState([])
	const [damagedOrders, setDamagedOrders] = useState([])
	const [damagedOrdersMap, setDamagedOrdersMap] = useState({})
	const [currentStep, setCurrentStep] = useState(modalViews.CHANGE_STATUS)
	const [steps, setSteps] = useState([])
	const [lastDeliveryAddressToDeliveryAreaMapping, setLastDeliveryAddressToDeliveryAreaMapping] = useLocalStorage(localStorageKeys.LAST_DELIVERY_ADDRESS_TO_DELIVERY_AREA_MAPPING, {})
	const previousStep = useMemo(() => {
		if (steps.length === 0) {
			return null
		}
		const currentStepIndex = steps.findIndex((step) => step === currentStep)
		if (currentStepIndex <= 0) {
			return null
		}
		return steps[currentStepIndex - 1]
	}, [steps, currentStep])
	const [processingOrders, setProcessingOrders] = useState([])
	const [receivableAmount, setReceivableAmount] = useState()
	const [returnedReason, setReturnedReason] = useState()
	const [canceledReason, setCanceledReason] = useState()
	const [onHoldReason, setOnHoldReason] = useState('')
	const [onHoldOtherReason, setOnHoldOtherReason] = useState('')
	const [followUpDate, setFollowUpDate] = useState()
	const [isOtherReasonSelected, setIsOtherReasonSelected] = useState(false)
	const [flagReason, setFlagReason] = useState()
	const isSomeProductsUnderStocked = useMemo(() => salesOrders.some(({ pickUpLocationId, salesOrderItems }) =>
		salesOrderItems.some(({ quantity, product: { inventoryItems } }) => quantity > getAvailableQuantity(pickUpLocationId, inventoryItems))
	), [salesOrders])
	const inactiveProducts = useMemo(() => {
		const salesOrderItems = salesOrders.reduce((acc, { salesOrderItems }) => [...acc, ...salesOrderItems], [])
		const inactiveProducts = salesOrderItems.filter(({ product: { deleted } }) => deleted)
		return inactiveProducts
	}, [salesOrders])
	const [isLoading, setIsLoading] = useState(false)
	const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
	const [isCreatingOrUpdatingSalesOrder, setIsCreatingOrUpdatingSalesOrder] = useState(false)
	const [deliveredSalesOrderItems, setDeliveredSalesOrderItems] = useState([])
	const [returnedDeliveredSalesOrderItems, setReturnedDeliveredSalesOrderItems] = useState([])
	const [returnedSalesOrderItems, setReturnedSalesOrderItems] = useState([])
	const isReturningSalesOrderItems = useMemo(() => {
		return !returnedSalesOrderItems.some(({ returnedQuantity }) => returnedQuantity > 0)
	}, [returnedSalesOrderItems])
	const [selectedSalesOrderIds, setSelectedSalesOrderIds] = useState([])
	const [selectedWarehouseFromProcessingView, setSelectedWarehouseFromProcessingView] = useState(null)
	const [selectedWarehouseDeliveryPartnerOptions, setSelectedWarehouseDeliveryPartnerOptions] = useState([])
	const [selectedDeliveryPartnerFromProcessingView, setSelectedDeliveryPartnerFromProcessingView] = useState(null)
	const [disabledPartnerOrders, setDisabledPartnerOrders] = useState([])
	const [isInactiveModalVisible, setIsInactiveModalVisible] = useState(false)
	const filteredApproveTableColumns = useMemo(() => {
		if (companyDetails?.isInventoryEnabled) {
			return approveTableColumns
		} else {
			return approveTableColumns.filter(({ key }) => key !== 'availableQty' && key !== 'warehouse')
		}
	}, [companyDetails?.isInventoryEnabled])
	const isChangingStatusFromPaymentDueToPaymentCollected = useMemo(() => {
		if (selectedStatus?.subStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS) {
			if (subStatus?.toLowerCase() === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS) {
				return true
			} else if (salesOrders && salesOrders.length === 1 && salesOrders[0].subStatus?.toLowerCase() === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS) {
				return true
			}
		}
		return false
	}, [
		subStatus,
		selectedStatus,
		salesOrders
	])
	const totalReceivableAmount = useMemo(() => {
		if (salesOrders.length === 1) {
			const totalPrice = deliveredSalesOrderItems.reduce((acc, { price, updatedDeliveredQuantity, discountAmount, discount }) => {
				let totalPrice = +price * updatedDeliveredQuantity
				let totalDiscount = 0
				if (+discount > 0) {
					totalDiscount = calculateDiscount(totalPrice, +discount)
				} else if (+discountAmount > 0) {
					totalDiscount = +discountAmount
				}
				totalPrice = totalPrice - totalDiscount
				return acc + +totalPrice.toFixed(2)
			}, 0)
			const salesOrder = salesOrders[0]
			const discount = +salesOrder.discount > 0 ? calculateDiscount(totalPrice, +salesOrder.discount) : +salesOrder.discountAmount
			return +totalPrice.toFixed(2) - discount + +salesOrder.deliveryCharge
		}
		return salesOrders.reduce((acc, b) => {
			return acc + +(+b.totalDueAmount - +b.totalPaidAmount).toFixed(2)
		}, 0)
	}, [deliveredSalesOrderItems, salesOrders])

	const totalReturnedAmount = useMemo(() => {
		const totalPrice = returnedDeliveredSalesOrderItems.reduce((acc, { price, updatedReturnedQuantity, discountAmount, discount }) => {
			let totalPrice = +price * updatedReturnedQuantity
			let totalDiscount = 0
			if (+discount > 0) {
				totalDiscount = calculateDiscount(totalPrice, +discount)
			} else if (+discountAmount > 0) {
				totalDiscount = +discountAmount
			}
			totalPrice = totalPrice - totalDiscount
			return acc + +totalPrice.toFixed(2)
		}, 0)
		return +totalPrice.toFixed(2)
	}, [returnedDeliveredSalesOrderItems])

	const processingSalesOrders = useMemo(() => {
		return selectedWarehouseFromProcessingView ?
			processingOrders
				.filter(({ pickUpLocationId }) => pickUpLocationId === selectedWarehouseFromProcessingView) :
			processingOrders
	}, [selectedWarehouseFromProcessingView, processingOrders])

	useEffect(() => {
		if (!defaultStatus) {
			return setSelectedStatus(null)
		}
		const statusOption = options.find((option) => option.status === defaultStatus)
		setSelectedStatus(statusOption)
	}, [defaultStatus])

	const onDeliveryAreaChanged = (orderId, partnerType, deliveryAreaOption = {}) => {
		setProcessingOrders(prevProcessingOrders =>
			prevProcessingOrders.map(order => {
				if (order.id === orderId) {
					const updatedOrder = {
						...order
					}
					if (partnerType === PartnerType.REDX && deliveryAreaOption?.division) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.E_COURIER && deliveryAreaOption?.city) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.PATHAO && deliveryAreaOption?.city) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.PIDEX && deliveryAreaOption?.city) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.TCS_COURIER && (deliveryAreaOption?.originCity || deliveryAreaOption?.city)) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.RIDER_COURIER && (deliveryAreaOption?.originCity || deliveryAreaOption?.city)) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.POST_EX && (deliveryAreaOption?.pickupAddress || deliveryAreaOption?.city)) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.MP_COURIER && (deliveryAreaOption?.pickupAddress || deliveryAreaOption?.city)) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.LEOPARDS && deliveryAreaOption?.city) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.BLUE_EX && (deliveryAreaOption?.city || deliveryAreaOption?.serviceType)) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.MOVEX && deliveryAreaOption?.area) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					} else if (partnerType === PartnerType.PAPERFLY && deliveryAreaOption?.zone) {
						updatedOrder.deliveryArea = convertDeliveryAreaOptionToDeliveryArea(deliveryAreaOption, partnerType)
					}
					return updatedOrder
				} else {
					return order
				}
			})
		)
	}

	const processingTableColumns = [
		{
			title: 'Invoice Number',
			key: 'invoiceNumber',
			width: 150,
			render: ({ internalId, id }) => {
				return (
					<Link href={`${paths.SALES_ORDERS}/${id}`}>
						<a>{internalId}</a>
					</Link>
				)
			}
		},
		{
			title: 'Pickup Address',
			key: 'pickUpLocation',
			width: 280,
			render: ({ pickUpLocation }) => {
				return (
					`${pickUpLocation.label || pickUpLocation.address}`
				)
			}
		},
		{
			title: 'Location',
			key: 'location',
			width: 280,
			render: ({ location }) => {
				return (
					`${location.label || location.address}`
				)
			}
		},
		{
			title: 'Delivery Partner',
			key: 'deliveryPartner',
			width: 200,
			className: styles.deliveryPartnerCell,
			render: ({ id: salesOrderId, preferredDeliveryPartner, pickUpLocationId, locationId }) => {
				const deliveryPartners = locationDeliveryPartnersMap[pickUpLocationId]?.deliveryPartners ?? []
				return (
					<CustomSelectSecondary
						title='Select Partner'
						optionLabelProp='label'
						options={deliveryPartners.map(({ id, name, type }) => ({ value: id, label: name, type }))}
						value={preferredDeliveryPartner}
						onChange={async value => {
							const updatedSalesOrders = [...processingOrders]
							const currentOrder = updatedSalesOrders.find(({ id }) => id === salesOrderId)
							currentOrder.preferredDeliveryPartner = value
							currentOrder.deliveryPartner = deliveryPartners.find((p) => p.id === value)
							let cachedDeliveryArea = null
							if (
								lastDeliveryAddressToDeliveryAreaMapping[currentOrder.deliveryPartner?.type] &&
								lastDeliveryAddressToDeliveryAreaMapping[currentOrder.deliveryPartner?.type][locationId]
							) {
								cachedDeliveryArea = convertDeliveryAreaOptionToDeliveryArea(lastDeliveryAddressToDeliveryAreaMapping[currentOrder.deliveryPartner?.type][locationId], currentOrder.deliveryPartner?.type)
							}
							currentOrder.deliveryArea = cachedDeliveryArea
							if (!currentOrder.isDeliveryFree) {
								if (!(currentOrder.integrationId && preferences.websiteOrdersDeliveryFeeEnabled) &&
                                !(preferences.manualOrdersDeliveryFeeEnabled && !currentOrder.integrationId)) {
									currentOrder.deliveryCharge = await getDeliveryCharge(locationId, value, pickUpLocationId)
								}
							}
							setProcessingOrders(updatedSalesOrders)
							onDeliveryAreaChanged(salesOrderId, currentOrder.deliveryPartner?.type, {})
						}}
						optionRender={DeliveryPartnerOption}
						popupContainer={() => document.querySelector(`.${styles.modal}`)}
					/>
				)
			}
		},
		{
			title: 'Delivery Area',
			key: 'deliveryArea',
			width: 280,
			render: ({ id, deliveryPartner, deliveryArea, location }) => {
				const deliveryPartnerType = deliveryPartner?.type
				const deliveryAreaOption = convertDeliveryAreaToDeliveryAreaOption(deliveryArea, deliveryPartnerType)

				switch (deliveryPartnerType) {
					case PartnerType.MOVEX:
						return (
							<DeliveryAreaSelectMoveX
								title='Delivery Area'
								error={!deliveryAreaOption?.area}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.PAPERFLY:
						return (
							<DeliveryAreaSelectPaperfly
								title='Delivery Area'
								error={!deliveryAreaOption?.zone || !deliveryAreaOption?.area}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.REDX:
						return (
							<DeliveryAreaSelectRedX
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={
									!deliveryAreaOption?.division
									|| !deliveryAreaOption?.district
									|| !deliveryAreaOption?.area
								}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.E_COURIER:
						return (
							<DeliveryAreaSelectECourier
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={
									!deliveryAreaOption?.city
									|| !deliveryAreaOption?.area
								}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.PIDEX:
						return (
							<DeliveryAreaSelectPidex
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={
									deliveryAreaOption?.city
									&& !deliveryAreaOption?.zone
								}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.TCS_COURIER:
						return (
							<DeliveryAreaSelectTCSCourier
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.originCity || !deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.RIDER_COURIER:
						return (
							<DeliveryAreaSelectRiderCourier
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.originCity || !deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.POST_EX:
						return (
							<DeliveryAreaSelectPostEx
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.pickupAddress || !deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.MP_COURIER:
						return (
							<DeliveryAreaSelectMpCourier
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.LEOPARDS:
						return (
							<DeliveryAreaSelectLeopards
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					case PartnerType.BLUE_EX:
						return (
							<DeliveryAreaSelectBlueEx
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.city || !deliveryAreaOption?.serviceType}
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
							/>
						)
					default:
						return (
							<DeliveryAreaSelect
								address={location?.address}
								disabled={!deliveryPartner || deliveryPartner?.type !== PartnerType.PATHAO}
								title='Delivery Area'
								value={deliveryAreaOption}
								onChange={deliveryArea => onDeliveryAreaChanged(id, deliveryPartner?.type, deliveryArea)}
								deliveryPartnerId={deliveryPartner?.type === PartnerType.PATHAO ? deliveryPartner?.id : null}
								popupContainer={() => document.querySelector(`.${styles.modal}`)}
								style={{ width: 280 }}
							/>
						)
				}
			}
		},
		{
			title: 'Delivery Fee',
			key: 'deliveryCharge',
			width: 200,
			render: ({ id: salesOrderId, deliveryCharge }) => {
				return (
					<Input
						inputClassName='delivery-fee-input'
						onChange={value => {
							setProcessingOrders(prevProcessingOrders =>
								prevProcessingOrders.map(prevProcessingOrder => ({
									...prevProcessingOrder,
									deliveryCharge: prevProcessingOrder.id === salesOrderId ?
										isNaN(+value) ? 0 : value :
										prevProcessingOrder.deliveryCharge
								}))
							)
						}}
						value={deliveryCharge}
						min={0}
						type='currency'
						disableCurrency={true}
					/>
				)
			}
		}
	]

	const deliveredTableColumns = [
		{
			title: 'Invoice Number',
			key: 'invoiceNumber',
			render: ({ internalId, id }) => {
				return (
					<Link href={`${paths.SALES_ORDERS}/${id}`}>
						<a>{internalId}</a>
					</Link>
				)
			}
		},
		{
			title: 'Product Quantity',
			key: 'orderQty',
			render: ({ salesOrderItems }) => {
				const hasDeliveredQty = salesOrderItems.some(salesOrderItem => {
					return salesOrderItem.deliveredQuantity > 0
				})
				const orderQty = salesOrderItems.reduce((a, b) => {
					let quantity = b.quantity
					if (hasDeliveredQty) {
						quantity = b.deliveredQuantity
					}
					return a + quantity
				}, 0)
				return orderQty
			}
		},
		{
			title: 'Due Amount',
			key: 'orderAmount',
			render: ({ totalDueAmount, totalPaidAmount }) => {
				const orderAmount = +totalDueAmount - +totalPaidAmount
				return `${getFormattedCurrency(orderAmount)}`
			}
		},
		{
			title: 'Receivable Amount',
			key: 'receivedAmount',
			render: ({ id, totalDueAmount, totalPaidAmount, receivedAmount }) => {
				const orderAmount = +totalDueAmount - +totalPaidAmount
				return (
					<NumberInput
						min={0}
						max={+orderAmount}
						value={isNotEmpty(receivedAmount) ? receivedAmount : orderAmount}
						onChange={value => onReceivedAmountChanged(id, value)}
					/>
				)
			}
		}
	]

	useEffect(() => {
		if (!allowedStatusesForDeliveryPartnerSelection.includes(selectedStatus?.status)) {
			return
		}
		getDeliveryPartners()
	}, [salesOrders])

	useEffect(() => {
		if (selectedStatus) {
			if (selectedStatus.status === orderStatuses.SALES_ORDER_APPROVED_STATUS) {
				getSalesOrders(true)
			} else {
				getSalesOrders()
			}
		}
	}, [selectedStatus])

	useEffect(() => {
		if (!steps.includes(currentStep)) {
			setSteps(steps.concat([currentStep]))
		}
		if (previousStep !== currentStep) {
			if (currentStep === modalViews.DELIVERED) {
				getSalesOrders()
			}
		}
	}, [currentStep])

	const reasonState = (reason) => {
		setIsOtherReasonSelected(false)
		setFlagReason(reason)
		if (reason === returnOrCancelReasons.OTHER_REASON) {
			setFlagReason('')
			setIsOtherReasonSelected(true)
		}
	}

	useEffect(() => {
		setIsOtherReasonSelected(false)
		setFlagReason(null)
	}, [selectedStatus])

	useEffect(() => {
		reasonState(returnedReason)
	}, [returnedReason])

	useEffect(() => {
		reasonState(canceledReason)
	}, [canceledReason])

	useEffect(() => {
		if (!selectedDeliveryPartnerFromProcessingView) {
			return
		}
		fetchDeliveryChargeForSelectedProcessingOrders()
	}, [selectedDeliveryPartnerFromProcessingView])

	useEffect(() => !selectedSalesOrderIds.length && setSelectedDeliveryPartnerFromProcessingView(null), [selectedSalesOrderIds.length])

	useEffect(() => !selectedWarehouseFromProcessingView && setSelectedSalesOrderIds([]), [selectedWarehouseFromProcessingView])

	const fetchDeliveryChargeForSelectedProcessingOrders = async () => {
		try {
			setIsLoading(true)
			const updatedSalesOrders = [...processingOrders]
			const deliveryCharges = await Promise.all(
				selectedSalesOrderIds.map(async (salesOrderId) => {
					const { locationId, isDeliveryFree, pickUpLocationId } = updatedSalesOrders.find(({ id }) => salesOrderId === id)
					if (isDeliveryFree) {
						return 0
					}
					return await getDeliveryCharge(locationId, selectedDeliveryPartnerFromProcessingView, pickUpLocationId)
				})
			)
			const deliveryPartner = deliveryPartners.find(p => p.id === selectedDeliveryPartnerFromProcessingView)
			selectedSalesOrderIds.forEach((salesOrderId, i) => {
				const salesOrder = updatedSalesOrders.find(({ id }) => salesOrderId === id)
				salesOrder.preferredDeliveryPartner = selectedDeliveryPartnerFromProcessingView
				salesOrder.deliveryPartner = deliveryPartner
				if (!(salesOrder.integrationId && preferences.websiteOrdersDeliveryFeeEnabled) && !(preferences.manualOrdersDeliveryFeeEnabled && !salesOrder.integrationId)) {
					salesOrder.deliveryCharge = deliveryCharges[i]
				}
				let cachedDeliveryArea = null
				if (
					lastDeliveryAddressToDeliveryAreaMapping[salesOrder.deliveryPartner?.type] &&
					lastDeliveryAddressToDeliveryAreaMapping[salesOrder.deliveryPartner?.type][salesOrder?.locationId]
				) {
					cachedDeliveryArea = convertDeliveryAreaOptionToDeliveryArea(lastDeliveryAddressToDeliveryAreaMapping[salesOrder.deliveryPartner?.type][salesOrder?.locationId], salesOrder.deliveryPartner?.type)
				}
				salesOrder.deliveryArea = cachedDeliveryArea
			})
			setProcessingOrders(updatedSalesOrders)
		} catch ({ message }) {
			notification.error({
				message: 'Could not retrieve delivery charges.',
				description: message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const getSalesOrders = async (inventory = false) => {
		try {
			setIsLoading(true)
			const { data } = await SalesOrders.multiple(salesOrderIds, inventory)
			setSalesOrders(data)
		} catch (e) {
			notification.error({
				message: 'Unable to get sales orders',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const getDeliveryPartners = async () => {
		try {
			setIsLoadingDeliveryPartners(true)
			const pickUpLocationIdsMap = {}
			salesOrders.forEach(salesOrder => {
				if (salesOrder.pickUpLocationId) {
					pickUpLocationIdsMap[salesOrder.pickUpLocationId] = true
				}
			})
			const pickUpLocationIds = Object.keys(pickUpLocationIdsMap)
			const locationDeliveryPartnersMap = {}
			const { data: { results: pickUpLocations } } = await Locations.index({ ids: pickUpLocationIds, includeDeliveryPartnerPreference: true })
			await Promise.all(pickUpLocations.map(async pickUpLocation => {
				const { data: { results } } = await DeliveryPartners.index({ active: true, warehouseId: pickUpLocation.id })
				locationDeliveryPartnersMap[pickUpLocation.id] = {
					deliveryPartners: results
				}
				if (pickUpLocation && pickUpLocation.deliveryPartnerWarehousePreferences?.length) {
					const { deliveryPartnerId } = pickUpLocation.deliveryPartnerWarehousePreferences[0]
					locationDeliveryPartnersMap[pickUpLocation.id].preferredDeliveryPartnerId = deliveryPartnerId
				}
			}))
			const processingOrders = await Promise.all(salesOrders
				.filter(salesOrder => {
					if (!salesOrder.deliveryPartner) {
						return true
					} else if (salesOrder.deliveryPartner) {
						if (salesOrder.deliveryPartner.type !== PartnerType.STEADFAST && !salesOrder.deliveryArea) {
							return true
						}
						return false
					} else {
						return false
					}
				})
				.map(async salesOrder => {
					const processingOrder = { ...salesOrder }
					const preferredDeliveryPartnerId = locationDeliveryPartnersMap[salesOrder.pickUpLocationId]?.preferredDeliveryPartnerId
					const deliveryPartners = locationDeliveryPartnersMap[salesOrder.pickUpLocationId]?.deliveryPartners || []
					if (preferredDeliveryPartnerId) {
						processingOrder.preferredDeliveryPartner = preferredDeliveryPartnerId
						processingOrder.deliveryPartner = deliveryPartners.find(p => p.id === preferredDeliveryPartnerId)
						if (!processingOrder.isDeliveryFree) {
							if (!(processingOrder.integrationId && preferences.websiteOrdersDeliveryFeeEnabled) && !(preferences.manualOrdersDeliveryFeeEnabled && !processingOrder.integrationId)) {
								processingOrder.deliveryCharge = await getDeliveryCharge(salesOrder.locationId, preferredDeliveryPartnerId, salesOrder.pickUpLocationId)
							}
						}
					}
					return processingOrder
				})
			)
			setPickUpLocations(pickUpLocations)
			const deliveryPartners = []
			Object.values(locationDeliveryPartnersMap).forEach(({ deliveryPartners: locationDeliveryPartners }) => {
				deliveryPartners.push(...locationDeliveryPartners)
			})
			setDeliveryPartners(deliveryPartners)
			setLocationDeliveryPartnersMap(locationDeliveryPartnersMap)
			setProcessingOrders(processingOrders)
		} catch (e) {
			notification.error({
				message: 'Couldn\'t get delivery partners',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDeliveryPartners(false)
		}
	}

	const changeStatus = async (newSelectedOrders) => {
		const filteredSalesOrders = newSelectedOrders ? newSelectedOrders : salesOrders
		try {
			setIsUpdatingStatus(true)
			if (filteredSalesOrders.length > 0) {
				const { data } = await SalesOrders.updateOrders(filteredSalesOrders.map(({ id }) => ({
					id,
					status: selectedStatus.status,
					subStatus: selectedStatus.subStatus,
					flagReason
				})))
				if (data.errors.length > 0) {
					throw new Error([...new Set(data.errors)].toString())
				}
				notification.success({
					message: 'Updated Order Statuses',
					description: `Successfully updated order statuses to ${selectedStatus.label}.`,
					placement: 'bottomLeft'
				})
				onComplete(status, selectedStatus.status, filteredSalesOrders.map(({ id }) => id))
			} else {
				throw new Error('No orders selected')
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Update Order Statuses',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingStatus(false)
		}
	}

	const changeStatusToProcessing = async () => {
		const targetStatus = allowedStatusesForDeliveryPartnerSelection.includes(selectedStatus.status) ? selectedStatus.status : orderStatuses.SALES_ORDER_PROCESSING_STATUS
		try {
			setIsUpdatingStatus(true)
			const orders = processingOrders.map(order => {
				switch (order?.deliveryPartner?.type) {
					case PartnerType.REDX:
						return {
							...order,
							deliveryArea: order?.deliveryArea?.divisionId && order?.deliveryArea?.districtId && order?.deliveryArea?.areaId
								? order.deliveryArea
								: null
						}
					case PartnerType.E_COURIER:
						return {
							...order,
							deliveryArea: order?.deliveryArea?.cityId && order?.deliveryArea?.areaId && order?.deliveryArea?.thanaId && order?.deliveryArea?.postCodeId
								? order.deliveryArea
								: null
						}
					case PartnerType.PIDEX:
						return {
							...order,
							deliveryArea: order?.deliveryArea?.cityId && order?.deliveryArea?.zoneId
								? order.deliveryArea
								: null
						}
					case PartnerType.TCS_COURIER:
					case PartnerType.RIDER_COURIER:
						return {
							...order,
							deliveryArea: order?.deliveryArea?.originCityId && order?.deliveryArea?.cityId
								? order.deliveryArea
								: null
						}
					case PartnerType.POST_EX:
						return {
							...order,
							deliveryArea: order?.deliveryArea?.pickupAddressId && order?.deliveryArea?.cityId
								? order.deliveryArea
								: null
						}
					case PartnerType.MP_COURIER:
					case PartnerType.LEOPARDS:
						return {
							...order,
							deliveryArea: order?.deliveryArea?.cityId
								? order.deliveryArea
								: null
						}
					case PartnerType.BLUE_EX:
						return {
							...order,
							deliveryArea: order?.deliveryArea?.cityId && order?.deliveryArea?.serviceTypeId
								? order.deliveryArea
								: null
						}
					case PartnerType.MOVEX:
						return {
							...order,
							deliveryArea: order?.deliveryArea?.areaId
								? order.deliveryArea
								: null
						}
					default:
						return order
				}
			})
				.map(({ id, preferredDeliveryPartner, deliveryCharge, deliveryArea, deliveryPartner, location }) => ({
					id,
					status: targetStatus,
					preferredDeliveryPartner,
					deliveryArea,
					deliveryCharge,
					deliveryPartner,
					location
				}))

			const { data } = await SalesOrders.updateOrders(orders)
			if (data.errors.length > 0) {
				throw new Error([...new Set(data.errors)].toString())
			}
			notification.success({
				message: 'Updated Order Statuses',
				description: `Successfully updated order statuses to ${selectedStatus.label.toLowerCase()}.`,
				placement: 'bottomLeft'
			})
			orders.filter(({ deliveryArea }) => deliveryArea).forEach(({ deliveryPartner, location, deliveryArea }) => {
				updateLastDeliveryAddressToDeliveryAreaMapping(deliveryPartner?.type, location?.id, deliveryArea)
			})
			onComplete(status, targetStatus)
		} catch (e) {
			notification.error({
				message: 'Unable to Update Order Statuses',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingStatus(false)
		}
	}

	const changeStatusToDelivered = async (subStatus) => {
		try {
			setIsUpdatingStatus(true)
			if (salesOrders.length === 1) {
				const salesOrder = salesOrders[0]
				let createReturnSalesOrder = false
				const salesOrderItems = deliveredSalesOrderItems.map(salesOrderItem => {
					const {
						id,
						quantity,
						updatedDeliveredQuantity,
						discount,
						discountAmount
					} = salesOrderItem
					if (!createReturnSalesOrder && updatedDeliveredQuantity < quantity && salesOrder.status.toLowerCase() !== orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
						createReturnSalesOrder = true
					}

					const discountValue = +discount > 0 ? +discount : 0
					const discountAmountValue = +discountAmount > 0 ? +discountAmount : 0

					return {
						salesOrderItemId: id,
						deliveredQuantity: updatedDeliveredQuantity,
						flaggedQuantity: updatedDeliveredQuantity < quantity ? quantity - updatedDeliveredQuantity : 0,
						discount: discountValue,
						discountAmount: discountAmountValue
					}
				})
				await SalesOrders.update(salesOrder.id, {
					status: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
					subStatus,
					salesOrderItems,
					createReturnSalesOrder,
					returnedSalesOrderItems: returnedDeliveredSalesOrderItems
						.filter(returnedSalesOrderItem => returnedSalesOrderItem.updatedReturnedQuantity > 0)
						.map(returnedSalesOrderItem => ({
							...returnedSalesOrderItem,
							quantity: returnedSalesOrderItem.updatedReturnedQuantity,
							deliveredQuantity: 0,
							flaggedQuantity: 0
						})),
					flagReason: createReturnSalesOrder ? flagReason : undefined,
					totalPaidAmount: subStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS ? +totalReceivableAmount : undefined
				})
			} else {
				const { data } = await SalesOrders.updateOrders(salesOrders.map(salesOrder => {
					let salesOrderItems = salesOrder.salesOrderItems.map(salesOrderItem => {
						const {
							id,
							quantity,
							packageQuantity,
							discount,
							discountAmount
						} = salesOrderItem
						return {
							salesOrderItemId: id,
							deliveredQuantity: quantity,
							deliveredPackageQuantity: packageQuantity,
							discount: +discount,
							discountAmount: +discountAmount
						}
					})
					if (salesOrder.status.toLowerCase() === orderStatuses.SALES_ORDER_COMPLETED_STATUS &&
					subStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS) {
						salesOrderItems = undefined
					}
					let receivedAmount = undefined
					if (subStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS) {
						if (isNotEmpty(salesOrder.receivedAmount) && !isNaN(+salesOrder.receivedAmount)) {
							receivedAmount = +salesOrder.receivedAmount
						} else {
							receivedAmount = +salesOrder.totalDueAmount - (+salesOrder.totalPaidAmount || 0)
						}
					}
					return {
						id: salesOrder.id,
						status: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
						subStatus,
						salesOrderItems,
						totalPaidAmount: receivedAmount
					}
				}))
				if (data.errors.length > 0) {
					throw new Error([...new Set(data.errors)].toString())
				}
			}
			notification.success({
				message: 'Successfully Delivered',
				description:
				<div className={styles.notificationContent}>
					{`You have delivered ${salesOrders.map(salesOrder => salesOrder.internalId).join(', ')} ${subStatus === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS ? 'without payment' : 'with payment'}.`}
				</div>,
				placement: 'bottomLeft'
			})
			onComplete(status, orderStatuses.SALES_ORDER_COMPLETED_STATUS)
		} catch (e) {
			notification.error({
				message: 'Unable to Deliver Order(s).',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingStatus(false)
		}
	}

	const changeStatusToReturned = async () => {
		try {
			setIsUpdatingStatus(true)
			const salesOrder = salesOrders[0]
			const salesOrderItems = salesOrder.salesOrderItems.map(salesOrderItem => {
				const discountValue = +salesOrderItem.discount > 0 ? +salesOrderItem.discount : 0
				const discountAmountValue = +salesOrderItem.discountAmount > 0 ? +salesOrderItem.discountAmount : 0
				const returnedSalesOrderItem = returnedSalesOrderItems.find(returnedSalesOrderItem => returnedSalesOrderItem.id === salesOrderItem.id)

				const item = {
					salesOrderItemId: salesOrderItem.id,
					flaggedQuantity: (returnedSalesOrderItem?.returnedQuantity || 0) + (returnedSalesOrderItem?.flaggedQuantity || 0),
					discount: discountValue,
					discountAmount: discountAmountValue
				}
				if (salesOrder.status.toLowerCase() === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
					item.deliveredQuantity = salesOrderItem.deliveredQuantity - (returnedSalesOrderItem?.returnedQuantity || 0)
				} else {
					item.quantity = salesOrderItem.quantity - (returnedSalesOrderItem?.returnedQuantity || 0)
				}
				return item
			})

			const modifiedReturnedSalesOrderItems = returnedSalesOrderItems
				.filter(returnedSalesOrderItem => returnedSalesOrderItem.returnedQuantity > 0)
				.map(returnedSalesOrderItem => ({
					...returnedSalesOrderItem,
					quantity: returnedSalesOrderItem.returnedQuantity,
					deliveredQuantity: 0,
					flaggedQuantity: 0
				}))
			if (modifiedReturnedSalesOrderItems.length) {
				await SalesOrders.update(salesOrder.id, {
					salesOrderItems: salesOrderItems,
					createReturnSalesOrder: true,
					returnedSalesOrderItems: modifiedReturnedSalesOrderItems,
					flagReason
				})
			} else {
				await changeStatus()
			}
			notification.success({
				message: 'Successfully Returned',
				description:
				<div className={styles.notificationContent}>
					{`You have returned ${salesOrders.map(salesOrder => salesOrder.internalId).join(', ')}.`}
				</div>,
				placement: 'bottomLeft'
			})
			onComplete(status, orderStatuses.SALES_ORDER_RETURNED_STATUS)
		} catch (e) {
			notification.error({
				message: 'Unable to Return Order(s).',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingStatus(false)
		}
	}

	const changeStatusToHold = async () => {
		try {
			setIsUpdatingStatus(true)
			if (salesOrders.length === 1) {
				const salesOrder = salesOrders[0]
				await SalesOrders.update(salesOrder.id, {
					status: orderStatuses.SALES_ORDER_ON_HOLD_STATUS,
					followUpDate,
					onHoldReason: onHoldReason === onHoldReasons.OTHER ? onHoldOtherReason : onHoldReason
				})
			} else {
				const { data } = await SalesOrders.updateOrders(salesOrders.map(salesOrder => {
					return {
						id: salesOrder.id,
						status: orderStatuses.SALES_ORDER_ON_HOLD_STATUS,
						followUpDate,
						onHoldReason: onHoldReason === onHoldReasons.OTHER ? onHoldOtherReason : onHoldReason
					}
				}))
				if (data.errors.length > 0) {
					throw new Error([...new Set(data.errors)].toString())
				}
			}
			notification.success({
				message: 'Successfully set to On Hold',
				description:
				<div className={styles.notificationContent}>
					{`You have returned ${salesOrders.map(salesOrder => salesOrder.internalId).join(', ')}.`}
				</div>,
				placement: 'bottomLeft'
			})
			onComplete(status, orderStatuses.SALES_ORDER_ON_HOLD_STATUS)
		} catch (e) {
			notification.error({
				message: 'Unable to set Order(s) to On Hold.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingStatus(false)
		}
	}

	const changeStatusToDeliveredWithPaymentCollected = async () => {
		try {
			setIsUpdatingStatus(true)
			const salesOrder = salesOrders[0]
			let createReturnSalesOrder = false
			const salesOrderItems = deliveredSalesOrderItems.map(salesOrderItem => {
				const {
					id,
					deliveredQuantity,
					updatedDeliveredQuantity,
					discount,
					discountAmount
				} = salesOrderItem
				if (!createReturnSalesOrder && updatedDeliveredQuantity < deliveredQuantity) {
					createReturnSalesOrder = true
				}

				return {
					salesOrderItemId: id,
					deliveredQuantity: updatedDeliveredQuantity,
					flaggedQuantity: updatedDeliveredQuantity < deliveredQuantity ? deliveredQuantity - updatedDeliveredQuantity : 0,
					discount: +discount > 0 ? +discount : 0,
					discountAmount: +discountAmount > 0 ? +discountAmount : 0
				}
			})
			const dueAmount = totalReceivableAmount - (isNaN(+salesOrder.totalPaidAmount) ? 0 : +salesOrder.totalPaidAmount)
			await SalesOrders.update(salesOrder.id, {
				status: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
				subStatus: orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS,
				salesOrderItems,
				createReturnSalesOrder,
				returnedSalesOrderItems: returnedDeliveredSalesOrderItems
					.filter(returnedSalesOrderItem => returnedSalesOrderItem.updatedReturnedQuantity > 0)
					.map(returnedSalesOrderItem => ({
						...returnedSalesOrderItem,
						quantity: returnedSalesOrderItem.updatedReturnedQuantity,
						deliveredQuantity: 0,
						flaggedQuantity: 0
					})),
				flagReason: createReturnSalesOrder ? flagReason : undefined,
				totalPaidAmount: isNotEmpty(receivableAmount) ? receivableAmount : dueAmount
			})
			onComplete(status, orderStatuses.SALES_ORDER_COMPLETED_STATUS)
			notification.success({
				message: 'Successfully Delivered',
				description:
				<div className={styles.notificationContent}>
					{`You have delivered ${salesOrder.internalId} with payment.`}
				</div>,
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Deliver Order.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingStatus(false)
		}
	}

	const createDamagedSalesOrders = async () => {
		try {
			setIsCreatingOrUpdatingSalesOrder(true)
			const ordersToUpdate = damagedOrders.map(({ salesOrderId }) => {
				const salesOrderItems = salesOrders.find(({ id }) => id === salesOrderId).salesOrderItems
					.map(({ id: salesOrderItemId, product : { id }, quantity }) => {
						const modifiedProduct = damagedOrders.find((order) => order.salesOrderId === salesOrderId).products
							.find((product) => product.productId === id)
						return {
							productId: id,
							salesOrderItemId,
							quantity: modifiedProduct ? Number(quantity) - Number(modifiedProduct.quantity) : undefined,
							flaggedQuantity: modifiedProduct ? Number(modifiedProduct.quantity) : undefined
						}
					}).filter(({ quantity }) => quantity)
				const order = {
					id: salesOrderId,
					salesOrderItems,
					createDamagedSalesOrder: true
				}

				return order
			})
			const { data } = await SalesOrders.updateOrders(ordersToUpdate)
			if (data.errors.length > 0) {
				throw new Error([...new Set(data.errors)].toString())
			}
			notification.success({
				message: 'Damaged Orders Created',
				description: 'Damaged orders successfully created',
				placement: 'bottomLeft'
			})
			setDamagedOrders([])
			setDamagedOrdersMap([])
			onComplete(status, orderStatuses.SALES_ORDER_DAMAGED_STATUS)
		} catch (e) {
			notification.error({
				message: 'Unable to Create Damaged Orders',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCreatingOrUpdatingSalesOrder(false)
		}
	}

	const onChange = (status, selectRef) => {
		setSelectedStatus(status)
		selectRef.current.blur()
	}

	const getOption = (label, optionsArr = options) => optionsArr.find((option) => option.label === label)

	const returnOrder = () => {
		if (salesOrders.length) {
			const salesOrder = salesOrders[0]
			const salesOrderItems = salesOrder.salesOrderItems
			const returnedSalesOrderItems = JSON.parse(JSON.stringify(salesOrderItems))
			returnedSalesOrderItems.forEach(salesOrderItem => {
				if (!salesOrderItem.returnedQuantity) {
					salesOrderItem.returnedQuantity = 0
				}
				let isFlatDiscount = true
				if (salesOrderItem.discount > 0) {
					isFlatDiscount = false
				}
				salesOrderItem.isFlatDiscount = isFlatDiscount
			})
			setReturnedSalesOrderItems(returnedSalesOrderItems)
			if (status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS) {
				return changeStatus()
			}
			setCurrentStep(modalViews.RETURNED)
		} else {
			setReturnedSalesOrderItems([])
		}
	}

	const deliverOrder = (modalView = modalViews.DELIVERED_PAYMENT_DUE) => {
		if (salesOrders.length) {
			const salesOrder = salesOrders[0]
			const salesOrderItems = salesOrder.salesOrderItems
			const deliveredSalesOrderItems = JSON.parse(JSON.stringify(salesOrderItems))
			deliveredSalesOrderItems.forEach(salesOrderItem => {
				if (isChangingStatusFromPaymentDueToPaymentCollected) {
					salesOrderItem.updatedDeliveredQuantity = salesOrderItem.deliveredQuantity
				} else {
					salesOrderItem.updatedDeliveredQuantity = salesOrderItem.quantity
				}
				let isFlatDiscount = true
				if (salesOrderItem.discount > 0) {
					isFlatDiscount = false
				}
				salesOrderItem.isFlatDiscount = isFlatDiscount
			})
			setDeliveredSalesOrderItems(deliveredSalesOrderItems)
			setReceivableAmount()
			setCurrentStep(modalView)
		} else {
			setDeliveredSalesOrderItems([])
		}
	}

	const getDeliveryCharge = async (locationId, deliveryPartnerId, pickUpLocationId) => {
		if (!locationId || !deliveryPartnerId) {
			return 0
		}
		const deliveyPartnerCharge = await determineDeliveryCharge(deliveryPartnerId, pickUpLocationId, locationId)
		return deliveyPartnerCharge?.deliveryCharge
	}

	const getInactiveProductsTableData = () => inactiveProducts.map(({ product: { name, id } }) => ({
		itemName: {
			name,
			id
		}
	}))

	const getDamagedOrdersOptions = (salesOrderId, salesOrderItems) => {
		const allOptions = salesOrderItems.map(({ productId, product: { name } }) => ({
			label: name,
			value: productId
		}))
		const selectedOrders = damagedOrders.find((order) => order.salesOrderId === salesOrderId)?.products
		if (!selectedOrders?.length) {
			return allOptions
		}

		return allOptions.filter(({ value }) => !selectedOrders.some(({ productId }) => productId === value))
	}

	const getUpdatableDamagedOrdersOptions = (salesOrderItems, selectedOrders, currentProductId) => {
		const allOptions = salesOrderItems.map(({ productId, product: { name } }) => ({
			label: name,
			value: productId
		}))
		const options = allOptions.filter(({ value }) => !selectedOrders.some(({ productId }) =>
			productId === value && productId !== currentProductId))
		return options
	}

	const updateDamagedOrderMapProperty = (orderId, propertyToUpdate, value) => {
		const updatedDamagedOrdersMap = { ...damagedOrdersMap }
		const currentOrderMap = updatedDamagedOrdersMap[orderId]

		if (!currentOrderMap) {
			return setDamagedOrdersMap({
				...updatedDamagedOrdersMap,
				[orderId]: { [propertyToUpdate]: value }
			})
		}
		currentOrderMap[propertyToUpdate] = value
		setDamagedOrdersMap(updatedDamagedOrdersMap)
	}

	const onReceivedAmountChanged = (id, value) => {
		setSalesOrders(prevSalesOrders => {
			return prevSalesOrders.map(order => {
				if (order.id === id) {
					return {
						...order,
						receivedAmount: value
					}
				} else {
					return order
				}
			})
		})
	}

	const isAddToDamagedOrdersButtonDisabled = (salesOrderId) => {
		const damagedOrderMap = damagedOrdersMap[salesOrderId]
		if (!damagedOrderMap || !damagedOrderMap?.productId || !damagedOrderMap?.quantity) {
			return true
		}
		const maxQuantity = salesOrders.find(({ id }) => id === salesOrderId).salesOrderItems
			.find(({ product: { id } }) => id === damagedOrderMap.productId).quantity
		return damagedOrderMap.quantity > maxQuantity
	}

	const addToDamagedProducts = (salesOrderId) => {
		const { id, salesOrderItems } = salesOrders.find(({ id }) => id === salesOrderId)
		const updatedDamagedOrders = [...damagedOrders]
		const updatedDamagedOrdersMap = { ...damagedOrdersMap }
		const damagedOrder = updatedDamagedOrders.find((order) => order.salesOrderId === salesOrderId)
		const damagedOrderMap = updatedDamagedOrdersMap[salesOrderId]
		const { price } = salesOrderItems.find(({ product : { id } }) => id === damagedOrderMap.productId)
		const newProduct = {
			productId: damagedOrderMap.productId,
			price: +price,
			quantity: damagedOrderMap.quantity.toFixed(2)
		}

		if (damagedOrder) {
			damagedOrder.products.push(newProduct)
			setDamagedOrders(updatedDamagedOrders)
		} else {
			const newDamagedOrder = { salesOrderId: id, products: [newProduct] }
			updatedDamagedOrders.push(newDamagedOrder)
		}

		delete damagedOrderMap.productId
		delete damagedOrderMap.quantity
		setDamagedOrders(updatedDamagedOrders)
		setDamagedOrdersMap(updatedDamagedOrdersMap)
	}

	const removeFromDamagedOrders = (salesOrderId, productId) => {
		let updatedDamagedOrders = [...damagedOrders]
		const damagedOrder = updatedDamagedOrders.find((order) => order.salesOrderId === salesOrderId)
		if (damagedOrder.products.length < 2) {
			updatedDamagedOrders = damagedOrders.filter((order) => order.salesOrderId !== salesOrderId)
		} else {
			damagedOrder.products = damagedOrder.products.filter((product) => product.productId !== productId)
		}
		setDamagedOrders(updatedDamagedOrders)
	}

	const updateDamagedOrderProperty = (salesOrderId, productId, propertyToUpdate, value) => {
		const updatedDamagedOrders = [...damagedOrders]
		const damagedOrder = updatedDamagedOrders.find((order) => order.salesOrderId === salesOrderId)
		const productToUpdate = damagedOrder.products.find((product) => product.productId === productId)
		productToUpdate[propertyToUpdate] = value.toFixed(2)
		setDamagedOrders(updatedDamagedOrders)
	}

	const getMaxDamageQuantity = (salesOrderId, productId) =>
		salesOrders.find(({ id }) => id === salesOrderId).salesOrderItems
			.find((product) => product.productId === productId)?.quantity?.toFixed(2)

	const isChangeStatusButtonDisabled = () => {
		if (!selectedStatus || isLoading) {
			return true
		}
		if (selectedStatus.status === orderStatuses.SALES_ORDER_CANCELLED_STATUS && !canceledReason) {
			return true
		}
		if (selectedStatus.subStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS && !returnedReason) {
			return true
		}
		if (isOtherReasonSelected && flagReason.trim().length === 0) {
			return true
		}
		if (salesOrders.length === 0) {
			return true
		}
		if (selectedStatus?.status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS && salesOrderIds.length > 500) {
			return true
		}
		return false
	}

	const generateRelevantPermissionKey = (status) => {
		switch (status) {
			case orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS:
				return 'CompletedPaymentDue'
			case orderStatuses.SALES_ORDER_COMPLETED_STATUS:
			case orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS:
				return 'CompletedPaymentCollected'
			case orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS:
				return 'InTransit'
			case orderStatuses.SALES_ORDER_FLAGGED_STATUS:
			case orderStatuses.SALES_ORDER_RETURNED_STATUS:
				return 'FlaggedReturn'
			case orderStatuses.SALES_ORDER_DAMAGED_STATUS:
				return 'FlaggedDamaged'
			case orderStatuses.SALES_ORDER_ON_HOLD_STATUS:
				return 'OnHold'
			default:
				return capitalizeWord(status)
		}
	}

	const validatePermission = (status, subStatus, optionStatus) => {
		const statusFrom = subStatus ? generateRelevantPermissionKey(subStatus) : generateRelevantPermissionKey(status)
		const statusTo = generateRelevantPermissionKey(optionStatus)
		let key = `editSalesOrdersStatus${statusFrom}To${statusTo}`
		if (!subStatus && optionStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
			return permissions[key] || permissions[`editSalesOrdersStatus${statusFrom}To${generateRelevantPermissionKey(orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS)}`] || permissions[`editSalesOrdersStatus${statusFrom}To${generateRelevantPermissionKey(orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS)}`]
		} else if (subStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS) {
			if (optionStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS) {
				return permissions[key] || permissions[`editSalesOrdersStatus${statusFrom}To${generateRelevantPermissionKey(orderStatuses.SALES_ORDER_DAMAGED_STATUS)}`]
			} else if (optionStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS) {
				// Flagged (Return) -> Flagged (Return) is allowed by default to allow the user to change the flag reason
				return true
			}
		} else if (status === orderStatuses.SALES_ORDER_CANCELLED_STATUS && optionStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS) {
			// Cancelled -> Cancelled is allowed by default to allow the user to change reason
			return true
		}
		return permissions[key]
	}

	const validateStatusUpdate = (status, subStatus, optionStatus) => {
		if (!validatePermission(status, subStatus, optionStatus)) {
			return false
		}
		switch (status) {
			case orderStatuses.SALES_ORDER_REQUESTED_STATUS:
				return optionStatus === orderStatuses.SALES_ORDER_APPROVED_STATUS ||
				optionStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS ||
				optionStatus === orderStatuses.SALES_ORDER_ON_HOLD_STATUS
			case orderStatuses.SALES_ORDER_APPROVED_STATUS:
				return optionStatus === orderStatuses.SALES_ORDER_REQUESTED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_PROCESSING_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_SHIPPED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_ON_HOLD_STATUS
			case orderStatuses.SALES_ORDER_PROCESSING_STATUS:
				return optionStatus === orderStatuses.SALES_ORDER_APPROVED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_SHIPPED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_ON_HOLD_STATUS
			case orderStatuses.SALES_ORDER_SHIPPED_STATUS:
				return optionStatus === orderStatuses.SALES_ORDER_PROCESSING_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_ON_HOLD_STATUS
			case orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS:
				return optionStatus === orderStatuses.SALES_ORDER_APPROVED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_PROCESSING_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_SHIPPED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS
			case orderStatuses.SALES_ORDER_COMPLETED_STATUS:
				if (subStatus === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS) {
					return optionStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS ||
						optionStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS
				} else if (subStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS) {
					return optionStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS
				} else {
					return optionStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS || optionStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS
				}
			case orderStatuses.SALES_ORDER_CANCELLED_STATUS:
				return optionStatus === orderStatuses.SALES_ORDER_REQUESTED_STATUS ||
					optionStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS // To allow the user to change the flag reason by keeping the same status
			case orderStatuses.SALES_ORDER_FLAGGED_STATUS:
				if (subStatus === orderStatuses.SALES_ORDER_DAMAGED_STATUS) {
					return false
				} else {
					return optionStatus === orderStatuses.SALES_ORDER_APPROVED_STATUS ||
						optionStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS ||
						optionStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS
				}
			case orderStatuses.SALES_ORDER_ON_HOLD_STATUS:
				return optionStatus === orderStatuses.SALES_ORDER_APPROVED_STATUS ||
				optionStatus === orderStatuses.SALES_ORDER_REQUESTED_STATUS ||
				optionStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS
			default:
				return false
		}
	}

	const validateSubStatusUpdate = (status, subStatus, optionSubStatus) => {
		if (!validatePermission(status, subStatus, optionSubStatus)) {
			return false
		}
		switch (status) {
			case orderStatuses.SALES_ORDER_FLAGGED_STATUS:
				return optionSubStatus === orderStatuses.SALES_ORDER_DAMAGED_STATUS
			case orderStatuses.SALES_ORDER_APPROVED_STATUS:
			case orderStatuses.SALES_ORDER_PROCESSING_STATUS:
				return optionSubStatus === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS ||
					optionSubStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS
			case orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS:
			case orderStatuses.SALES_ORDER_COMPLETED_STATUS:
				if (subStatus === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS) {
					return optionSubStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS ||
						optionSubStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS
				} else {
					return optionSubStatus === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS ||
						optionSubStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS ||
						optionSubStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS
				}
			default:
				switch (subStatus) {
					case orderStatuses.SALES_ORDER_RETURNED_STATUS:
						return optionSubStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS ||
							optionSubStatus === orderStatuses.SALES_ORDER_DAMAGED_STATUS
					case orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS:
						return optionSubStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS
					case orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS:
						return optionSubStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS ||
							optionSubStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS
					default:
						return false
				}
		}
	}

	const onChangeStatus = () => {
		if (selectedStatus.subStatus === orderStatuses.SALES_ORDER_DAMAGED_STATUS) {
			return setCurrentStep(modalViews.DAMAGED)
		}
		if (
			selectedStatus.status === orderStatuses.SALES_ORDER_APPROVED_STATUS &&
			status === orderStatuses.SALES_ORDER_REQUESTED_STATUS
		) {
			return setCurrentStep(modalViews.APPROVED)
		}
		if (
			allowedStatusesForDeliveryPartnerSelection.includes(selectedStatus.status) &&
			salesOrders.some(({ preferredDeliveryPartner, deliveryPartner, deliveryArea }) => {
				if (!preferredDeliveryPartner) {
					return true
				} else if (deliveryPartner && deliveryPartner.type !== PartnerType.CUSTOM) {
					if (deliveryPartner.type !== PartnerType.STEADFAST && !deliveryArea) {
						return true
					}
					return false
				} else {
					return false
				}
			})
		) {
			return setCurrentStep(modalViews.PROCESSING)
		}
		if (selectedStatus.status === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
			if (isChangingStatusFromPaymentDueToPaymentCollected && salesOrders.length === 1) {
				return deliverOrder(modalViews.DELIVERED_PAYMENT_COLLECTED)
			}
			if (selectedStatus.subStatus === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS) {
				if (salesOrderIds.length > 1) {
					return changeStatusToDelivered(orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS)
				} else {
					return deliverOrder(modalViews.DELIVERED_PAYMENT_DUE)
				}
			} else if (selectedStatus.subStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS) {
				if (salesOrders.length > 1) {
					return setCurrentStep(modalViews.DELIVERED)
				} else {
					return deliverOrder(modalViews.DELIVERED_PAYMENT_COLLECTED)
				}
			}
		}
		if (selectedStatus.status === orderStatuses.SALES_ORDER_FLAGGED_STATUS && selectedStatus.subStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS && salesOrderIds.length <= 1) {
			return returnOrder()
		}
		if (selectedStatus.status === orderStatuses.SALES_ORDER_ON_HOLD_STATUS) {
			return setCurrentStep(modalViews.ON_HOLD)
		}

		getSalesOrders()
		const disabledPartnerOrders = salesOrders.filter(order => order?.deliveryPartner?.disabled)
		if (disabledPartnerOrders.length < 1) {
			changeStatus()
		} else {
			setIsInactiveModalVisible(true)
		}
		setDisabledPartnerOrders(disabledPartnerOrders)
	}

	const applyDiscount = (returnedSalesOrderItem, deliveredSalesOrderItem) => {
		const { discount, discountAmount, isFlatDiscount } = deliveredSalesOrderItem
		if (discountAmount > 0 && isFlatDiscount) {
			const totalAmount = deliveredSalesOrderItem.quantity * deliveredSalesOrderItem.price
			const discountPercentage = (discountAmount / totalAmount) * 100
			returnedSalesOrderItem.discountAmount = 0
			returnedSalesOrderItem.discount = discountPercentage
			returnedSalesOrderItem.isFlatDiscount = false
			deliveredSalesOrderItem.discountAmount = 0
			deliveredSalesOrderItem.discount = discountPercentage
			deliveredSalesOrderItem.isFlatDiscount = false
		} else if (discount > 0) {
			returnedSalesOrderItem.discount = discount
		}
	}

	const onUpdateDeliveredQuantity = (value, index) => {
		const newDeliveredSalesOrderItems = [...deliveredSalesOrderItems]
		newDeliveredSalesOrderItems[index].updatedDeliveredQuantity = value || 0
		setDeliveredSalesOrderItems(newDeliveredSalesOrderItems)

		const deliveredSalesOrderItem = newDeliveredSalesOrderItems[index]
		const returnQuantity = deliveredSalesOrderItem.quantity - deliveredSalesOrderItem.updatedDeliveredQuantity - (deliveredSalesOrderItem.flaggedQuantity || 0)
		const newReturnedSalesOrderItems = [...returnedDeliveredSalesOrderItems]
		const returnedQuantityIndex = newReturnedSalesOrderItems.findIndex(({ productId }) => productId === deliveredSalesOrderItem.productId)
		if (returnedQuantityIndex > -1) {
			if (returnQuantity > 0) {
				newReturnedSalesOrderItems[returnedQuantityIndex].updatedReturnedQuantity = returnQuantity
				applyDiscount(newReturnedSalesOrderItems[returnedQuantityIndex], deliveredSalesOrderItem)
			} else {
				newReturnedSalesOrderItems.splice(returnedQuantityIndex, 1)
			}
		} else {
			if (returnQuantity > 0) {
				const returnedSalesOrderItem = JSON.parse(JSON.stringify(deliveredSalesOrderItem))
				returnedSalesOrderItem.updatedReturnedQuantity = returnQuantity
				applyDiscount(returnedSalesOrderItem, deliveredSalesOrderItem)
				newReturnedSalesOrderItems.push(returnedSalesOrderItem)
			}
		}

		setReturnedDeliveredSalesOrderItems(newReturnedSalesOrderItems)
	}

	const onUpdateReturnedQuantity = (value, index) => {
		const newReturnedDeliveredSalesOrderItems = [...returnedDeliveredSalesOrderItems]
		const returnedQuantity = value || 0
		newReturnedDeliveredSalesOrderItems[index].updatedReturnedQuantity = returnedQuantity
		const returnedSalesOrderItemProductId = newReturnedDeliveredSalesOrderItems[index].productId
		if (returnedQuantity === 0) {
			newReturnedDeliveredSalesOrderItems.splice(index, 1)
		}
		setReturnedDeliveredSalesOrderItems(newReturnedDeliveredSalesOrderItems)

		const newDeliveredSalesOrderItems = [...deliveredSalesOrderItems]
		const deliveredQuantityIndex = newDeliveredSalesOrderItems.findIndex(({ productId }) => productId === returnedSalesOrderItemProductId)
		if (deliveredQuantityIndex > -1) {
			const deliveredQuantity = newDeliveredSalesOrderItems[deliveredQuantityIndex].quantity - returnedQuantity - (newDeliveredSalesOrderItems[deliveredQuantityIndex].flaggedQuantity || 0)
			newDeliveredSalesOrderItems[deliveredQuantityIndex].updatedDeliveredQuantity = deliveredQuantity
		}

		setDeliveredSalesOrderItems(newDeliveredSalesOrderItems)
	}

	const onAddReturnedQuantity = (value, index) => {
		const newReturnedSalesOrderItems = [...returnedSalesOrderItems]
		newReturnedSalesOrderItems[index].returnedQuantity = value || 0
		const deliveredSalesOrderItem = salesOrders[0]?.salesOrderItems.find(({ productId }) => productId === newReturnedSalesOrderItems[index].productId)
		if (deliveredSalesOrderItem) {
			const { discount, discountAmount, deliveredQuantity } = deliveredSalesOrderItem
			if (discountAmount > 0) {
				const totalAmount = deliveredQuantity * deliveredSalesOrderItem.price
				const discountPercentage = (discountAmount / totalAmount) * 100
				newReturnedSalesOrderItems[index].discountAmount = 0
				newReturnedSalesOrderItems[index].discount = discountPercentage
				newReturnedSalesOrderItems[index].isFlatDiscount = false
				deliveredSalesOrderItem.discountAmount = 0
				deliveredSalesOrderItem.discount = discountPercentage
				deliveredSalesOrderItem.isFlatDiscount = false
			} else if (discount > 0) {
				newReturnedSalesOrderItems[index].discount = discount
			}
		}
		setReturnedSalesOrderItems(newReturnedSalesOrderItems)
	}

	const renderEmptyDeliveryPartnerOrdersTable = () => {
		const emptyProcessingOrders = processingOrders.filter(({ preferredDeliveryPartner }) => !preferredDeliveryPartner)
		return (
			<>
				<CustomTable
					columns={processingTableColumns.slice(0, 3)}
					dataSource={emptyProcessingOrders}
					pagination={false}
					size='small'
					scroll={{ y: '300px' }}
				/>
			</>
		)
	}

	const renderInactiveProductsTable = () => {
		return (
			<>
				<p className={styles.inactiveProductsTableTitle}>Inactive Products</p>
				<CustomTable
					columns={inactiveProductsTableColumns}
					dataSource={getInactiveProductsTableData()}
					pagination={false}
					size='small'
					scroll={{ y: '300px' }}
				/>
			</>
		)
	}

	const renderSelectedDamagedRows = (salesOrderId, salesOrderItems) => {
		const selectedProducts = damagedOrders.find((order) => order.salesOrderId === salesOrderId)?.products
		if (!selectedProducts?.length) {
			return
		}

		return (
			selectedProducts.map(({ productId, quantity }) => {
				return (
					<div key={productId} className={styles.damageSelectorRow}>
						<div className={styles.damageSelector}>
							<CustomSelectSecondary
								title='Product'
								options={getUpdatableDamagedOrdersOptions(salesOrderItems, selectedProducts, productId)}
								value={productId}
								onChange={(value) => updateDamagedOrderProperty(salesOrderId, productId, 'productId', value)}
								popupContainer={() => document.querySelector('.damaged-products')}
							/>
							<NumberInput
								title='Damage Quantity'
								min={0}
								max={getMaxDamageQuantity(salesOrderId, productId)}
								value={quantity}
								onChange={(value) => updateDamagedOrderProperty(salesOrderId, productId, 'quantity', value)}
							/>
						</div>
						<Button
							className={`${styles.quantityButton} ${styles.quantityButtonRemove}`}
							type='primary'
							onClick={() => removeFromDamagedOrders(salesOrderId, productId)}
						>
							<img src='/img/minus-red.svg' alt='Minus icon' />
						</Button>
					</div>
				)
			})
		)
	}

	const renderDamageSelectorRow = (salesOrderId, salesOrderItems) => {
		const options = getDamagedOrdersOptions(salesOrderId, salesOrderItems)

		return (
			options.length ?
				<div className={styles.damageSelectorRow}>
					<div className={styles.damageSelector}>
						<CustomSelectSecondary
							title='Product'
							options={options}
							value={damagedOrdersMap[salesOrderId]?.productId}
							onChange={(value) => updateDamagedOrderMapProperty(salesOrderId, 'productId', value)}
							popupContainer={() => document.querySelector('.damaged-products')}
						/>
						<NumberInput
							title='Damage Quantity'
							min={0}
							max={getMaxDamageQuantity(salesOrderId, damagedOrdersMap[salesOrderId]?.productId)}
							value={damagedOrdersMap[salesOrderId]?.quantity?.toFixed(2)}
							onChange={(value) => updateDamagedOrderMapProperty(salesOrderId, 'quantity', value)}
						/>
					</div>
					<Button
						className={styles.quantityButton}
						type='primary'
						disabled={isAddToDamagedOrdersButtonDisabled(salesOrderId)}
						onClick={() => addToDamagedProducts(salesOrderId)}
					>
						<img src='/img/plus.svg' alt='Plus icon' />
					</Button>
				</div> : null
		)
	}

	const renderOptions = (selectRef) => {
		return (
			<div className={styles.options}>
				{
					(status === orderStatuses.SALES_ORDER_FLAGGED_STATUS ? flaggedStatusOptions : options)
						.map(({ label, dropdownItems, background, status: optionStatus }) => {
							const disabled = !validateStatusUpdate(status, subStatus, optionStatus)
							return (
								!disabled &&
								<div
									key={label}
									className={styles.option}
									onClick={(e) => {
										if (dropdownItems) {
											e.stopPropagation()
										} else {
											onChange(getOption(label, options), selectRef)
										}
									}}
								>
									<div className={styles.optionTitleContainer}>
										<div className={styles.dot} style={{ background }} />
										<p>
											{label}
										</p>
									</div>
									{
										dropdownItems &&
									<>
										<img className={styles.chevron} src='/img/chevron-right.svg' alt='Chevron Right' />
										<div className={styles.dropdown}>
											<div className={styles.dropdownContent}>
												{
													dropdownItems.map(({ label, background, subStatus: optionSubStatus }) => {
														const disabled = !validateSubStatusUpdate(status, subStatus, optionSubStatus)
														return (
															!disabled &&
															<div
																key={label}
																className={styles.option}
																onClick={(e) => {
																	e.stopPropagation()
																	onChange(getOption(label, dropdownItems), selectRef)
																}}
															>
																<div className={styles.optionTitleContainer}>
																	<div className={styles.dot} style={{ background }} />
																	<p>
																		{label}
																	</p>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
									</>
									}
								</div>
							)
						})
				}
			</div>
		)
	}

	const renderChangeStatusView = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>Change Status</p>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div style={{ padding: '0 24px 24px 24px' }}>
					<div style={{ marginBottom: 32 }}>
						<CustomSelectSecondary
							title='Select Status'
							disabled={disableStatusSelect}
							value={selectedStatus?.label}
							dropdownRender={renderOptions}
							dropdownClassName={styles.subDropdown}
						/>
					</div>
					{
						selectedStatus?.status === orderStatuses.SALES_ORDER_FLAGGED_STATUS &&
						selectedStatus?.subStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS ?
							<CustomSelectSecondary
								title='Select Return Reason'
								options={returnReasons}
								value={returnedReason}
								onChange={setReturnedReason}
							/> :
							selectedStatus?.status === orderStatuses.SALES_ORDER_CANCELLED_STATUS ?
								<CustomSelectSecondary
									title='Select Canceled Reason'
									options={cancelReasons}
									value={canceledReason}
									onChange={setCanceledReason}
								/> : null
					}
					{
						isOtherReasonSelected &&
						<div style={{ marginTop: 32 }}>
							<TextArea
								title='Describe The Reason'
								onChange={({ target: { value } }) => setFlagReason(value)}
							/>
						</div>
					}
					{
						selectedStatus?.status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS && salesOrderIds.length > 500 ? <Alert message={'Unable to change more than 500 orders at a time'} type='error' showIcon /> : null
					}
					<div className={styles.actionButtons}>
						<Button
							key={1}
							className={styles.modalButton}
							onClick={onCancel}
						>
							Cancel
						</Button>
						<Button
							key={2}
							className={styles.modalButton}
							type='primary'
							loading={isUpdatingStatus || isLoading}
							disabled={isChangeStatusButtonDisabled()}
							onClick={onChangeStatus}
						>
							Confirm
						</Button>
					</div>
				</div>
			</>
		)
	}

	const renderDamagedView = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>Damaged Products</p>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div className={`${styles.modalContent} damaged-products`}>
					<div style={{ padding: '0 24px 32px 24px' }}>
						<div className={styles.damageGrid}>
							{
								salesOrders.map((order, i) => {
									const { id, salesOrderItems } = order
									return (
										<div key={i} className={styles.damageRow}>
											<p className={styles.invoiceNoSecondary}>
												Invoice No: <span>{order.internalId}</span>
											</p>
											<div className={styles.damageRowGrid}>
												{renderSelectedDamagedRows(id, salesOrderItems)}
												{renderDamageSelectorRow(id, salesOrderItems)}
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
					<div className={styles.actionButtonsSticky}>
						<Button
							key={6}
							className={styles.modalButton}
							onClick={() => {
								setDamagedOrders([])
								setDamagedOrdersMap([])
								setCurrentStep(modalViews.CHANGE_STATUS)
							}}
						>
							Cancel
						</Button>
						<Button
							key={7}
							className={styles.modalButton}
							type='primary'
							loading={isCreatingOrUpdatingSalesOrder}
							disabled={!damagedOrders.length}
							onClick={createDamagedSalesOrders}
						>
							Confirm
						</Button>
					</div>
				</div>
			</>
		)
	}

	const renderApprovedView = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>Approve Order</p>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div className={styles.modalContent}>
					<div style={{ padding: 24 }}>
						<p className={styles.approveDescription}>
							Are you sure want to approve this order? The inventory will also be updated once this is approved.
						</p>
						<div className={styles.tables}>
							{
								salesOrders.map((order, i) => {
									return (
										<div key={order.id}>
											<p className={styles.invoiceNo}>
												{i + 1}. &nbsp;&nbsp; Invoice No:&nbsp;
												<Link href={`${paths.SALES_ORDERS}/${order.id}`}
													className={styles.invoiceNo}
												>
													<a>{order.internalId}</a>
												</Link>
											</p>
											<CustomTable
												title={() => 'Inventory'}
												pagination={false}
												columns={filteredApproveTableColumns}
												dataSource={getApprovedTableData(order)}
												size='small'
											/>
										</div>
									)
								})
							}
						</div>
					</div>
					{
						<div className={styles.warnings}>
							{
								isSomeProductsUnderStocked &&
								<div className={styles.warning}>
									At least one of the products of selected orders is&nbsp;<span>understocked</span>&nbsp;in the inventory.
									<Popover
										overlayClassName='understocked-products-popover'
										placement='rightTop'
										arrowPointAtCenter
										trigger={['hover', 'click']}
										content={renderUnderStockedTable(salesOrders)}
									>
										<ExclamationCircleOutlined
											style={{ fontSize: 24, marginLeft: 10, cursor: 'pointer' }}
										/>
									</Popover>
								</div>
							}
							{
								inactiveProducts.length > 0 &&
								<div className={styles.warning}>
									At least one of the products of selected orders is&nbsp;<span>inactive</span>.
									<Popover
										overlayClassName='inactive-products-popover'
										placement='rightTop'
										arrowPointAtCenter
										trigger={['hover', 'click']}
										content={renderInactiveProductsTable()}
									>
										<ExclamationCircleOutlined
											style={{ fontSize: 24, marginLeft: 10, cursor: 'pointer' }}
										/>
									</Popover>
								</div>
							}
						</div>
					}
					<div className={styles.actionButtonsSticky}>
						<Button
							key={3}
							onClick={() => setCurrentStep(modalViews.CHANGE_STATUS)}
						>
							Cancel
						</Button>
						<Button
							key={5}
							type='primary'
							loading={isUpdatingStatus}
							onClick={() => changeStatus()}
						>
							Approve
						</Button>
					</div>
				</div>
			</>
		)
	}

	const renderSelectedItems = () => {
		return selectedSalesOrderIds.length > 0 &&
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					margin: '10px'
				}}
			>
				<Typography.Text>
					{`You have selected ${selectedSalesOrderIds.length} order(s).`}
				</Typography.Text>
				<span
					onClick={() => setSelectedSalesOrderIds([])}
					style={{ color: '#f5222d', fontWeight: 'bold', marginLeft: 6, cursor: 'pointer' }}
				>Clear all selections
				</span>
			</div>
	}

	const isProcessingChangeButtonDisabled = useMemo(() => {
		return processingOrders.some(({ preferredDeliveryPartner, deliveryPartner, deliveryArea }) => {
			switch (deliveryPartner?.type) {
				case PartnerType.REDX:
					return !preferredDeliveryPartner
						|| !deliveryArea?.divisionId
						|| !deliveryArea?.districtId
						|| !deliveryArea?.areaId
				case PartnerType.E_COURIER:
					return !preferredDeliveryPartner
						|| !deliveryArea?.cityId
						|| !deliveryArea?.areaId
				case PartnerType.PATHAO:
					return !preferredDeliveryPartner || !deliveryArea || !deliveryArea?.cityId || !deliveryArea?.zoneId
				case PartnerType.PIDEX:
					return !preferredDeliveryPartner
						|| (deliveryArea?.cityId && !deliveryArea?.zoneId)
				case PartnerType.TCS_COURIER:
				case PartnerType.RIDER_COURIER:
					return !preferredDeliveryPartner || !deliveryArea?.originCityId || !deliveryArea?.cityId
				case PartnerType.POST_EX:
					return !preferredDeliveryPartner || !deliveryArea?.pickupAddressId || !deliveryArea?.cityId
				case PartnerType.MP_COURIER:
				case PartnerType.LEOPARDS:
					return !preferredDeliveryPartner || !deliveryArea?.cityId
				case PartnerType.BLUE_EX:
					return !preferredDeliveryPartner
						|| !deliveryArea?.cityId
						|| !deliveryArea?.serviceTypeId
				case PartnerType.MOVEX:
					return !preferredDeliveryPartner || !deliveryArea?.areaId
				default:
					return !preferredDeliveryPartner
			}
		})
	}, [processingOrders])

	const renderProcessingView = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>Order Processing and Final Review before Sending to Courier</p>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div className={styles.modalContent}>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 24, padding: '0 24px' }}>
						<div style={{ display: 'flex', gap: 24 }}>
							<div style={{ width: 400 }}>
								<CustomSelectSecondary
									title='Filter by Pickup Address'
									options={
										pickUpLocations.map(({ label, address, id }) => ({
											label: label || address,
											value: id
										}))
									}
									value={selectedWarehouseFromProcessingView}
									onChange={(option) => {
										const pickUpLocation = pickUpLocations.find(location => location.id === option)
										if (pickUpLocation && pickUpLocation.deliveryPartnerWarehousePreferences?.length) {
											const { deliveryPartnerId } = pickUpLocation.deliveryPartnerWarehousePreferences[0]
											setSelectedDeliveryPartnerFromProcessingView(deliveryPartnerId)
										}
										setSelectedWarehouseDeliveryPartnerOptions(locationDeliveryPartnersMap[option]?.deliveryPartners ?? [])
										setSelectedWarehouseFromProcessingView(option)
									}}
									defaultValue={[]}
									allowClear={true}
									loading={isLoadingDeliveryPartners}
								/>
							</div>
							<div style={{ width: 400 }}>
								<CustomSelectSecondary
									title='Set Delivery Partner for Selected Orders'
									disabled={!selectedWarehouseFromProcessingView}
									optionLabelProp='label'
									options={selectedWarehouseDeliveryPartnerOptions.map(({ id, name, type }) => ({ value: id, label: name, type }))}
									value={selectedDeliveryPartnerFromProcessingView}
									onChange={setSelectedDeliveryPartnerFromProcessingView}
									optionRender={DeliveryPartnerOption}
									loading={isLoadingDeliveryPartners}
								/>
							</div>
						</div>
						<div style={{ marginBottom: 24 }}>
							<div>
								{renderSelectedItems()}
							</div>
							<CustomTable
								tableLayout='auto'
								scroll={{ y: 404 }}
								columns={processingTableColumns}
								dataSource={processingSalesOrders}
								pagination={false}
								size='small'
								loading={isLoading}
								rowKey='id'
								rowSelection={
									{
										preserveSelectedRowKeys: false,
										selectedRowKeys: selectedSalesOrderIds,
										getCheckboxProps: () => ({ disabled: !selectedWarehouseFromProcessingView }),
										onChange: (selectedRowKeys) => setSelectedSalesOrderIds(selectedRowKeys)
									}
								}
							/>
							{
								processingOrders.filter(({ preferredDeliveryPartner }) => !preferredDeliveryPartner).length > 0 &&
								<Alert
									style={{ borderRadius: 0, marginTop: 24 }}
									className={styles.alert}
									message={
										<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
											{`You have selected ${processingSalesOrders.length} order(s). At least 1 order is not assigned to any delivery partner.`}
											<Popover
												overlayClassName='unassigned-orders-popover'
												placement='rightTop'
												arrowPointAtCenter
												trigger={['hover', 'click']}
												content={renderEmptyDeliveryPartnerOrdersTable()}
											>
												<InfoCircleOutlined
													style={{ color: '#288ea5' }}
												/>
											</Popover>
										</div>
									}
									type='info'
								/>
							}
						</div>
					</div>
					<div className={styles.actionButtonsSticky}>
						<Button
							key={12}
							onClick={() => {
								const updatedSalesOrders = [...processingOrders]
								updatedSalesOrders.map((salesOrder) => {
									salesOrder.preferredDeliveryPartner = null
									salesOrder.deliveryCharge = 0
								})
								setProcessingOrders(updatedSalesOrders)
								setCurrentStep(modalViews.CHANGE_STATUS)
							}}
						>
							Cancel
						</Button>
						<Button
							key={13}
							type='primary'
							loading={isUpdatingStatus || isLoadingDeliveryPartners}
							disabled={isProcessingChangeButtonDisabled}
							onClick={changeStatusToProcessing}
						>
							Confirm
						</Button>
					</div>
				</div>
			</>
		)
	}

	const renderDeliveredView = () => {
		return (
			<div>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>Preview</p>
					<svg className={styles.closeIcon} onClick={() => {
						if (isChangingStatusFromPaymentDueToPaymentCollected) {
							onComplete(status, orderStatuses.SALES_ORDER_COMPLETED_STATUS)
						} else {
							onCancel()
						}
					}}
					>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div className={styles.modalContent}>
					<div style={{ padding:'0px 24px 24px 24px' }}>
						<div className={styles.tables}>
							<CustomTable
								pagination={false}
								columns={deliveredTableColumns}
								dataSource={salesOrders}
								size='small'
							/>
						</div>
					</div>
					<div className={styles.actionButtonsSticky}>
						<Button
							key={6}
							onClick={() => {
								if (isChangingStatusFromPaymentDueToPaymentCollected) {
									onComplete(status, orderStatuses.SALES_ORDER_COMPLETED_STATUS)
								} else {
									setCurrentStep(modalViews.CHANGE_STATUS)
								}
							}}
						>
							Cancel
						</Button>
						<Button
							key={7}
							onClick={() => setCurrentStep(previousStep)}
						>
							Previous
						</Button>
						<Button
							key={8}
							type='primary'
							loading={isUpdatingStatus || isLoading}
							onClick={() => {
								changeStatusToDelivered(orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS)
							}}
						>
							Confirm
						</Button>
					</div>
				</div>
			</div>
		)
	}

	const renderDeliveredOrderView = () => {
		const deliveredSalesOrderItemColumns = [
			{
				title: 'SKU',
				key: 'sku',
				render: (salesOrderItem) => {
					const { product } = salesOrderItem
					return (
						<div>{product.sku || 'No SKU'}</div>
					)
				}
			},
			{
				title: 'Product Name',
				key: 'name',
				render: (salesOrderItem) => {
					const { product } = salesOrderItem
					return (
						<div>{product.name}</div>
					)
				}
			},
			{
				title: 'Price',
				key: 'name',
				render: (salesOrderItem) => {
					const { price } = salesOrderItem
					return (
						<div>{getFormattedCurrency(price)}</div>
					)
				}
			},
			{
				title: isChangingStatusFromPaymentDueToPaymentCollected && salesOrders.length === 1 ? 'Previous Delivered Qty' : 'Requested Qty',
				key: 'requestedQty',
				render: (salesOrderItem) => {
					return (
						<div>
							{
								isChangingStatusFromPaymentDueToPaymentCollected ?
									salesOrderItem.deliveredQuantity :
									salesOrderItem.quantity
							}
						</div>
					)
				}
			},
			{
				title: isChangingStatusFromPaymentDueToPaymentCollected && salesOrders.length === 1 ? 'Updated Delivered Qty' : 'Delivered Qty',
				key: 'deliveredQty',
				render: (salesOrderItem, record, index) => {
					const maxQuantity = isChangingStatusFromPaymentDueToPaymentCollected ?
						salesOrderItem.deliveredQuantity :
						salesOrderItem.quantity

					return (
						<div>
							<NumberInput
								min={0}
								max={maxQuantity}
								step={1}
								value={salesOrderItem.updatedDeliveredQuantity}
								onChange={value => onUpdateDeliveredQuantity(value, index)}
							/>
						</div>
					)
				}
			},
			{
				title: 'Discount',
				key: 'discount',
				width: 220,
				render: (salesOrderItem, record, index) => {
					const { updatedDeliveredQuantity, isFlatDiscount, discount, discountAmount } = salesOrderItem
					const price = +salesOrderItem.price
					let totalPrice = price * updatedDeliveredQuantity
					let discountValue = 0
					if (discount > 0) {
						discountValue = discount
					} else if (discountAmount > 0) {
						discountValue = discountAmount
					}
					return (
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								className={styles.discountToggle}
								onClick={() => {
									const newDeliveredSalesOrderItems = [...deliveredSalesOrderItems]
									newDeliveredSalesOrderItems[index].isFlatDiscount = !isFlatDiscount
									newDeliveredSalesOrderItems[index].discount = 0
									newDeliveredSalesOrderItems[index].discountAmount = 0
									setDeliveredSalesOrderItems(newDeliveredSalesOrderItems)
								}}
							>
								{isFlatDiscount ? 'Flat' : 'Percentage'}
							</div>
							<NumberInput
								min={0}
								max={isFlatDiscount ? totalPrice : 100}
								defaultValue={discountValue}
								step={1}
								value={discountValue}
								onChange={value => {
									const newDeliveredSalesOrderItems = [...deliveredSalesOrderItems]
									if (isFlatDiscount) {
										newDeliveredSalesOrderItems[index].discount = 0
										newDeliveredSalesOrderItems[index].discountAmount = value || 0
									} else {
										newDeliveredSalesOrderItems[index].discount = value || 0
										newDeliveredSalesOrderItems[index].discountAmount = 0
									}
									setDeliveredSalesOrderItems(newDeliveredSalesOrderItems)
								}}
							/>
							{
								!isFlatDiscount && <div style={{ marginLeft: 4 }}>%</div>
							}
						</div>
					)
				}
			},
			{
				title: 'Total Price',
				key: 'totalPrice',
				width: 200,
				render: (salesOrderItem) => {
					const { updatedDeliveredQuantity, discount, discountAmount } = salesOrderItem
					const price = +salesOrderItem.price
					let totalPrice = price * updatedDeliveredQuantity
					let totalDiscount = 0
					if (discount > 0) {
						totalDiscount = calculateDiscount(totalPrice, discount)
					} else if (discountAmount > 0) {
						totalDiscount = discountAmount
					}
					totalPrice = totalPrice - totalDiscount
					return (
						<div>{getFormattedCurrency(totalPrice)}</div>
					)
				}
			}
		]

		const returnedDeliveredSalesOrderItemColumns = [
			{
				title: 'SKU',
				key: 'sku',
				render: (salesOrderItem) => {
					const { product } = salesOrderItem
					return (
						<div>{product.sku || 'No SKU'}</div>
					)
				}
			},
			{
				title: 'Product Name',
				key: 'name',
				render: (salesOrderItem) => {
					const { product } = salesOrderItem
					return (
						<div>{product.name}</div>
					)
				}
			},
			{
				title: 'Price',
				key: 'name',
				render: (salesOrderItem) => {
					const { price } = salesOrderItem
					return (
						<div>{getFormattedCurrency(price)}</div>
					)
				}
			},
			{
				title: 'Return Qty',
				key: 'returnQty',
				render: (salesOrderItem, record, index) => {
					return (
						<div>
							<NumberInput
								min={0}
								max={salesOrderItem.quantity}
								step={1}
								value={salesOrderItem.updatedReturnedQuantity}
								onChange={value => onUpdateReturnedQuantity(value, index)}
							/>
						</div>
					)
				}
			},
			{
				title: 'Discount',
				key: 'discount',
				width: 220,
				render: (salesOrderItem, record, index) => {
					const { updatedReturnedQuantity, isFlatDiscount, discount, discountAmount } = salesOrderItem
					const price = +salesOrderItem.price
					let totalPrice = price * updatedReturnedQuantity
					let discountValue = 0
					if (discount > 0) {
						discountValue = discount
					} else {
						discountValue = discountAmount
					}
					return (
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								className={styles.discountToggle}
								onClick={() => {
									const newReturnedDeliveredSalesOrderItems = [...returnedDeliveredSalesOrderItems]
									newReturnedDeliveredSalesOrderItems[index].isFlatDiscount = !isFlatDiscount
									newReturnedDeliveredSalesOrderItems[index].discount = 0
									newReturnedDeliveredSalesOrderItems[index].discountAmount = 0
									setReturnedDeliveredSalesOrderItems(newReturnedDeliveredSalesOrderItems)
								}}
							>
								{isFlatDiscount ? 'Flat' : 'Percentage'}
							</div>
							<NumberInput
								min={0}
								max={isFlatDiscount ? totalPrice : 100}
								defaultValue={discountValue}
								step={1}
								value={discountValue}
								onChange={value => {
									const newReturnedDeliveredSalesOrderItems = [...returnedDeliveredSalesOrderItems]
									if (isFlatDiscount) {
										newReturnedDeliveredSalesOrderItems[index].discount = 0
										newReturnedDeliveredSalesOrderItems[index].discountAmount = value || 0
									} else {
										newReturnedDeliveredSalesOrderItems[index].discount = value || 0
										newReturnedDeliveredSalesOrderItems[index].discountAmount = 0
									}
									setReturnedDeliveredSalesOrderItems(newReturnedDeliveredSalesOrderItems)
								}}
							/>
							{
								!isFlatDiscount && <div style={{ marginLeft: 4 }}>%</div>
							}
						</div>
					)
				}
			},
			{
				title: 'Total Price',
				key: 'totalPrice',
				width: 200,
				render: (salesOrderItem) => {
					const { updatedReturnedQuantity, discount, discountAmount } = salesOrderItem
					const price = +salesOrderItem.price
					let totalPrice = price * updatedReturnedQuantity
					let totalDiscount = 0
					if (discount > 0) {
						totalDiscount = calculateDiscount(totalPrice, discount)
					} else if (discountAmount > 0) {
						totalDiscount = discountAmount
					}
					totalPrice = totalPrice - totalDiscount
					return (
						<div>{getFormattedCurrency(totalPrice)}</div>
					)
				}
			}
		]

		const totalPaidAmount = isNaN(+salesOrders[0]?.totalPaidAmount) ? 0 : +salesOrders[0]?.totalPaidAmount
		const dueAmount = totalReceivableAmount - +salesOrders[0]?.totalPaidAmount

		return (
			<div>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle} style={{ marginRight: 0 }}>Deliver Order</p>
					<div className={styles.orderId}>{salesOrders[0]?.internalId}</div>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div className={styles.modalContent}>
					<div style={{ padding:'0px 24px 24px 24px' }}>
						<div className={styles.tables}>
							<CustomTable
								pagination={false}
								columns={deliveredSalesOrderItemColumns}
								dataSource={deliveredSalesOrderItems}
								size='small'
								summary={() => {
									return (
										<>
											<Summary.Row className={styles.summaryRow}>
												<Summary.Cell />
												<Summary.Cell />
												<Summary.Cell />
												<Summary.Cell />
												<Summary.Cell />
												<Summary.Cell>Sub Total</Summary.Cell>
												<Summary.Cell>
													<div style={{ textAlign: 'start' }}>
														{getFormattedCurrency(totalReceivableAmount)}
													</div>
												</Summary.Cell>
											</Summary.Row>
											{
												totalPaidAmount > 0 &&
												<Summary.Row className={styles.summaryRow}>
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell>Paid Amount</Summary.Cell>
													<Summary.Cell>
														<div style={{ textAlign: 'start' }}>
															{getFormattedCurrency(totalPaidAmount)}
														</div>
													</Summary.Cell>
												</Summary.Row>
											}
											<Summary.Row className={styles.summaryRow}>
												<Summary.Cell />
												<Summary.Cell />
												<Summary.Cell />
												<Summary.Cell />
												<Summary.Cell />
												<Summary.Cell>Due Amount</Summary.Cell>
												<Summary.Cell>
													<div style={{ textAlign: 'start' }}>
														{getFormattedCurrency(dueAmount)}
													</div>
												</Summary.Cell>
											</Summary.Row>
											{
												currentStep === modalViews.DELIVERED_PAYMENT_COLLECTED &&
												<Summary.Row className={styles.summaryRow}>
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell>Receivable Amount</Summary.Cell>
													<Summary.Cell>
														<div style={{ textAlign: 'start' }}>
															<NumberInput
																value={isNotEmpty(receivableAmount) ? receivableAmount : dueAmount}
																min={0}
																max={dueAmount}
																onChange={(value) => setReceivableAmount(value)}
															/>
														</div>
													</Summary.Cell>
												</Summary.Row>
											}
										</>
									)
								}}
							/>
						</div>
						{
							returnedDeliveredSalesOrderItems.length > 0 &&
							<div>
								<p className={styles.modalTitle} style={{ marginTop: 24, marginBottom: 12 }}>Returnable Quantity</p>
								<CustomTable
									pagination={false}
									columns={returnedDeliveredSalesOrderItemColumns}
									dataSource={returnedDeliveredSalesOrderItems}
									size='small'
									summary={() => {
										return (
											<>
												<Summary.Row className={styles.summaryRow}>
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell />
													<Summary.Cell>Return Amount</Summary.Cell>
													<Summary.Cell>
														<div style={{ textAlign: 'start' }}>
															{getFormattedCurrency(totalReturnedAmount)}
														</div>
													</Summary.Cell>
												</Summary.Row>
											</>
										)
									}}
								/>
								<Alert
									style={{ borderRadius: 0, marginTop: 24 }}
									className={styles.alert}
									message='Based on the returned product, the product discount and the total price will be adjusted accordingly.'
									type='info'
									showIcon
								/>
							</div>
						}
					</div>
					<div className={styles.actionButtonsSticky}>
						<Button
							key={6}
							onClick={() => setCurrentStep(modalViews.CHANGE_STATUS)}
						>
							Cancel
						</Button>
						<Button
							key={7}
							type='primary'
							loading={isUpdatingStatus}
							onClick={async () => {
								if (isChangingStatusFromPaymentDueToPaymentCollected && salesOrders.length === 1) {
									return changeStatusToDeliveredWithPaymentCollected()
								}
								if (selectedStatus.subStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS && salesOrders.length === 1) {
									return changeStatusToDeliveredWithPaymentCollected()
								}
								if (selectedStatus.subStatus === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS) {
									return await changeStatusToDelivered(selectedStatus.subStatus)
								}
								setCurrentStep(modalViews.DELIVERED)
							}}
						>
							{
								selectedStatus?.subStatus === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS && salesOrders.length === 1 ?
									'Confirm' : 'Delivered'
							}
						</Button>
					</div>
				</div>
			</div>
		)
	}

	const renderReturnedView = () => {
		const salesOrderItemColumns = [
			{
				title: 'SKU',
				key: 'sku',
				render: (salesOrderItem) => {
					const { product } = salesOrderItem
					return (
						<div>{product?.sku || 'No SKU'}</div>
					)
				}
			},
			{
				title: 'Product Name',
				key: 'name',
				render: (salesOrderItem) => {
					const { product } = salesOrderItem
					return (
						<div>{product.name}</div>
					)
				}
			},
			{
				title: 'Price',
				key: 'name',
				render: (salesOrderItem) => {
					const { price } = salesOrderItem
					return (
						<div>{getFormattedCurrency(price)}</div>
					)
				}
			},
			{
				title: 'Delivered Quantity',
				key: 'deliveredQty',
				render: (salesOrderItem) => {
					const { deliveredQuantity } = salesOrderItem
					const returnedSalesOrderItem = returnedSalesOrderItems.find(({ productId }) => productId === salesOrderItem.productId)
					const quantity = deliveredQuantity - (returnedSalesOrderItem?.returnedQuantity || 0)
					return (
						<div>{quantity}</div>
					)
				}
			},
			{
				title: 'Discount',
				key: 'discount',
				render: (salesOrderItem) => {
					const { discount, discountAmount } = salesOrderItem
					let discountValue = 0
					if (discount > 0) {
						discountValue = discount
					} else if (discountAmount > 0) {
						discountValue = discountAmount
					}
					return (
						<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
							<div>
								{
									discount > 0 &&
										<div>{`-${getFormattedCurrency(discountValue)} (${(+discount).toFixed(2)} %)`}</div>
								}
								{
									discountAmount > 0 &&
										<div>{`-${getFormattedCurrency(discountValue)}`}</div>
								}
							</div>
							<div
								className={styles.discountToggle}
							>
								{discountAmount > 0 ? 'Flat' : 'Percentage'}
							</div>
						</div>
					)
				}
			},
			{
				title: 'Total Price',
				key: 'deliverPrice',
				width: 200,
				render: (salesOrderItem) => {
					const { deliveredQuantity, discount, discountAmount } = salesOrderItem
					const price = +salesOrderItem.price
					const returnedSalesOrderItem = returnedSalesOrderItems.find(({ productId }) => productId === salesOrderItem.productId)
					const quantity = deliveredQuantity - (returnedSalesOrderItem?.returnedQuantity || 0)
					let returnPrice = price * quantity
					let totalDiscount = 0
					if (discount > 0) {
						totalDiscount = calculateDiscount(returnPrice, discount)
					} else if (discountAmount > 0) {
						totalDiscount = discountAmount
					}
					if (deliveredQuantity > 0) {
						returnPrice = returnPrice - totalDiscount
					} else {
						returnPrice = 0
					}
					return (
						<div>{getFormattedCurrency(returnPrice)}</div>
					)
				}
			}
		]
		const returnedSalesOrderItemColumns = [
			{
				title: 'SKU',
				key: 'sku',
				render: (salesOrderItem) => {
					const { product } = salesOrderItem
					return (
						<div>{product?.sku || 'No SKU'}</div>
					)
				}
			},
			{
				title: 'Product Name',
				key: 'name',
				render: (salesOrderItem) => {
					const { product } = salesOrderItem
					return (
						<div>{product.name}</div>
					)
				}
			},
			{
				title: 'Price',
				key: 'name',
				render: (salesOrderItem) => {
					const { price } = salesOrderItem
					return (
						<div>{getFormattedCurrency(price)}</div>
					)
				}
			},
			{
				title: 'Returned Quantity',
				key: 'returnedQty',
				render: (salesOrderItem, record, index) => {
					return (
						<NumberInput
							min={0}
							defaultValue={0}
							max={salesOrderItem.deliveredQuantity}
							step={1}
							value={salesOrderItem.returnedQuantity}
							onChange={value => onAddReturnedQuantity(value, index)}
						/>
					)
				}
			},
			{
				title: 'Discount',
				key: 'discount',
				width: 220,
				render: (salesOrderItem, record, index) => {
					const { updatedReturnedQuantity, isFlatDiscount, discount, discountAmount } = salesOrderItem
					const price = +salesOrderItem.price
					let totalPrice = price * updatedReturnedQuantity
					let discountValue = 0
					if (discount > 0) {
						discountValue = discount
					} else if (discountAmount > 0) {
						discountValue = discountAmount
					}
					return (
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								className={styles.discountToggle}
								onClick={() => {
									const newReturnedSalesOrderItems = [...returnedSalesOrderItems]
									newReturnedSalesOrderItems[index].isFlatDiscount = !isFlatDiscount
									newReturnedSalesOrderItems[index].discount = 0
									newReturnedSalesOrderItems[index].discountAmount = 0
									setReturnedSalesOrderItems(newReturnedSalesOrderItems)
								}}
							>
								{isFlatDiscount ? 'Flat' : 'Percentage'}
							</div>
							<NumberInput
								min={0}
								max={isFlatDiscount ? totalPrice : 100}
								defaultValue={discountValue}
								step={1}
								value={discountValue}
								onChange={value => {
									const newReturnedSalesOrderItems = [...returnedSalesOrderItems]
									if (isFlatDiscount) {
										newReturnedSalesOrderItems[index].discount = 0
										newReturnedSalesOrderItems[index].discountAmount = value || 0
									} else {
										newReturnedSalesOrderItems[index].discount = value || 0
										newReturnedSalesOrderItems[index].discountAmount = 0
									}
									setReturnedSalesOrderItems(newReturnedSalesOrderItems)
								}}
							/>
							{
								!isFlatDiscount && <div style={{ marginLeft: 4 }}>%</div>
							}
						</div>
					)
				}
			},
			{
				title: 'Return Price',
				key: 'returnPrice',
				width: 200,
				render: (salesOrderItem) => {
					const { returnedQuantity, discount, discountAmount } = salesOrderItem
					const price = +salesOrderItem.price
					let returnPrice = price * returnedQuantity
					let totalDiscount = 0
					if (discount > 0) {
						totalDiscount = calculateDiscount(returnPrice, discount)
					} else if (discountAmount > 0) {
						totalDiscount = discountAmount
					}
					if (returnedQuantity > 0) {
						returnPrice = returnPrice - totalDiscount
					} else {
						returnPrice = 0
					}
					return (
						<div>{getFormattedCurrency(returnPrice)}</div>
					)
				}
			}
		]
		return (
			<div>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle} style={{ marginRight: 0 }}>Return Order</p>
					<div className={styles.orderId}>{salesOrders[0]?.internalId}</div>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div className={styles.modalContent}>
					<div style={{ padding:'0px 24px 24px 24px' }}>
						<div className={styles.tables}>
							<CustomTable
								pagination={false}
								columns={salesOrderItemColumns}
								dataSource={salesOrders[0]?.salesOrderItems}
								size='small'
							/>
						</div>
						<p className={styles.modalTitle} style={{ marginTop: 24, marginBottom: 12 }}>Returnable Quantity</p>
						<CustomTable
							pagination={false}
							columns={returnedSalesOrderItemColumns}
							dataSource={returnedSalesOrderItems}
							size='small'
						/>
						<Alert
							style={{ borderRadius: 0, marginTop: 24 }}
							className={styles.alert}
							message='Based on the returned product, the product discount and the total price will be adjusted accordingly.'
							type='info'
							showIcon
						/>
					</div>
					<div className={styles.actionButtonsSticky}>
						<Button
							key={6}
							onClick={() => setCurrentStep(modalViews.CHANGE_STATUS)}
						>
							Cancel
						</Button>
						<Button
							key={7}
							type='primary'
							disabled={isReturningSalesOrderItems}
							loading={isUpdatingStatus}
							onClick={() => changeStatusToReturned()}
						>
							Returned
						</Button>
					</div>
				</div>
			</div>
		)
	}

	const renderOnHoldView = () => {
		return (
			<div>
				<div>
					<div className={styles.titleContainer}>
						<p className={styles.modalTitle} style={{ marginRight: 0 }}>On Hold Order</p>
						{
							salesOrders.length === 1 &&
							<div className={styles.orderId}>{salesOrders[0]?.internalId}</div>
						}
						<svg className={styles.closeIcon} onClick={onCancel}>
							<use href='/img/close-2.svg#icon' />
						</svg>
					</div>
				</div>
				<div className={styles.modalContent}>
					<div style={{ padding:'0px 24px 24px 24px', display: 'flex', flexDirection: 'column', gap: 24 }}>
						<CustomSelectSecondary
							title='Select On Hold Reason'
							options={onHoldReasonOptions}
							value={onHoldReason}
							onChange={setOnHoldReason}
						/>
						{
							onHoldReason === onHoldReasons.OTHER &&
							<Input
								title='Reason'
								type='textarea'
								value={onHoldOtherReason}
								onChange={(e) => setOnHoldOtherReason(e.target.value)}
							/>
						}
						{
							onHoldReason &&
							<Input
								type='date'
								title='Select follow up date'
								disabledDate={current => current && current.valueOf() < moment().subtract(1, 'days')}
								value={followUpDate ? moment(followUpDate) : null}
								onChange={(date) => setFollowUpDate(date?.toDate())}
							/>
						}
					</div>
					<div className={styles.actionButtonsSticky}>
						<Button
							key={6}
							onClick={() => setCurrentStep(modalViews.CHANGE_STATUS)}
						>
							Cancel
						</Button>
						<Button
							key={7}
							type='primary'
							disabled={!followUpDate || !onHoldReason || (onHoldReason === onHoldReasons.OTHER && !onHoldOtherReason)}
							loading={isUpdatingStatus}
							onClick={() => changeStatusToHold()}
						>
							On Hold
						</Button>
					</div>
				</div>
			</div>
		)
	}

	const updateLastDeliveryAddressToDeliveryAreaMapping = (partnerType, locationId, deliveryArea = {}) => {
		const selectedDeliveryArea = convertDeliveryAreaToDeliveryAreaOption(deliveryArea, partnerType)
		setLastDeliveryAddressToDeliveryAreaMapping({
			...lastDeliveryAddressToDeliveryAreaMapping,
			[partnerType]: {
				...lastDeliveryAddressToDeliveryAreaMapping[partnerType] || {},
				[locationId]: selectedDeliveryArea
			}
		})
	}

	return (
		<Modal
			style={{
				maxWidth:
				currentStep === modalViews.DAMAGED ? 704 :
					currentStep === modalViews.PROCESSING ? 1500 :
						currentStep === modalViews.APPROVED || currentStep === modalViews.DELIVERED || currentStep === modalViews.RETURNED || currentStep === modalViews.DELIVERED_PAYMENT_DUE || currentStep === modalViews.DELIVERED_PAYMENT_COLLECTED ? 1100 :
							496
			}}
			width='calc(100vw - 100px)'
			destroyOnClose
			visible={visible}
			modalRender={() => {
				return (
					<div className={`${styles.modal} ant-modal-content`}>
						{
							currentStep === modalViews.CHANGE_STATUS && renderChangeStatusView()
						}
						{
							currentStep === modalViews.DAMAGED && renderDamagedView()
						}
						{
							currentStep === modalViews.APPROVED && renderApprovedView()
						}
						{
							currentStep === modalViews.PROCESSING && renderProcessingView()
						}
						{
							currentStep === modalViews.DELIVERED && renderDeliveredView()
						}
						{
							(currentStep === modalViews.DELIVERED_PAYMENT_DUE || currentStep === modalViews.DELIVERED_PAYMENT_COLLECTED) && renderDeliveredOrderView()
						}
						{
							currentStep === modalViews.RETURNED && renderReturnedView()
						}
						{
							currentStep === modalViews.ON_HOLD && renderOnHoldView()
						}

						{
							disabledPartnerOrders.length > 0 && isInactiveModalVisible ?
								<ECommerceUpdateOrderDisabledPartnerModal
									visible={isInactiveModalVisible}
									setIsInactiveModalVisible={setIsInactiveModalVisible}
									salesOrders={salesOrders}
									setDisabledPartnerOrders={setDisabledPartnerOrders}
									disabledPartnerOrders={disabledPartnerOrders}
									subStatus={subStatus}
									onCancel={onCancel}
									onChange={(newSelectedOrders) => changeStatus(newSelectedOrders)}
								/> : null
						}
					</div>
				)
			}}
		/>
	)
}

ECommerceUpdateOrderStatusModal.propTypes = {
	visible: PropTypes.bool,
	salesOrderIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	defaultStatus: PropTypes.string,
	status: PropTypes.string,
	subStatus: PropTypes.string,
	disableStatusSelect: PropTypes.bool,
	onCancel: PropTypes.func,
	onComplete: PropTypes.func,
	permissions: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]))])
}

ECommerceUpdateOrderStatusModal.defaultProps = {
	salesOrderIds: [],
	permissions: [],
	disableStatusSelect: false
}

export default ECommerceUpdateOrderStatusModal
