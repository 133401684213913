import moment from 'moment'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { deliveryPartnerLogos, FileTypes, PartnerType, roles, shippingAreas, StandardInventoryProductTypes, SubscriptionPeriod } from './constants'

export const convertEnumToString = (enumField) => {
	if (!enumField) {
		return ''
	}
	return enumField
		.split('_')
		.map(word => {
			if (word.includes('-')) {
				// Handle hyphenated words
				return word.split('-')
					.map(part => part.charAt(0).toUpperCase() + part.slice(1))
					.join('-')
			}
			// Capitalize the first letter of each word
			return word.charAt(0).toUpperCase() + word.slice(1)
		})
		.join(' ')
}

export const convertStringToEnum = (string) => {
	if (!string) {
		return ''
	}
	return string
		.replace(/[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/g, ' ') // Remove all special characters except hyphen
		.replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between lowercase and uppercase letters
		.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Insert space between consecutive uppercase letters followed by lowercase
		.toLowerCase()
		.trim()
		.replace(/\s+/g, '_') // Replace any whitespace with a single underscore
		.replace(/(\d+)_-_(\d+)/g, '$1-$2') // Fix cases like "1_-_2" to "1-2"
}

export const convertCamelCaseToString = (string) => {
	const result = string.replace(/([A-Z])/g, ' $1')
	return `${result.charAt(0).toUpperCase()}${result.slice(1)}`.trim()
}

export const convertStatusKeyToLabel = (string) => {
	const subString = string.substring(21)
	return convertCamelCaseToString(subString)
		.replace('To', '\u2192')
		.replaceAll('Requested', 'Pending')
		.replaceAll('Completed', 'Delivered')
}

export const capitalizeWord = (word, delimiter = ' ') => {
	const formattedWord = word?.trim()?.toLowerCase() || ''
	if (!formattedWord) {
		return ''
	}
	const capitalizeWords = formattedWord.split(delimiter).map(word => {
		const trimmedWord = word?.trim() || ''
		if (!trimmedWord) {
			return ''
		}
		return `${trimmedWord[0].toUpperCase()}${trimmedWord.slice(1)}`
	})
	return capitalizeWords.join(' ')
}

export const getAbbreviation = (text) => {
	if (typeof text != 'string' || !text) {
		return ''
	}
	if (text.trim().split(' ').length === 1) {
		return text.toUpperCase().substring(0, 5)
	} else {
		let acronym = text.match(/\b([A-Z])/g)
		if (acronym) {
			return acronym.join('').substring(0, 5)
		} else {
			return ''
		}
	}
}

export const getFormattedCurrency = (amount, hasCurrencyTag = true, currencyOverride, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
	let value = amount ? Number.parseFloat(amount) : 0.0
	value = isNaN(value) ? 0.0 : value
	if (hasCurrencyTag) {
		const currency = currencyOverride ?
			currencyOverride :
			localStorage?.getItem('currency') || 'BDT'
		return value.toLocaleString('en-US', {
			style: 'currency',
			currency: currency,
			minimumFractionDigits,
			maximumFractionDigits
		})
	} else {
		return value.toLocaleString('en-US', {
			minimumFractionDigits,
			maximumFractionDigits
		})
	}
}

export const getCurrencyCode = () => {
	if (typeof window !== 'undefined') {
		return localStorage?.getItem('currency') || 'BDT'
	} else {
		return 'BDT'
	}
}

export const getCountryCode = () => {
	if (typeof window !== 'undefined') {
		return localStorage?.getItem('country') || 'BD'
	} else {
		return 'BD'
	}
}

export const numberToAbbreviatedString = (num, fixed) => {
	if (num === null) {
		return null
	}
	if (num === 0) {
		return '0'
	}
	fixed = !fixed || fixed < 0 ? 0 : fixed
	var b = num.toPrecision(2).split('e'),
		k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3),
		c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed),
		d = c < 0 ? c : Math.abs(c),
		e = d + ['', 'K', 'M', 'B', 'T'][k]
	return e
}

export const calculateDiscount = (amount, discountInPercentage = 0) => +((amount * discountInPercentage) / 100).toFixed(2)

export const calculateAmountAfterDiscount = (amount, flatDiscount, discountInPercentage) =>
	discountInPercentage > 0 ? amount - calculateDiscount(amount, discountInPercentage) : amount - flatDiscount

export const isEmptyObject = object => Object.keys(object).length === 0

export const isValidUUID = (str) => {
	const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
	return regexExp.test(str)
}

export const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
	const p = 0.017453292519943295 // Math.PI / 180
	const a = 0.5 - Math.cos((lat2 - lat1) * p) / 2 +
			Math.cos(lat1 * p) * Math.cos(lat2 * p) *
			(1 - Math.cos((lon2 - lon1) * p)) / 2

	return 12742 * Math.asin(Math.sqrt(a)) * 1000
}

export const removeURLParameter = (url, parameter) => {
	const urlparts = url.split('?')
	if (urlparts.length >= 2) {

		const prefix = encodeURIComponent(parameter) + '='
		const pars = urlparts[1].split(/[&;]/g)

		for (let i = pars.length; i-- > 0;) {
			if (pars[i].lastIndexOf(prefix, 0) !== -1) {
				pars.splice(i, 1)
			}
		}

		return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
	}
	return url
}

export const isNotEmpty = (value) => {
	return value !== null && value !== undefined && value !== '' && value !== 'undefined' && value !== 'null'
}

export const isEmail = (email) => {
	const re = /\S+@\S+\.\S+/
	return re.test(email)
}

export const getPaymentMethod = (paymentMethod) => {
	switch (paymentMethod) {
		case 'CASH':
			return 'Cash'
		case 'CREDIT':
			return 'Credit'
		case 'CASH_ON_DELIVERY':
			return 'Cash On Delivery'
		case 'BKASH':
			return 'Bkash'
		case 'NAGAD':
			return 'Nagad'
		case 'UPAY':
			return 'Upay'
		case 'ROCKET':
			return 'Rocket'
		case 'TAP':
			return 'Tap'
		case 'BANK_DEPOSIT':
			return 'Bank Deposit'
		case 'BANK_TRANSFER':
			return 'Bank Transfer'
		default:
			return convertEnumToString(paymentMethod) || ''
	}
}

export const getDiscountedPrice = (price, discount) => price - (price * discount / 100)

export const formatDate = (date) =>
	new Intl.DateTimeFormat('en-US', {
		month: 'short',
		day: '2-digit',
		year: 'numeric'
	}).format(date)

export const getShippingArea = (area) => {
	switch (area) {
		case shippingAreas.DHAKA:
			return 'Inside Dhaka'
		case shippingAreas.DHAKA_SUBURB:
			return 'Dhaka Suburb'
		case shippingAreas.OUTSIDE_DHAKA:
			return 'Outside Dhaka'
	}
}

export const getRoleName = (role) => {
	switch (role) {
		case roles.USER:
			return 'General'
		case roles.SALES_MANAGER:
			return 'Sales & Marketing'
		case roles.PURCHASE_MANAGER:
			return 'Procurement & Sourcing'
		default:
			return convertEnumToString(role.toLowerCase())
	}
}

export const isValidInternationalPhoneNumber = (phone = '') => {
	if (phone.startsWith('+880')) {
		return !!phone.match('^(\\+8801[3-9]\\d{8})$')
	} else {
		return isValidPhoneNumber(phone)
	}
}

export const getFileType = (mimeType = '') => {
	const type = Object.values(FileTypes).find(fileType => mimeType.includes(fileType))
	return type ? type : FileTypes.OTHER
}

export const getProductType = (type) => {
	switch (type) {
		case StandardInventoryProductTypes.RAW_MATERIAL:
			return 'Raw Materials'
		case StandardInventoryProductTypes.FINISHED_GOODS:
			return 'Finished Goods'
		default:
			return type
	}
}

export const isOverflowing = (element, config) => {
	if (!element) {
		return false
	}
	const { scrollHeight, clientHeight, scrollWidth, clientWidth } = element
	const { x, y } = config
	if (x && y || !x && !y) {
		return scrollHeight > clientHeight || scrollWidth > clientWidth
	}
	return x ? scrollWidth > clientWidth : scrollHeight > clientHeight
}

export const range = (start, end) => {
	const result = []
	for (let i = start; i < end; i++) {
		result.push(i)
	}
	return result
}

export const getDeliveryPartnerLogo = (partnerType) => {
	const logo = deliveryPartnerLogos[partnerType] || deliveryPartnerLogos[PartnerType.CUSTOM]
	return logo
}

export const isLocationValid = (location) => {
	if (!location) {
		return false
	}
	if (isNaN(+location.lat) || isNaN(+location.lng)) {
		return false
	}
	if (+location.lat === 91 || +location.lng === 181) {
		return false
	}
	return true
}

export const isValidUrl = (urlString) => {
	var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
		'(\\#[-a-z\\d_]*)?$', 'i') // validate fragment locator
	return !!urlPattern.test(urlString)
}

export const getSubscriptionKey = (subscriptionTier, subscriptionPeriod) => {
	return subscriptionPeriod === SubscriptionPeriod.YEARLY.value ? subscriptionTier + '_YEARLY' : subscriptionTier
}

const getCurrencyName = (currency) => {
	switch (currency) {
		case 'BDT':
			return ['Taka', 'Paisa']
		case 'PKR':
			return ['Rupee', 'Paise']
		default:
			return ['Taka', 'Paisa']
	}
}


export const paymentAmountInWords = (amount, currency = 'BDT') => {
	let num = Math.floor(amount * 100)
	const currencyName = getCurrencyName(currency)
	const a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen ']
	const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
	if ((num = num.toString()).length > 11) {
		return 'overflow'
	}
	const n = ('00000000000' + num)
		.substring(('00000000000' + num).length - 11)
		.match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})(\d{2})$/)
	if (!n) {
		return
	}
	let str = ''
	str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : ''
	str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lac ' : ''
	str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : ''
	str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : ''
	str += n[5] != 0 ? (str != '' ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : ''
	str += currencyName[0]
	if (Number(n[6]) !== 0) {
		str += ' and ' + (a[Number(n[6])] || b[n[6][0]] + ' ' + a[n[6][1]]) + currencyName[1]
	}
	return str + ' Only'
}

export const getFormattedNumber = (amount, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
	let value = amount ? Number.parseFloat(amount) : 0
	value = isNaN(value) ? 0 : value
	return value.toLocaleString('en-US', {
		minimumFractionDigits,
		maximumFractionDigits
	})
}

export const downloadArrayOfObjectsAsCSV = (data, fileName, { includeDateInFileName, headers } = { includeDateInFileName: true, headers: [] }) => {
	if (!data?.length) {
		return
	}

	const columnHeaders = headers?.length ? headers : Object.keys(data[0]).map(key => ({ key, name: key }))

	let csvString = columnHeaders.map(({ name }) => name).join(',')
	data.forEach(item => {
		csvString += '\n'
		columnHeaders.forEach(({ key }, colIndex) => {
			let cellValue = `"${item[key]}"`
			if (colIndex >= columnHeaders.length - 1) {
				csvString += `${cellValue}`
			} else {
				csvString += `${cellValue},`
			}
		})
	})

	const link = document.createElement('a')
	link.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvString)
	link.download = `${fileName}${includeDateInFileName ? `_${moment().format('YYYY-MM-DD')}` : ''}.csv`
	link.click()
	link.remove()
}

export const getFormattedDateRangeType = (type) => {
	let dateRangeType = ''
	switch (type) {
		case 'createdAt':
			dateRangeType = 'Creation'
			break
		case 'approvedAt':
			dateRangeType = 'Approved'
			break
		case 'inTransitAt':
			dateRangeType = 'In-Transit'
			break
		case 'deliveredAt':
			dateRangeType = 'Delivered'
			break
	}
	return dateRangeType
}

export const chunkArray = (array, chunkSize) => {
	const result = []
	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize))
	}
	return result
}

export const convertNumberToBankNoteWords = (value) => {
	const fractionValue = Math.round((value % 1) * 100)
	let fractionText = ''

	if (fractionValue > 0) {
		fractionText = 'AND ' + convertNumber(fractionValue) + ' PAISE'
	}

	return convertNumber(value) + ' RUPEE ' + fractionText + ' ONLY'
}

const convertNumber = (number) => {
	if (number < 0 || number > 999999999) {
		return 'ZERO'
	}

	const croreValue = Math.floor(number / 10000000) /* Crore */
	number -= croreValue * 10000000

	const lakhValue = Math.floor(number / 100000) /* Lakhs */
	number -= lakhValue * 100000

	const thousandValue = Math.floor(number / 1000) /* Thousand */
	number -= thousandValue * 1000

	const hundredValue = Math.floor(number / 100) /* Hundreds */
	number = number % 100 /* Ones */

	const tensValue = Math.floor(number / 10)
	const onesValue = Math.floor(number % 10)

	let result = ''

	if (croreValue > 0) {
		result += convertNumber(croreValue) + ' CRORE'
	}

	if (lakhValue > 0) {
		result += (result === '' ? '' : ' ') + convertNumber(lakhValue) + ' LAKH'
	}

	if (thousandValue > 0) {
		result += (result === '' ? '' : ' ') + convertNumber(thousandValue) + ' THOUSAND'
	}

	if (hundredValue) {
		result += (result === '' ? '' : ' ') + convertNumber(hundredValue) + ' HUNDRED'
	}

	const onesArray = [
		'', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT',
		'NINE', 'TEN', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN',
		'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'
	]

	const tensArray = [
		'', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY',
		'EIGHTY', 'NINETY'
	]

	if (tensValue > 0 || onesValue > 0) {
		if (result !== '') {
			result += ' AND '
		}

		if (tensValue < 2) {
			result += onesArray[tensValue * 10 + onesValue]
		} else {
			result += tensArray[tensValue]
			if (onesValue > 0) {
				result += '-' + onesArray[onesValue]
			}
		}
	}

	if (result === '') {
		result = 'ZERO'
	}

	return result
}
