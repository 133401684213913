
export const getWorkbook = (type, fields) => {
	return {
		name: type.name,
		labels: ['pinned'],
		sheets: [
			{
				name: type.name,
				slug: type.slug,
				fields
			}
		],
		actions: [
			{
				operation: 'submitActionFg',
				mode: 'foreground',
				label: 'Upload CSV',
				description: 'Upload CSV file to import data into the system',
				primary: true,
				confirm: true,
				tooltip: 'From here you can upload '
			}
		],
		settings: {
			trackChanges: true
		}
	}
}
