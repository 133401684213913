require('./ProductPickingModal.less')
import { Button, Image, Modal, notification, Popover, Table } from 'antd'
import ReactToPrint from 'react-to-print'
import CustomSearchableSelectWithTags from '../custom-searchable-select-with-tags'
import styles from './ProductPickingModal.module.css'
import CustomTable from '../table'
import { PrinterOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { downloadArrayOfObjectsAsCSV } from '../../utils'
import SalesOrders from '../../services/api/sales-orders'
import Link from 'next/link'
import { LocationTypes, paths } from '../../utils/constants'
import { Locations } from '../../services/api/locations'
import moment from 'moment'
import { useSelector } from 'react-redux'

const { Summary } = Table


const invoiceTableColumns = [
	{
		title: 'SL',
		key: 'sl',
		width: 70,
		render: (item, record, index) => {
			return (
				<div>{index + 1}</div>
			)
		}
	},
	{
		title: 'Invoice No',
		key: 'invoiceNo',
		render: ({ internalId, id }) => {
			return (
				<Link href={`${paths.SALES_ORDERS}/${id}`}>
					<a>{internalId}</a>
				</Link>
			)
		}
	},
	{
		title: 'Shipping Date',
		key: 'orderDate',
		render: ({ orderDate }) => {
			return (
				<div>{moment(orderDate).format('MMM D, YYYY')}</div>
			)
		}
	},
	{
		title: 'Product Qty',
		dataIndex: 'productQuantity',
		key: 'productQuantity'
	}
]

const ProductPickingModal = ({
	visible,
	onCancel,
	status
}) => {
	const { userProfile } = useSelector(state => state.authReducer)
	const [pickingProducts, setPickingProducts] = useState([])
	const [isPrintingPickingTable, setIsPrintingPickingTable] = useState(false)
	const [isLoadingExportPickingProducts, setIsLoadingExportPickingProducts] = useState(false)
	const [isLoadingProducts, setIsLoadingProducts] = useState(false)
	const [warehouse, setWarehouse] = useState(null)
	const [warehouses, setWarehouses] = useState([])
	const [selectedWarehouses, setSelectedWarehouses] = useState([])
	const [isFetchingWarehouseLocations, setIsFetchingWarehouseLocations] = useState(false)
	const [areAllWarehouseLocationsSelected, setAreAllWarehouseLocationsSelected] = useState(false)
	const pickingTableRef = useRef(null)
	const printRef = useRef()

	useEffect(() => {
		if (userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setWarehouses(locations)
			} else {
				getWarehouses()
			}
		}
	}, [userProfile])

	useEffect(() => {
		if (visible) {
			getAllPickingOrders()
		}
	}, [visible])

	const modifiedPickingProducts = pickingProducts
		.filter(({ inventoryItems }) => {
			if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
				return true
			} else {
				return inventoryItems.some(({ locationId }) => selectedWarehouses.some(({ id }) => id === locationId))
			}
		})
		.map(product => {
			const totalQuantity = product.orders
				.filter(({ pickUpLocationId }) => {
					if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
						return true
					} else {
						return selectedWarehouses.some(({ id }) => id === pickUpLocationId)
					}
				})
				.reduce((acc, curr) => {
					return acc + curr.productQuantity
				}, 0)
			return {
				...product,
				totalQuantity
			}
		})
		.filter(({ totalQuantity }) => totalQuantity > 0)

	const getInvoiceTableData = (productId) => {
		const pickingProduct = pickingProducts.find(({ id }) => id === productId)
		const orders = pickingProduct.orders.filter(({ pickUpLocationId }) => {
			if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
				return true
			} else {
				return selectedWarehouses.some(({ id }) => id === pickUpLocationId)
			}
		})
		const totalQuantity = orders.reduce((acc, curr) => {
			return acc + curr.productQuantity
		}, 0)
		return {
			orders,
			totalQuantity
		}
	}

	const getAllPickingOrders = async () => {
		try {
			setIsLoadingProducts(true)
			const { data } = await SalesOrders.getPickingProducts({
				status
			})
			setPickingProducts(data)
		} catch (e) {
			notification.error({
				message: 'Unable to get picking products.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingProducts(false)
		}
	}

	const getWarehouses = async () => {
		try {
			setIsFetchingWarehouseLocations(true)
			const response = await Locations.index({
				types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY],
				page: -1
			})
			const locations = response.data.results
			setWarehouses(locations)
		} catch (err) {
			notification.error({
				message: 'Unable to Fetch User\'s Locations',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFetchingWarehouseLocations(false)
		}
	}

	const searchWarehouses = value => {
		return Locations.index({
			searchTerm: value,
			types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY]
		})
	}

	const onWarehouseSelected = value => {
		const { data } = value
		const newSelectedLocation = { ...data, value: data.id, label: data.label || data.address }
		setWarehouse(newSelectedLocation)
		const existedLocation = selectedWarehouses.find(p => p.id === newSelectedLocation.id)
		if (!existedLocation) {
			selectedWarehouses.push(newSelectedLocation)
		}
	}

	const onExportPickingProducts = async () => {
		try {
			setIsLoadingExportPickingProducts(true)
			if (pickingProducts.length) {
				const csvData = modifiedPickingProducts
					.map(product => {
						return {
							'Product SKU': product.sku || 'No SKU',
							'Image': product.imageUrls.length > 0 ? product.imageUrls[0] : '',
							'Product Name': product.name,
							'Picking Quantity': product.totalQuantity,
							'Available Quantity': product.inventoryItems
								.filter(({ quantity, locationId }) => {
									if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
										return quantity > 0
									} else if (selectedWarehouses.length > 0) {
										return quantity > 0 && selectedWarehouses.some(({ id }) => id === locationId)
									} else {
										return false
									}
								})
								.reduce((acc, curr) => {
									return acc + curr.quantity
								}, 0),
							'Shortage Quantity': product.inventoryItems
								.filter(({ quantity, locationId }) => {
									if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
										return quantity < 0
									} else if (selectedWarehouses.length > 0) {
										return quantity < 0 && selectedWarehouses.some(({ id }) => id === locationId)
									} else {
										return false
									}
								})
								.reduce((acc, curr) => {
									return acc + curr.quantity
								}, 0),
							'Vendor': product.vendor ? product.vendor.name : 'N/A'
						}
					})
				downloadArrayOfObjectsAsCSV(csvData, 'Product and Stock Checklist', { includeDateInFileName: true })
			}
			setIsLoadingExportPickingProducts(false)
		} catch (e) {
			notification.error({
				message: 'Export Error',
				description: e.message || 'An error occurred during export.',
				placement: 'bottomLeft'
			})
			setIsLoadingExportPickingProducts(false)
		}
	}

	const renderInvoiceDetails = (productId) => {
		const { orders, totalQuantity } = getInvoiceTableData(productId)
		return (
			<>
				<p className={styles.invoiceNo}>Invoice Information</p>
				<CustomTable
					pagination={false}
					scroll={{ y: '275px' }}
					columns={invoiceTableColumns}
					dataSource={orders}
					summary={() => {
						return (
							<Summary.Row className={`${styles.summaryRow}`}>
								<Summary.Cell />
								<Summary.Cell />
								<Summary.Cell>Total</Summary.Cell>
								<Summary.Cell>{totalQuantity?.toFixed(2)}</Summary.Cell>
							</Summary.Row>
						)
					}}
				/>
			</>
		)
	}

	const pickingOrderTableColumns = [
		{
			title: 'Product SKU',
			key: 'productId',
			render: ({ id, sku }) => {
				return (
					<>
						<Link href={`${paths.PRODUCTS}/${id}`}>
							<a>{sku ? sku : 'No SKU'}</a>
						</Link>
						{
							!isLoadingProducts &&
							<Popover
								overlayClassName='item-details-popover'
								placement='rightTop'
								arrowPointAtCenter
								trigger={['hover', 'click']}
								content={renderInvoiceDetails(id)}
							>
								<InfoCircleOutlined
									style={{ fontSize: 16, marginLeft: 60, cursor: 'pointer' }}
									className={styles.pickingProductInfoIcon}
								/>
							</Popover>
						}
					</>
				)
			}
		},
		{
			title: 'Images',
			key: 'images',
			width: 64,
			render: ({ imageUrls }) => {
				return (
					imageUrls.length > 0 ?
						<div
							key={imageUrls[0]}
							className={`${styles.imageContainer} product-image`}
						>
							<Image
								width={44}
								height={44}
								src={imageUrls[0]}
								placeholder={true}
								style={{ objectFit: 'contain', borderRadius: '0px' }}
								referrerPolicy='no-referrer'
							/>
						</div> :
						<Image
							width={44}
							height={44}
							placeholder={true}
							style={{ objectFit: 'contain', borderRadius: '0px' }}
							referrerPolicy='no-referrer'
						/>
				)
			}
		},
		{
			title: 'Product Name',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: 'Picking Qty',
			dataIndex: 'totalQuantity',
			key: 'totalQuantity'
		},
		{
			title: 'Available Qty',
			key: 'availableQuantity',
			render: ({ inventoryItems }) => {
				const totalQuantity = inventoryItems
					.filter(({ quantity, locationId }) => {
						if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
							return quantity > 0
						} else if (selectedWarehouses.length > 0) {
							return quantity > 0 && selectedWarehouses.some(({ id }) => id === locationId)
						} else {
							return false
						}
					})
					.reduce((acc, curr) => {
						return acc + curr.quantity
					}, 0)
				return totalQuantity
			}
		},
		{
			title: 'Shortage Qty',
			key: 'shortageQuantity',
			render: ({ inventoryItems }) => {
				const totalQuantity = inventoryItems
					.filter(({ quantity, locationId }) => {
						if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
							return quantity < 0
						} else if (selectedWarehouses.length > 0) {
							return quantity < 0 && selectedWarehouses.some(({ id }) => id === locationId)
						} else {
							return false
						}
					})
					.reduce((acc, curr) => {
						return acc + curr.quantity
					}, 0)
				return totalQuantity
			}
		},
		{
			title: 'Vendor',
			key: 'vendor',
			render: ({ vendor }) => {
				if (!vendor) {
					return 'N/A'
				}

				return (
					<div>
						{vendor.name}
					</div>
				)
			}
		}
	]

	const renderPickingTable = () => {
		return (
			<CustomTable
				loading={isLoadingProducts}
				refresh={getAllPickingOrders}
				columns={pickingOrderTableColumns}
				dataSource={modifiedPickingProducts}
				pagination={false}
				title={() => <span style={{ opacity:0, userSelect: 'none' }}>.</span>}
				headerChildren={
					<>
						<Button
							type='primary'
							loading={isPrintingPickingTable}
							icon={<PrinterOutlined />}
							onClick={() => {
								setIsPrintingPickingTable(true)
								document.title = `Product and Stock Checklist_${moment().format('YYYY-MM-DD')}`
								printRef.current.handleClick()
							}}
						>
							Print
						</Button>
						<Button
							type='primary'
							loading={isLoadingExportPickingProducts}
							icon={<DownloadOutlined />}
							onClick={onExportPickingProducts}
						>
							Export As CSV
						</Button>
					</>
				}
			/>
		)
	}

	const renderPickingModalContent = () => {
		return (
			<div className={`${styles.pickingModal} product-picking-modal ant-modal-content`}>
				<div className={styles.modalTitleContainer}>
					<p className={styles.modalTitle}>Product and Stock Checklist</p>
					<svg className={styles.closeIcon} onClick={() => onCancel()}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div style={{ marginBottom: 12, width: '50%' }}>
					<CustomSearchableSelectWithTags
						searchTask={searchWarehouses}
						defaultOptions={warehouses}
						isLoading={isFetchingWarehouseLocations}
						title='Warehouse'
						selectedTitle='Selected Warehouses'
						customLabel={(data) => data.label || data.address}
						valueIndex='id'
						descriptionIndex='address'
						placeholder='Search Warehouse'
						onChange={onWarehouseSelected}
						onClear={() => setWarehouse(null)}
						value={warehouse}
						setValue={setWarehouse}
						areAllValuesSelected={areAllWarehouseLocationsSelected}
						setAreAllValuesSelected={setAreAllWarehouseLocationsSelected}
						onAreAllValuesSelectedChange={areAllWarehousesSelected => {
							setSelectedWarehouses(areAllWarehousesSelected ? warehouses : [])
							setWarehouse(null)
						}}
						selectedValues={selectedWarehouses}
						setSelectedValues={setSelectedWarehouses}
					/>
				</div>
				<div
					className={`${styles.modalContent} ${styles.pickingTableContainer}`}
					ref={pickingTableRef}
				>
					<p className={styles.printTitle}>Product and Stock Checklist</p>
					<div className={styles.pickingTables}>
						{renderPickingTable()}
						{renderPickingTable()}
					</div>
					<ReactToPrint
						ref={printRef}
						content={() => pickingTableRef.current}
						onAfterPrint={() => {
							setIsPrintingPickingTable(false)
							document.title = 'Nuport'
						}}
					/>
				</div>
			</div>
		)
	}

	return (
		<Modal
			width={1000}
			visible={visible}
			centered={true}
			destroyOnClose={true}
			modalRender={renderPickingModalContent}
		/>
	)
}

export default ProductPickingModal
