import styles from './GeneralSalesOrdersPage.module.css'
require('./GeneralSalesOrdersPage.less')
import { useRouter } from 'next/router'
import { Tooltip, Dropdown, Menu, Checkbox, Popover, notification, Modal, Table as AntTable, Button } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectSalesOrderIds, updateSalesOrderCounts, updateSalesOrders } from '../../../store/sales-orders/actions'
import Table from '../../table'
import moment from 'moment'
import { convertEnumToString, getFormattedCurrency, downloadArrayOfObjectsAsCSV } from '../../../utils'
import Link from 'next/link'
import { FileOutlined, ExportOutlined, UploadOutlined, PrinterOutlined, SwapOutlined, InfoCircleOutlined } from '@ant-design/icons'
import useSearchParams from '../../../hooks/useSearchParams'
import { SalesOrders } from '../../../services/api/sales-orders'
import SalesOrdersExportModal from '../../sales-orders-export-modal'
import { Industry } from '../../company-settings'
import { orderStatuses, paths, EXPORT_TYPE, InventoryEditions } from '../../../utils/constants'
import UploadOrdersModal from '../../upload-orders-modal'
import { LocationTypes } from '../../location-modal'
import HeaderTab from '../../header-tab'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import UpdateOrderStatusModal from '../../update-order-status-modal'
import PrintSalesOrderInvoicesModal from '../../print-sales-order-invoices-modal'
import PrintSalesOrderChallansModal from '../../print-sales-order-challans-modal'
import SubTab from '../../sub-tab'
import CopyToClipboard from '../../copy-to-clipboard'
import CustomEmptySecondary from '../../custom-empty-secondary'
import ReactToPrint from 'react-to-print'

const { Summary } = AntTable

const SALES_ORDER_CHALLANS_LOADED = 'sales-orders-loaded'

const SALES_ORDER_REQUESTED_STATUS = 'requested'
const SALES_ORDER_FLAGGED_STATUS = 'flagged'
const SALES_ORDER_APPROVED_STATUS = 'approved'
const SALES_ORDER_PROCESSING_STATUS = 'processing'
const SALES_ORDER_COMPLETED_STATUS = 'completed'
const SALES_ORDER_CANCELLED_STATUS = 'cancelled'

const SALES_ORDER_SHIPPED_SUB_STATUS = 'shipped'
const SALES_ORDER_IN_TRANSIT_SUB_STATUS = 'in_transit'
const SALES_ORDER_UNLOADED_SUB_STATUS = 'unloaded'
const SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS = 'payment_collected'
const SALES_ORDER_PAYMENT_DUE_SUB_STATUS = 'payment_due'
const SALES_ORDER_RETURNED_SUB_STATUS = 'returned'
const SALES_ORDER_DAMAGED_SUB_STATUS = 'damaged'
const SALES_ORDER_EXPIRED_SUB_STATUS = 'expired'

const PROCESSING_SUB_STATUSES = [
	SALES_ORDER_SHIPPED_SUB_STATUS,
	SALES_ORDER_IN_TRANSIT_SUB_STATUS,
	SALES_ORDER_UNLOADED_SUB_STATUS
]

const FLAGGED_SUB_STATUSES = [
	SALES_ORDER_RETURNED_SUB_STATUS,
	SALES_ORDER_DAMAGED_SUB_STATUS,
	SALES_ORDER_EXPIRED_SUB_STATUS
]

const COMPLETED_SUB_STATUSES = [
	SALES_ORDER_PAYMENT_DUE_SUB_STATUS,
	SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS
]

const tabs = [
	{
		title: 'All Orders',
		status: 'all',
		path: `${paths.SALES_ORDERS}?status=all`
	},
	{
		title: 'Requested',
		status: SALES_ORDER_REQUESTED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_REQUESTED_STATUS}`
	},
	{
		title: 'Flagged',
		status: SALES_ORDER_FLAGGED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_FLAGGED_STATUS}`
	},
	{
		title: 'Approved',
		status: SALES_ORDER_APPROVED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_APPROVED_STATUS}`
	},
	{
		title: 'Processing',
		status: SALES_ORDER_PROCESSING_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_PROCESSING_STATUS}`
	},
	{
		title: 'Delivered',
		status: SALES_ORDER_COMPLETED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_COMPLETED_STATUS}&subStatus=${SALES_ORDER_PAYMENT_DUE_SUB_STATUS}`
	},
	{
		title: 'Cancelled',
		status: SALES_ORDER_CANCELLED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_CANCELLED_STATUS}`
	}
]

const advancedTabs = [
	{
		title: 'All Orders',
		status: 'all',
		path: `${paths.SALES_ORDERS}?status=all`
	},
	{
		title: 'Requested',
		status: SALES_ORDER_REQUESTED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_REQUESTED_STATUS}`
	},
	{
		title: 'Approved',
		status: SALES_ORDER_APPROVED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_APPROVED_STATUS}`
	},
	{
		title: 'Processing',
		status: SALES_ORDER_PROCESSING_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_PROCESSING_STATUS}`
	},
	{
		title: 'Delivered',
		status: SALES_ORDER_COMPLETED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_COMPLETED_STATUS}&subStatus=${SALES_ORDER_PAYMENT_DUE_SUB_STATUS}`
	},
	{
		title: 'Flagged',
		status: SALES_ORDER_FLAGGED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_FLAGGED_STATUS}`
	},
	{
		title: 'Cancelled',
		status: SALES_ORDER_CANCELLED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_CANCELLED_STATUS}`
	}
]

const allOrdersStatus = 'all'

const invoiceTableColumns = [
	{
		title: 'SL',
		dataIndex: 'sl',
		key: 'sl',
		width: 70
	},
	{
		title: 'Invoice No',
		dataIndex: 'invoiceNo',
		key: 'invoiceNo',
		render: ({ invoiceNo, id }) => {
			return (
				<Link href={`${paths.SALES_ORDERS}/${id}`}>
					<a>{invoiceNo}</a>
				</Link>
			)
		}
	},
	{
		title: 'Requested Product QTY',
		dataIndex: 'requestedProductQty',
		key: 'requestedProductQty'
	}
]

const initializeColumnValues = (isChecked) => {
	return {
		isAllChecked: isChecked,
		creationDate: isChecked,
		deliveryDate: isChecked,
		orderDate: isChecked,
		salesOrderID: isChecked,
		areaManager: isChecked,
		to: isChecked,
		from: isChecked,
		salesAmount: isChecked,
		advanceAmount: isChecked,
		paymentMethod: isChecked,
		attachments: isChecked,
		status: isChecked
	}
}

const GeneralSalesOrdersPage = () => {
	const salesOrderColumns = [
		{
			title: 'Delivery Date',
			key: 'deliveryDate',
			render: salesOrder => {
				return (
					<div style={{
						display: 'flex',
						alignItems: 'center',
						minHeight: salesOrder.week ? 120 : 'auto'
					}}
					>
						{
							salesOrder.week &&
								<div style={{ display: 'flex', position: 'absolute', top: 0, marginTop: 12 }}>
									<div className={styles.weekHeading}>
										{getWeekHeading(salesOrder.week)}
									</div>
								</div>
						}
						{
							salesOrder.staleness >= 2 ?
								<Tooltip title={`Status has not updated in ${salesOrder.staleness} days.`}>
									<div className={styles.staleIndicator} />
								</Tooltip> : null
						}
						<div style={{ width: '100%' }}>
							{moment(salesOrder.deliveryDate).format('MMM D, YYYY')}
						</div>
					</div>
				)
			}
		},
		{
			title: 'Shipping Date',
			key: 'orderDate',
			render: salesOrder => {
				return moment(salesOrder.orderDate).format('MMM D, YYYY')
			}
		},
		{
			title: 'Creation Date',
			key: 'creationDate',
			render: salesOrder => {
				return moment(salesOrder.createdAt).format('MMM D, YYYY')
			}
		},
		{
			title: 'Order #',
			key: 'salesOrderID',
			render: salesOrder => {
				return (
					<div className={styles.salesOrderIdColumn}>
						<Link href={{
							pathname: `${paths.SALES_ORDERS}/${salesOrder.id}`,
							query: {
								status,
								subStatus
							}
						}}
						>
							<a className={styles.link}>{salesOrder.internalId}</a>
						</Link>
						<CopyToClipboard text={salesOrder.internalId} />
					</div>
				)
			}
		},
		{
			title: 'Area Manager',
			key: 'areaManager',
			render: salesOrder => {
				const isAssigned = salesOrder.distributor?.accountManager

				return (
					salesOrder.isStockTransfer ?
						<div className={styles.unassigned}>N/A</div> :
						salesOrder.distributor ?
							<Link href={`/customers/${salesOrder.distributor.id}`}>
								<a className={isAssigned ? undefined : styles.unassigned}>
									{salesOrder.distributor.accountManager?.name || 'Unassigned'}
								</a>
							</Link> :
							<p style={{ margin: 0 }} className={styles.unassigned}>Unassigned</p>
				)
			}
		},
		{
			title: 'Pick Up',
			key: 'from',
			render: salesOrder => {
				const {
					pickUpLocation
				} = salesOrder
				return (
					<div className={styles.locations}>
						{
							pickUpLocation &&
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div className={styles.badge}>
									{convertEnumToString(pickUpLocation.type.toLowerCase())}
								</div>
								{
									[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE, LocationTypes.FACTORY].includes(pickUpLocation.type) && !pickUpLocation.deletedAt ?
										<Link
											href={`${paths.WAREHOUSES}/${pickUpLocation.id}`}
										>
											<a>
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													{pickUpLocation.label}
													<div className={styles.location}>
														{`${pickUpLocation.address}${pickUpLocation.internalId ? ` (${pickUpLocation.internalId})` : ''}`}
													</div>
												</div>
											</a>
										</Link> :
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{pickUpLocation.label}
											<div className={styles.location}>
												{`${pickUpLocation.address}${pickUpLocation.internalId ? ` (${pickUpLocation.internalId})` : ''}`}
											</div>
										</div>
								}
							</div>
						}
					</div>
				)
			}
		},
		{
			title: 'Drop Off',
			key: 'to',
			width: 155,
			render: salesOrder => {
				const {
					distributor,
					location,
					isStockTransfer
				} = salesOrder
				return (
					<div className={styles.locations}>
						{
							distributor ?
								<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 6 }}>
									<div className={styles.badge} style={{ color: '#278EA5', background: '#ecf9f9' }}>
										{convertEnumToString(distributor.type.toLowerCase())}
									</div>
									<Link href={`${paths.CUSTOMERS}/${distributor.id}`}>
										<a style={{ color: '#278EA5' }}>
											<div>
												{`${distributor.name}${distributor.internalName ? ` (${distributor.internalName})` : ''}`}
												<div style={{ color: '#278EA5', fontSize: 12 }}>{distributor.internalId}</div>
											</div>
										</a>
									</Link>
									<div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
										<p style={{ fontSize: 12, margin: '4px 0 0 0', color: '#000' }}>
											{distributor.phone}
										</p>
										<CopyToClipboard text={distributor.phone} style={{ width: 20, height: 20 }} />
									</div>
								</div> : null
						}
						{
							location &&
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{
									!distributor &&
									<div className={styles.badge}>
										{convertEnumToString(location.type.toLowerCase())}
									</div>
								}
								{
									[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE, LocationTypes.FACTORY].includes(location.type) && !location.deletedAt ?
										<Link href={`${paths.WAREHOUSES}/${location.id}`}>
											<a>
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													{location.label}
													<div className={styles.location}>
														{`${location.address}${location.internalId ? ` (${location.internalId})` : ''}`}
													</div>
												</div>
											</a>
										</Link> :
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{location.label}
											<div className={styles.location}>
												{`${location.address}${location.internalId ? ` (${location.internalId})` : ''}`}
											</div>
										</div>
								}
								{
									isStockTransfer && location.contactPerson && location.phone &&
									<div>
										<p style={{ fontSize: 12, margin: '4px 0 0 0', color: '#000' }}>
											{location.contactPerson}
										</p>
										<div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
											<p style={{ fontSize: 12, margin: '4px 0 0 0', color: '#000' }}>
												{location.phone}
											</p>
											<CopyToClipboard text={location.phone} style={{ width: 20, height: 20 }} />
										</div>
									</div>
								}
							</div>
						}
					</div>
				)
			}
		},
		{
			title: 'Buying Amount',
			key: 'buyingAmount',
			render: salesOrder => {
				return `${getFormattedCurrency(salesOrder.totalBuyingAmount)}`
			}
		},
		{
			title: 'Sales Amount',
			key: 'salesAmount',
			render: salesOrder => {
				return `${getFormattedCurrency(salesOrder.totalAmount)}`
			}
		},
		{
			title: 'Advance Amount',
			key: 'advanceAmount',
			render: salesOrder => {
				return (
					salesOrder.isStockTransfer ?
						<div className={styles.unassigned}>N/A</div> :
							`${getFormattedCurrency(salesOrder.distributorAdvancePayment)}`
				)
			}
		},
		{
			title: 'Payment Method',
			key: 'paymentMethod',
			render: salesOrder => {
				return (
					salesOrder.isStockTransfer ?
						<div className={styles.unassigned}>N/A</div> :
							`${convertEnumToString(salesOrder.paymentMethod)}`
				)
			}
		},
		{
			title: 'Attachments',
			key: 'attachments',
			render: salesOrder => {
				return (
					<div className={styles.attachments}>
						{
							salesOrder.attachments && salesOrder.attachments.length > 0 ?
								salesOrder.attachments.map(attachment => {
									return (
										<Tooltip
											key={attachment.id}
											title={attachment.name}
										>
											<div
												onClick={() => SalesOrders.downloadAttachment(attachment.downloadUrl, attachment.name)}
												key={attachment.id}
												className={styles.attachment}
											>
												<FileOutlined />
											</div>
										</Tooltip>
									)
								}) :
								<div className={styles.unassigned}>None</div>
						}
					</div>
				)
			}
		},
		{
			title: 'Status',
			key: 'status',
			render: salesOrder => {
				return (
					<div className={styles.status}>
						{convertEnumToString(
							salesOrder.status.toLowerCase() === orderStatuses.SALES_ORDER_COMPLETED_STATUS ? orderStatuses.SALES_ORDER_DELIVERED_STATUS :
								salesOrder.subStatus ?
									salesOrder.subStatus.toLowerCase() === SALES_ORDER_UNLOADED_SUB_STATUS ? 'Arrived' :
										salesOrder.subStatus.toLowerCase() :
									salesOrder.status.toLowerCase()
						)}
					</div>
				)
			}
		}
	]

	const productListOrderTableColumns = [
		{
			title: 'Product ID/SKU',
			dataIndex: 'productId',
			key: 'productId',
			render: ({ productId, shortId }) => {
				return (
					<>
						<Link href={`${paths.PRODUCTS}/${productId}`}>
							<a>{shortId ? shortId : 'No ID'}</a>
						</Link>
						{
							!isLoadingAllRequestedOrders &&
							<Popover
								className={styles.invoicePopover}
								overlayClassName='item-details-popover'
								placement='rightTop'
								arrowPointAtCenter
								trigger={['hover', 'click']}
								content={renderInvoiceDetails(productId)}
							>
								<InfoCircleOutlined
									style={{ fontSize: 16, marginLeft: 30, cursor: 'pointer' }}
									className={styles.pickingProductInfoIcon}
								/>
							</Popover>
						}
					</>
				)
			}
		},
		{
			title: 'Product Name',
			dataIndex: 'productName',
			key: 'productName'
		},
		{
			title: 'Requested QTY',
			dataIndex: 'requestedQty',
			key: 'requestedQty'
		}
	]

	const router = useRouter()
	const { status, subStatus } = router.query
	const dispatch = useDispatch()
	const {
		totalCount,
		page,
		allSalesOrders,
		requestedSalesOrders,
		flaggedSalesOrders,
		approvedSalesOrders,
		processingSalesOrders,
		completedSalesOrders,
		cancelledSalesOrders,
		salesOrderCounts,
		allSelectedSalesOrderIds,
		selectedSalesOrderIds: statusSelectedSalesOrderIds
	} = useSelector(state => state.salesOrdersReducer)
	const { searchParams, isSearchApplied } = useSearchParams()
	const { companyDetails, permissions, enabledOrderStatuses } = useSelector(state => state.authReducer)
	const [allRequestedOrders, setAllRequestedOrders] = useState([])
	const [salesOrderWeeklyGroupings, setSalesOrderWeeklyGroupings] = useState([])
	const [filteredColumns, setFilteredColumns] = useLocalStorage('filteredColumns', initializeColumnValues(true))
	const [exportType, setExportType] = useState()
	const [salesOrderChallansUrl, setSalesOrderChallansUrl] = useState()
	const [currentStatus, setCurrentStatus] = useState(null)
	const [pageSize, setPageSize] = useState(50)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingAllRequestedOrders, setIsLoadingAllRequestedOrders] = useState(false)
	const [isProductListModalVisible, setIsProductListModalVisible] = useState(false)
	const [isSalesOrdersExportModalVisible, setIsSalesOrdersExportModalVisible] = useState(false)
	const [isUploadOrdersModalVisible, setIsUploadOrdersModalVisible] = useState(false)
	const [isPrintSalesOrderInvoicesModalVisible, setIsPrintSalesOrderInvoicesModalVisible] = useState(false)
	const [isPrintSalesOrderChallansModalVisible, setIsPrintSalesOrderChallansModalVisible] = useState(false)
	const [isFilterControlVisible, setIsFilterControlVisible] = useState(false)
	const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] = useState(false)
	const [isPrintingChallans, setIsPrintingChallans] = useState(false)
	const [isPrintingProductListTable, setIsPrintingProductListTable] = useState(false)
	const [isTableDataEmpty, setIsTableDataEmpty] = useState(false)
	const [isDownloadingSummary, setIsDownloadingSummary] = useState(false)
	const [isLoadingCounts, setIsLoadingCounts] = useState(false)
	const { orderStatusFlow, inventoryEdition } = useMemo(() => {
		return {
			inventoryEdition: companyDetails?.inventoryEdition,
			orderStatusFlow: companyDetails?.orderStatusFlow
		}
	}, [companyDetails])
	const currentSalesOrderColumns = useMemo(() => {
		return salesOrderColumns
			.filter(column => {
				if (column.key === 'status') {
					if (currentStatus === SALES_ORDER_PROCESSING_STATUS || currentStatus === undefined) {
						return true
					} else {
						return false
					}
				} else if (column.key === 'buyingAmount') {
					if (companyDetails && companyDetails.industry && companyDetails.industry.toLowerCase() === Industry.AGRICULTURE) {
						return true
					} else {
						return false
					}
				} else {
					return true
				}
			})
			.filter(column => {
				if (filteredColumns) {
					return filteredColumns[column.key]
				} else {
					return false
				}
			})
	}, [currentStatus, subStatus, companyDetails, filteredColumns])
	const modifiedTabs = useMemo(() => {
		const isAdvancedInventory = inventoryEdition === InventoryEditions.ADVANCED
		let modifiedTabs = isAdvancedInventory ? [...advancedTabs] : [...tabs]
		if (isAdvancedInventory) {
			modifiedTabs = modifiedTabs.map((tab) => {
				const modifiedTab = { ...tab }
				if (modifiedTab.status === SALES_ORDER_FLAGGED_STATUS) {
					modifiedTab.path = `${paths.SALES_ORDERS}?status=${SALES_ORDER_FLAGGED_STATUS}&subStatus=${SALES_ORDER_RETURNED_SUB_STATUS}`
				} else if (modifiedTab.status === SALES_ORDER_PROCESSING_STATUS) {
					modifiedTab.path = `${paths.SALES_ORDERS}?status=${SALES_ORDER_PROCESSING_STATUS}&subStatus=${SALES_ORDER_SHIPPED_SUB_STATUS}`
				}
				return modifiedTab
			})
		}
		if (orderStatusFlow) {
			return modifiedTabs.filter(tab => orderStatusFlow.statuses.includes(tab.status.toUpperCase()) || tab.status === allOrdersStatus)
		} else {
			return modifiedTabs
		}
	}, [orderStatusFlow, inventoryEdition])
	const productListTableRef = useRef(null)
	const printRef = useRef(null)
	const selectedSalesOrderIds = currentStatus === allOrdersStatus ? allSelectedSalesOrderIds : statusSelectedSalesOrderIds

	useEffect(() => {
		if (!permissions.viewOrders) {
			return
		}
		if (!status) {
			return router.push(`${paths.SALES_ORDERS}?status=${companyDetails?.orderStatusFlow?.defaultStatus.toLowerCase()}`)
		}
		let salesOrderStatus = undefined
		switch (status) {
			case SALES_ORDER_REQUESTED_STATUS:
				salesOrderStatus = SALES_ORDER_REQUESTED_STATUS
				break
			case SALES_ORDER_FLAGGED_STATUS:
				salesOrderStatus = SALES_ORDER_FLAGGED_STATUS
				break
			case SALES_ORDER_APPROVED_STATUS:
				salesOrderStatus = SALES_ORDER_APPROVED_STATUS
				break
			case SALES_ORDER_PROCESSING_STATUS:
				salesOrderStatus = SALES_ORDER_PROCESSING_STATUS
				break
			case SALES_ORDER_COMPLETED_STATUS:
				salesOrderStatus = SALES_ORDER_COMPLETED_STATUS
				break
			case SALES_ORDER_CANCELLED_STATUS:
				salesOrderStatus = SALES_ORDER_CANCELLED_STATUS
				break
			default:
				salesOrderStatus = undefined
				break
		}
		setCurrentStatus(salesOrderStatus)
		if (currentStatus !== null && currentStatus !== salesOrderStatus) {
			dispatch(selectSalesOrderIds([], []))
		}
		getSalesOrders({
			status: salesOrderStatus,
			subStatus,
			page: 0,
			...searchParams
		})
	}, [
		status,
		subStatus,
		searchParams,
		pageSize
	])

	useEffect(() => {
		let salesOrders = allSalesOrders
		switch (status) {
			case SALES_ORDER_REQUESTED_STATUS:
				salesOrders = requestedSalesOrders
				break
			case SALES_ORDER_FLAGGED_STATUS:
				salesOrders = flaggedSalesOrders
				break
			case SALES_ORDER_APPROVED_STATUS:
				salesOrders = approvedSalesOrders
				break
			case SALES_ORDER_PROCESSING_STATUS:
				salesOrders = processingSalesOrders
				break
			case SALES_ORDER_COMPLETED_STATUS:
				salesOrders = completedSalesOrders
				break
			case SALES_ORDER_CANCELLED_STATUS:
				salesOrders = cancelledSalesOrders
				break
			default:
				salesOrders = allSalesOrders
				break
		}
		setSalesOrderWeeklyGroupings(getSalesOrderGroupings(salesOrders))
	}, [
		allSalesOrders,
		requestedSalesOrders,
		flaggedSalesOrders,
		approvedSalesOrders,
		processingSalesOrders,
		completedSalesOrders,
		cancelledSalesOrders
	])

	useEffect(() => {
		window.addEventListener('message', handleMessage)
		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [])

	const isAllColumnChecked = (filteredColumns) => {
		return Object.values(filteredColumns).every((value) => value === true)
	}

	const getSalesOrders = async ({ status, subStatus, isStockTransfer, page }) => {
		try {
			setIsLoading(true)
			const { data } = await SalesOrders.index({
				status,
				subStatus,
				page,
				pageSize,
				isStockTransfer,
				...searchParams
			})
			dispatch(updateSalesOrders(data, status))
			getSalesOrderCounts()
			setIsLoading(false)
		} catch (e) {
			notification.error({
				message: 'Unable to get orders',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const getSalesOrderCounts = async () => {
		setIsLoadingCounts(true)
		const { data } = await SalesOrders.aggregates({
			...searchParams
		})
		const salesOrderCounts = {
			[allOrdersStatus]: 0
		}
		const salesAmounts = {}
		for (const count of data) {
			const status = count.status?.toLowerCase()
			const subStatus = count.subStatus?.toLowerCase()
			const countIncrement = count._count.id
			const totalAmountIncrement = +count._sum.totalAmount

			salesOrderCounts[allOrdersStatus] = salesOrderCounts[allOrdersStatus] + countIncrement

			if (status) {
				salesOrderCounts[status] = salesOrderCounts[status] ? salesOrderCounts[status] + countIncrement : countIncrement
				salesAmounts[status] = salesAmounts[status] ? salesAmounts[status] + totalAmountIncrement : totalAmountIncrement
			}
			if (subStatus) {
				const key = `${status}_${subStatus}`
				salesOrderCounts[key] = salesOrderCounts[key] ? salesOrderCounts[key] + countIncrement : countIncrement
				salesAmounts[key] = salesAmounts[key] ? salesAmounts[key] + totalAmountIncrement : totalAmountIncrement
			}
		}
		dispatch(updateSalesOrderCounts(salesOrderCounts))
		setIsLoadingCounts(false)
	}

	const onPageChanged = async (page) => {
		let pageNumber = page
		const totalPage = Math.ceil(salesOrderCounts[currentStatus] / pageSize)
		if (page > totalPage && salesOrderWeeklyGroupings.length === selectedSalesOrderIds.length) {
			pageNumber = page - 2
		} else if (page === totalPage - 1 && salesOrderWeeklyGroupings.length === selectedSalesOrderIds.length) {
			pageNumber = page - 1
		} else if (salesOrderWeeklyGroupings.length === 0 && salesOrderWeeklyGroupings.length === selectedSalesOrderIds.length) {
			pageNumber = page - 2
		} else {
			pageNumber = page - 1
		}
		await getSalesOrders({
			status: currentStatus,
			subStatus,
			page: pageNumber,
			...searchParams
		})
	}

	const onSubStatusSelected = async (subStatus) => {
		dispatch(selectSalesOrderIds([], []))
		router.push(`?status=${status}&subStatus=${subStatus}`)
	}

	const onTabClicked = (tab) => {
		router.push(tab.path)
	}

	const getAllRequestedOrders = async () => {
		try {
			setIsLoadingAllRequestedOrders(true)
			const { data: { results } } = await SalesOrders.index({
				status: orderStatuses.SALES_ORDER_REQUESTED_STATUS,
				page: -1,
				salesOrderItemsWithProduct: true
			})
			setAllRequestedOrders(results)
		} catch (e) {
			notification.error({
				message: 'Unable to get picking orders',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingAllRequestedOrders(false)
		}
	}

	const onCountChange = (value) => {
		setPageSize(value)
	}

	const onExportCSV = () => {
		setExportType(EXPORT_TYPE.CSV)
		setIsSalesOrdersExportModalVisible(true)
	}

	const onExportPDF = () => {
		setExportType(EXPORT_TYPE.PDF)
		setIsSalesOrdersExportModalVisible(true)
	}

	const onUploadOrder = () => {
		setIsUploadOrdersModalVisible(true)
	}

	const onUpdateOrderStatuses = () => {
		setIsUpdateStatusModalVisible(true)
	}

	const onPrintOrderChallans = async () => {
		setIsPrintSalesOrderChallansModalVisible(true)
	}

	const onPrintOrderInvoices = () => {
		setIsPrintSalesOrderInvoicesModalVisible(true)
	}

	const handleMessage = (event) => {
		if (event.data.action === SALES_ORDER_CHALLANS_LOADED) {
			const iframe = document.frames ? document.frames['sales-orders'] : document.getElementById('sales-orders')
			const iframeWindow = iframe.contentWindow || iframe
			iframe.focus()
			iframeWindow.print()
			setSalesOrderChallansUrl(null)
			setIsPrintingChallans(false)
		}
	}

	const onSelectChange = (selectedRowKeys, selectedRows) => {
		dispatch(selectSalesOrderIds(selectedRowKeys, selectedRows))
	}

	const onUpdateOrderStatus = async (updatedSalesOrderIds = selectedSalesOrderIds) => {
		setIsUpdateStatusModalVisible(false)
		await getSalesOrderCounts()
		setSalesOrderWeeklyGroupings(prevData => {
			const updatedSalesOrderWeeklyGroupings = prevData.filter(({ id }) => !updatedSalesOrderIds.includes(id))
			if (updatedSalesOrderWeeklyGroupings.length === 0) {
				setIsTableDataEmpty(true)
			}
			return getSalesOrderGroupings(updatedSalesOrderWeeklyGroupings)
		})
		dispatch(selectSalesOrderIds([], []))
	}

	const getSalesOrderGroupings = (salesOrders) => {
		// Sales orders are sorted by delivery date by default.
		const salesOrderWeeklyGroupings = []
		let sameKey = undefined
		salesOrders.forEach(salesOrder => {
			const date = moment(salesOrder.deliveryDate)
			const yearWeekKey = `${date.startOf('isoWeek').year()}-${date.startOf('isoWeek').isoWeek()}`
			if (sameKey !== yearWeekKey) {
				salesOrderWeeklyGroupings.push({
					week: yearWeekKey,
					...salesOrder
				})
				sameKey = yearWeekKey
			} else {
				salesOrderWeeklyGroupings.push(salesOrder)
			}
		})
		return salesOrderWeeklyGroupings
	}

	const getWeekHeading = (dateKey) => {
		const currentDate = new Date()
		const currentYear = moment(currentDate).year()
		const currentWeek = moment(currentDate).isoWeek()
		const [year, week] = dateKey.split('-').map(val => +val)
		const date = moment().year(year).isoWeek(week)
		if (currentYear === year && currentWeek === week) {
			return 'This Week'
		} else if (currentYear === year && currentWeek + 1 === week) {
			return 'Next Week'
		} else if (currentYear === year && currentWeek - 1 === week) {
			return 'Last Week'
		} else {
			return `${date.startOf('isoWeek').format('MMM Do')} - ${date.endOf('isoWeek').format('MMM Do')}`
		}
	}

	const subStatusOptions = useMemo(() => {
		switch (status) {
			case SALES_ORDER_PROCESSING_STATUS:
				return PROCESSING_SUB_STATUSES
			case SALES_ORDER_COMPLETED_STATUS:
				return COMPLETED_SUB_STATUSES
			case SALES_ORDER_FLAGGED_STATUS:
				return inventoryEdition === InventoryEditions.ADVANCED ? FLAGGED_SUB_STATUSES.filter(subStatus => enabledOrderStatuses[subStatus]) : []
			default:
				return []
		}
	}, [status, inventoryEdition])

	const isStatusChangeDisabled = useMemo(() => {
		let disabled = false
		if (inventoryEdition === InventoryEditions.ADVANCED && [SALES_ORDER_DAMAGED_SUB_STATUS, SALES_ORDER_EXPIRED_SUB_STATUS].includes(subStatus)) {
			disabled = true
		}
		return !permissions.editOrdersStatus || selectedSalesOrderIds.length === 0 || disabled
	}, [permissions?.editOrdersStatus, selectedSalesOrderIds, inventoryEdition, subStatus])

	const getProductListTableData = () => {
		const lineItems = allRequestedOrders.reduce((acc, { salesOrderItems }) => acc.concat(salesOrderItems), [])
		const uniqueItemIds = [...new Set(lineItems.map(({ productId }) => productId))]
		const tableData = uniqueItemIds.map((id) => {
			const { productId, product: { name, sku } } = lineItems.find((item) => item.productId === id)
			const requestedQty = lineItems.reduce((acc, { productId, quantity, packageQuantity, packaging }) =>
				id === productId
					? acc + (((packageQuantity || 0) * (packaging?.size || 0)) + (quantity || 0))
					: acc
			, 0).toFixed(2)

			return {
				key: id,
				productId: { productId, shortId: sku },
				productName: name,
				requestedQty
			}
		})

		return tableData
	}

	const getInvoiceTableData = (productId) => {
		const includedOrders = allRequestedOrders.filter(({ salesOrderItems }) => salesOrderItems.some((item) => item.productId === productId))
		const tableData = includedOrders.map((order, i) => {
			const requestedProductQty = order.salesOrderItems.find((item) => item.productId === productId).quantity

			return {
				sl: i + 1,
				invoiceNo: { invoiceNo: order.internalId, id: order.id },
				deliveryDate: moment(order.deliveryDate).format('MMM D, YYYY'),
				requestedProductQty
			}
		})

		return tableData
	}

	const onProductListExport = () => {
		const productList = getProductListTableData()
		const csvData = productList.map(({ productId, productName, requestedQty }) => ({
			'Product ID': productId.shortId || '',
			'Product Name': productName || '',
			'Requested Qty': Number(requestedQty || 0)
		}))
		downloadArrayOfObjectsAsCSV(csvData, 'product_list', { includeDateInFileName: true })
	}

	const renderInvoiceDetails = (productId) => {
		const dataSource = getInvoiceTableData(productId)
		const totalQty = dataSource.reduce((acc, { requestedProductQty }) => acc + requestedProductQty, 0)

		return (
			<>
				<p className={styles.invoiceNo}>Invoice Information</p>
				<Table
					pagination={false}
					scroll={{ y: '275px' }}
					columns={invoiceTableColumns}
					dataSource={dataSource}
					summary={() => {
						return (
							<Summary.Row className={`${styles.summaryRow}`}>
								<Summary.Cell />
								<Summary.Cell className={styles.invoicesTablesTotalColumn}>Total</Summary.Cell>
								<Summary.Cell>{totalQty.toFixed(2)}</Summary.Cell>
							</Summary.Row>
						)
					}}
				/>
			</>
		)
	}

	const renderActionMenu = () => {
		return (
			<Menu>
				<Menu.Item
					icon={<ExportOutlined />}
					onClick={onExportCSV}
					key='0'
					disabled={!permissions.exportOrders}
				>
					Export As CSV
				</Menu.Item>
				<Menu.Item
					icon={<ExportOutlined />}
					onClick={() => {
						setIsDownloadingSummary(true)
						onExportCSV()
					}}
					key='0'
					disabled={!permissions.exportOrders}
				>
					Export Summary As CSV
				</Menu.Item>
				<Menu.Item
					icon={<ExportOutlined />}
					onClick={onExportPDF}
					key='1'
					disabled={!permissions.exportOrders}
				>
					Export As PDF
				</Menu.Item>
				<Menu.Item
					icon={<UploadOutlined />}
					onClick={onUploadOrder}
					key='2'
					disabled={!permissions.uploadOrders}
				>
					Upload Orders
				</Menu.Item>
				{
					inventoryEdition === InventoryEditions.ADVANCED && status === SALES_ORDER_REQUESTED_STATUS ? null :
						<Menu.Item
							icon={<SwapOutlined />}
							onClick={onUpdateOrderStatuses}
							key='3'
							disabled={isStatusChangeDisabled}
						>
							Change Status
						</Menu.Item>
				}
				{
					companyDetails?.documentTemplates === undefined || companyDetails?.documentTemplates?.isChallanEnabled === true &&
					<Menu.Item
						icon={<PrinterOutlined />}
						onClick={onPrintOrderChallans}
						key='4'
						disabled={selectedSalesOrderIds.length === 0 || isPrintingChallans}
					>
						Print Challan
					</Menu.Item>
				}
				{
					companyDetails?.documentTemplates === undefined || companyDetails?.documentTemplates?.isInvoiceEnabled === true &&
					<Menu.Item
						icon={<PrinterOutlined />}
						onClick={onPrintOrderInvoices}
						key='5'
						disabled={selectedSalesOrderIds.length === 0 || isPrintingChallans}
					>
						Print Invoice
					</Menu.Item>
				}
			</Menu>
		)
	}

	const renderCreateMenu = () => {
		return (
			<Menu>
				<Menu.Item
					key='0'
					disabled={!permissions.addOrders}
				>
					<Link href='/create-sales-order'>
						Create Sales Order
					</Link>
				</Menu.Item>
				<Menu.Item
					key='1'
					disabled={!permissions.addOrders}
				>
					<Link href='/create-sales-order?stockTransfer=true'>
						Create Stock Transfer Order
					</Link>
				</Menu.Item>
			</Menu>
		)
	}

	const renderProductListTable = () => {
		return (
			<Table
				loading={isLoadingAllRequestedOrders}
				refresh={getAllRequestedOrders}
				columns={productListOrderTableColumns}
				dataSource={getProductListTableData()}
				pagination={false}
				scroll={{ y: 300 }}
				title={() => <span style={{ opacity:0, userSelect: 'none' }}>.</span>}
				headerChildren={
					<>
						<Button
							type='primary'
							loading={isPrintingProductListTable}
							onClick={() => {
								setIsPrintingProductListTable(true)
								printRef.current.handleClick()
							}}
						>
							Print
						</Button>
						<Button
							icon={<ExportOutlined />}
							onClick={onProductListExport}
						>
							Export As CSV
						</Button>
					</>
				}
			/>
		)
	}

	const renderProductListModalContent = () => {
		return (
			<div className={`${styles.productListModal} ant-modal-content`}>
				<div className={styles.modalTitleContainer}>
					<p className={styles.modalTitle}>Product List</p>
					<svg className={styles.closeIcon} onClick={() => setIsProductListModalVisible(false)}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div
					className={`${styles.modalContent} ${styles.productListTableContainer}`}
					ref={productListTableRef}
				>
					<p className={styles.printTitle}>Products List</p>
					<div className={styles.productListTables}>
						{renderProductListTable()}
						{renderProductListTable()}
					</div>
					<ReactToPrint
						ref={printRef}
						content={() => productListTableRef.current}
						onAfterPrint={() => setIsPrintingProductListTable(false)}
					/>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.contentHeader}>
					<div style={{ display: 'flex' }}>
						<div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
							<div className={styles.contentHeaderContainer}>
								<Dropdown overlay={renderActionMenu()} trigger={['click']}>
									<Button
										loading={isPrintingChallans}
										onClick={() => {}}
									>
										Action
									</Button>
								</Dropdown>
								<Dropdown overlay={renderCreateMenu()} trigger={['click']}>
									<Button
										type='primary'
										className={styles.actionButton}
									>
										Create Order
									</Button>
								</Dropdown>
							</div>
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<div className={styles.tabContainer}>
							{
								modifiedTabs.map(tab => {
									return (
										<HeaderTab
											key={tab.path}
											title={tab.title}
											count={permissions.viewOrdersCount ? salesOrderCounts[tab.status] || 0 : 0}
											isSelected={() => tab.status === status}
											onClick={() => onTabClicked(tab)}
											loading={isLoadingCounts}
										/>
									)
								})
							}
						</div>
					</div>
					{
						subStatusOptions.length > 0 ?
							<div style={{ display: 'flex', gap: 8 }}>
								{
									subStatusOptions.map(currentSubStatus => {
										const isActive = currentSubStatus === subStatus
										return (
											<SubTab
												key={currentSubStatus}
												active={isActive}
												count={permissions.viewOrdersCount ? salesOrderCounts[`${status}_${currentSubStatus}`] || 0 : 0}
												onClick={() => onSubStatusSelected(currentSubStatus)}
												isLoading={isLoadingCounts}
											>
												{currentSubStatus === SALES_ORDER_UNLOADED_SUB_STATUS ? 'Arrived' : convertEnumToString(currentSubStatus)}
											</SubTab>
										)
									})
								}
							</div> : null
					}
				</div>
				{
					selectedSalesOrderIds.length > 0 &&
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
						<div>
							{`You have selected ${selectedSalesOrderIds.length} order(s).`}
							<span
								onClick={() => dispatch(selectSalesOrderIds([], []))}
								style={{ color: '#f5222d', fontWeight: 'bold', marginLeft: 6, cursor: 'pointer' }}
							>
								Clear all selections
							</span>
						</div>
					</div>
				}
				<div className={styles.content}>
					<Table
						refresh={() => onPageChanged(page + 1)}
						loading={isLoading}
						columns={currentSalesOrderColumns}
						dataSource={salesOrderWeeklyGroupings}
						isDataSourceEmpty={isTableDataEmpty}
						pagination={{
							pageSize,
							total: totalCount,
							position: ['topRight'],
							onChange: onPageChanged,
							showSizeChanger: false,
							current: page + 1
						}}
						onPageSizeChange={(size) => onCountChange(size)}
						scroll={{
							y: subStatusOptions.length > 0 && selectedSalesOrderIds.length > 0 ? 'calc(100vh - 425px)' :
								selectedSalesOrderIds.length > 0 ? 'calc(100vh - 370px)' :
									subStatusOptions.length > 0 ? 'calc(100vh - 395px)' :
										'calc(100vh - 340px)'
						}}
						rowKey='id'
						rowSelection={{
							preserveSelectedRowKeys: true,
							selectedRowKeys: selectedSalesOrderIds,
							onChange: onSelectChange
						}}
						empty={isSearchApplied ? <CustomEmptySecondary showFallbackAction /> : null}
						headerChildren={
							<div>
								{
									status === orderStatuses.SALES_ORDER_REQUESTED_STATUS ?
										<Button
											type='primary'
											onClick={() => {
												setIsProductListModalVisible(true)
												getAllRequestedOrders()
											}}
										>
											Product List
										</Button> :
										null
								}
							</div>
						}
						filter={true}
						filterTrigger='click'
						filterPlacement='bottomLeft'
						filterVisible={isFilterControlVisible}
						onFilterVisibleChange={setIsFilterControlVisible}
						filterContent={
							<div className={styles.filterOptions}>
								<div className={styles.filterOption}>
									<Checkbox
										checked={isAllColumnChecked(filteredColumns)}
										value='isAllChecked'
										onChange={(e) => {
											if (e.target.checked) {
												setFilteredColumns(initializeColumnValues(true))
											} else {
												setFilteredColumns(initializeColumnValues(false))
											}
										}}
									>
										Select All
									</Checkbox>
								</div>
								{
									salesOrderColumns
										.filter(({ key }) => {
											if (key === 'status') {
												if (currentStatus === SALES_ORDER_PROCESSING_STATUS || currentStatus === undefined) {
													return true
												} else {
													return false
												}
											} else {
												return true
											}
										})
										.map(column => {
											if (column.key !== 'buyingAmount') {
												return (
													<div key={column.key} className={styles.filterOption}>
														<Checkbox
															checked={filteredColumns[column.key]}
															value={column.key}
															onChange={(e) => setFilteredColumns({ ...filteredColumns, [column.key]: e.target.checked })}
														>
															{column.title}
														</Checkbox>
													</div>
												)
											}
										})
								}
							</div>
						}
					/>
				</div>
			</div>
			{
				isSalesOrdersExportModalVisible ?
					<SalesOrdersExportModal
						filteredColumns={filteredColumns}
						exportType={exportType}
						onCancel={() => {
							setIsSalesOrdersExportModalVisible(false)
							setIsDownloadingSummary(false)
						}}
						visible={isSalesOrdersExportModalVisible}
						isDownloadingSummary={isDownloadingSummary}
					/> : null
			}
			{
				isUploadOrdersModalVisible &&
					<UploadOrdersModal
						visible={isUploadOrdersModalVisible}
						onCancel={() => setIsUploadOrdersModalVisible(false)}
						onComplete={async () => {
							await getSalesOrders({
								status: currentStatus,
								subStatus,
								page: page
							})
						}}
					/>
			}
			{
				isUpdateStatusModalVisible &&
					<UpdateOrderStatusModal
						visible={isUpdateStatusModalVisible}
						onCancel={() => setIsUpdateStatusModalVisible(false)}
						onComplete={async (updatedSalesOrderIds) => {
							onUpdateOrderStatus(updatedSalesOrderIds)
							await getSalesOrders({
								status: currentStatus,
								subStatus,
								page: page
							})
						}}
						currentStatus={currentStatus}
						currentSubStatus={subStatus}
						salesOrderIds={selectedSalesOrderIds}
					/>
			}
			{
				isPrintSalesOrderInvoicesModalVisible &&
					<PrintSalesOrderInvoicesModal
						visible={isPrintSalesOrderInvoicesModalVisible}
						salesOrderIds={selectedSalesOrderIds}
						onCancel={() => setIsPrintSalesOrderInvoicesModalVisible(false)}
					/>
			}
			{
				isPrintSalesOrderChallansModalVisible &&
					<PrintSalesOrderChallansModal
						visible={isPrintSalesOrderChallansModalVisible}
						salesOrderIds={selectedSalesOrderIds}
						onCancel={() => setIsPrintSalesOrderChallansModalVisible(false)}
					/>
			}
			<Modal
				width={1000}
				visible={isProductListModalVisible}
				destroyOnClose={true}
				modalRender={renderProductListModalContent}
			/>
			{
				salesOrderChallansUrl &&
				<iframe
					id='sales-orders'
					style={{ display: 'none' }}
					src={salesOrderChallansUrl}
				/>
			}
		</>
	)
}

export default GeneralSalesOrdersPage
