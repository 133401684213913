import styles from './DropdownOverlay.module.css'
import PropTypes from 'prop-types'

const DropdownOverlay = ({ children, className }) => {
	return (
		<div className={`${styles.overlay} ${className}`}>
			{children}
		</div>
	)
}

DropdownOverlay.propTypes = {
	className: PropTypes.string
}

export default DropdownOverlay
