import { Button, Modal, notification, Progress } from 'antd'
import styles from './UploadCsvModal.module.css'
require('./UploadCsvModal.less')
import { DownloadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Tasks } from '../../services/api/firebase'
import { useSelector } from 'react-redux'
import { useSpace } from '@flatfile/react'
import { isDev } from '../../config'
import { Upload } from '../../services/api/upload'
import { FlatfileListener } from '@flatfile/listener'
import api from '@flatfile/api'
import { recordHook } from '@flatfile/plugin-record-hook'
import { getWorkbook } from '../../utils/workbook'

const spaceProps = {
	name: 'Embedded Space',
	publishableKey: process.env.NEXT_PUBLIC_FLAT_FILE_PUBLISHABLE_KEY,
	environmentId: process.env.NEXT_PUBLIC_FLAT_FILE_ENVIRONMENT_KEY
}

const UploadCsvModal = ({
	title,
	type,
	fields = [],
	firstStepPrompt,
	secondStepPrompt,
	visible,
	loading = false,
	onCancel,
	downloadTask,
	uploadTask,
	onComplete,
	allowCustom = false,
	importOnly = false // Flag indicates we do not proceed with uploading the data.
}) => {
	const { userProfile } = useSelector(state => state.authReducer)
	const [isDownloadingCsv, setIsDownloadingCsv] = useState(false)
	const [file, setFile] = useState()
	const [importedValues, setImportedValues] = useState([])
	const [isUploadingCsv, setIsUploadingCsv] = useState(false)
	const [logs, setLogs] = useState([])
	const [taskId, setTaskId] = useState()
	const [progress, setProgress] = useState(0)
	const [status, setStatus] = useState()
	const [isDownloadingErrorCsv, setIsDownloadingErrorCsv] = useState(false)
	const [showSpace, setShowSpace] = useState(false)
	const [isSpaceLoading, setIsSpaceLoading] = useState(false)

	useEffect(() => {
		if (taskId) {
			const unsubscribeTask = Tasks.listenToTask(
				userProfile.companyId,
				taskId,
				task => {
					const count = task.processedRows || 0
					const length = task.length || 1
					const progress = count / length * 100
					setProgress(progress)
					if (progress >= 100) {
						setStatus('completed')
						unsubscribeTask()
						if (task.logs) {
							setLogs(logs => [...logs, ...task.logs])
						}
					} else {
						if (task.loadState === 'FAILED') {
							setStatus('exception')
						}
						if (task.logs) {
							setLogs(logs => [...logs, ...task.logs])
						}
					}
				}
			)
			return () => {
				unsubscribeTask()
			}
		}
	}, [taskId])

	const downloadCsv = async () => {
		setIsDownloadingCsv(true)
		if (downloadTask) {
			await downloadTask()
		}
		setIsDownloadingCsv(false)
	}

	const onDownloadErrorRows = async () => {
		setIsDownloadingErrorCsv(true)
		await Upload.downloadTaskErrors(taskId)
		setIsDownloadingErrorCsv(false)
	}

	const onUploadFile = async () => {
		try {
			if (importOnly) {
				if (onComplete) {
					onComplete(importedValues)
				}
			} else {
				setIsUploadingCsv(true)
				setLogs([])
				if (uploadTask) {
					const response = await uploadTask(file)
					const { taskId, errorMessages } = response.data
					setLogs(errorMessages)
					if (errorMessages.length === 0) {
						setTaskId(taskId)
						setStatus('active')
					}
				}
			}
		} catch (e) {
			setLogs([])
			notification.error({
				message: 'File Upload Failed',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUploadingCsv(false)
		}
	}

	const onOk = () => {
		if (onComplete) {
			onComplete()
		}
		onCancel()
	}

	const parseResults = (results) => {
		const { fileName, validData } = results
		return new Promise(resolve => {
			const data = [].concat.apply([], validData)
			if (data.length > 0) {
				const headerRow = data[0]
				let header = Object.keys(headerRow)
				if (headerRow.$custom) {
					header = [...header, ...Object.keys(headerRow.$custom)].filter(key => key !== '$custom')
				}
				const csv = [
					header.join(','),
					...data.map(row => {
						if (row.$custom) {
							const custom = { ...row.$custom }
							delete row.$custom
							row = {
								...custom,
								...row
							}
						}
						return header
							.map(fieldName => {
								let value = row[fieldName]
								if (value === null || value === undefined) {
									value = ''
								} else if (typeof value !== 'string') {
									value = String(value)
								}
								if (value.includes(',') || value.includes('\n') || value.includes('\r') || value.includes('"')) {
									return `"${value.replace(/"/g, '""')}"`
								} else {
									return value
								}
							})
							.join(',')
					})
				].join('\r\n')
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
				const file = new File([blob], fileName, { type: 'text/csv' })
				resolve(file)
			} else {
				resolve()
			}
		})
	}

	const isLoading = isUploadingCsv || isSpaceLoading || (taskId !== undefined && status === 'active')
	const isFinished = taskId !== undefined && status !== 'active'

	const listener = FlatfileListener.create((listener) => {
		listener.use(
			recordHook(type.slug, (record) => {
				return record
			})
		)

		listener.filter({ job: 'workbook:submitActionFg' }, (config) => {
			config.on('job:ready', async ({ context: { jobId, workbookId } }) => {
				try {
					await api.jobs.ack(jobId, {
						info: 'Getting started.',
						progress: 10
					})
					const { data: workbookSheets } = await api.sheets.list({ workbookId })
					const sheets = []
					const errors = []
					for (const sheet of workbookSheets) {
						const { data: records } = await api.records.get(sheet.id)
						let index = 0
						for (const record of records.records) {
							const values = record.values
							const recordErrors = []
							let data = {}
							for (const [key, value] of Object.entries(values)) {
								if (!value.valid) {
									recordErrors.push(value.messages[0].message)
								} else {
									data[key] = value.value
								}
							}
							sheets.push(data)
							if (recordErrors.length > 0) {
								errors.push(`Error(s) occurred for row ${index + 1}: ${recordErrors.join(', ')}`)
							}
							index++
						}
					}
					if (errors.length > 0) {
						await api.jobs.fail(jobId, {
							error: errors.join('\n')
						})
						notification.error({
							message: 'Invalid Data',
							description: errors.join('\n'),
							placement: 'bottomLeft'
						})
						return
					}
					const fileName = `${type.name}.csv`
					if (importOnly) {
						setImportedValues(sheets)
						setFile({
							name: fileName
						})
					} else {
						const file = await parseResults({ fileName, validData: sheets })
						setFile(file)
					}
					await api.jobs.ack(jobId, {
						info: 'File uploaded.',
						progress: 50
					})
					await api.jobs.complete(jobId, {
						info: 'File uploaded.',
						progress: 100
					})
					setShowSpace(false)
				} catch (error) {
					await api.jobs.fail(jobId, {
						error: error.message
					})
				}
			})
		})
	})

	const Space = ({ setShowSpace }) => {
		setIsSpaceLoading(true)
		const space = useSpace({
			...spaceProps,
			devMode: isDev(),
			allowCustom,
			loading,
			workbook: getWorkbook(type, fields),
			listener,
			sidebarConfig: {
				showSidebar: false
			},
			themeConfig: {
				root: {
					primaryColor: '#278EA5',
					buttonBorderRadius: '0px',
					badgeBorderRadius: '0px',
					checkboxBorderRadius: '0px',
					interactiveBorderRadius: '0px',
					modalBorderRadius: '0px',
					pillBorderRadius: '0px',
					popoverBorderRadius: '0px'
				}
			},
			closeSpace: {
				operation: 'submitActionFg',
				onClose: () => setShowSpace(false)
			}
		})
		setIsSpaceLoading(false)
		return space
	}

	const renderModalFooter = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
				<Button
					disabled={isSpaceLoading}
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					type='primary'
					loading={isSpaceLoading}
					disabled={!file}
					onClick={isFinished ? onOk : onUploadFile}
				>
					{isUploadingCsv ? 'Validating' : isSpaceLoading ? 'Uploading...' : isFinished ? 'OK' : importOnly ? 'Import' : 'Upload'}
				</Button>
			</div>
		)
	}

	return (
		<Modal
			title={title}
			visible={visible}
			onCancel={onCancel}
			maskClosable={false}
			closable={!isSpaceLoading}
			width={showSpace && !isLoading ? 1440 : 720}
			footer={renderModalFooter()}
		>
			<div>
				<div className={styles.uploadContainer}>
					<div style={{ display: 'flex' }}>
						<div className={styles.item}>
							<div>
								<span style={{ marginRight: 12 }}><b>1.</b></span>
								<span>{firstStepPrompt}</span>
							</div>
							<Button
								onClick={downloadCsv}
								loading={isDownloadingCsv}
								style={{ marginTop: 24 }}
								icon={<DownloadOutlined />}
							>
								Download CSV
							</Button>
						</div>
						<div className={styles.item}>
							<div>
								<span style={{ marginRight: 12 }}><b>2.</b></span>
								<span>{secondStepPrompt}</span>
							</div>
						</div>
					</div>
					{
						fields.length > 0 &&
						<div className={styles.uploadItem}>
							<div>
								<div>
									<span style={{ marginRight: 12 }}><b>3.</b></span>
									<span>Start importing your data.</span>
								</div>
								<div className={styles.import}>
									<Button
										onClick={() => setShowSpace(true)}
										type='primary'
										style={{ marginLeft: 8 }}
										loading={isLoading}
										disabled={file !== undefined}
									>
									Get Started
									</Button>
									<div className={styles.flatFile}>
										{
											showSpace &&
											<Space setShowSpace={setShowSpace} />
										}
									</div>
									{
										file &&
									<div className={styles.file}>
										{file.name}
									</div>
									}
								</div>
							</div>
						</div>
					}
				</div>
				{
					taskId &&
					<Progress percent={progress} format={percent => `${Math.round(percent)} %`} size='small' status={status} style={{ marginTop: 24 }} />
				}
				{
					logs && logs.length > 0 ?
						<div className={styles.output}>
							{
								logs.map((data, index) => {
									return (
										<div key={index} className={styles.outputRow}>
											{data}
										</div>
									)
								})
							}
						</div> : null
				}
				{
					logs && logs.length > 0 ?
						<Button
							style={{ marginLeft: 'auto', marginTop: 24 }}
							size='small'
							onClick={onDownloadErrorRows}
							loading={isDownloadingErrorCsv}
							disabled={!taskId}
							icon={<DownloadOutlined />}
						>
							Download Error Rows
						</Button> : null
				}
			</div>
		</Modal>
	)
}

export default UploadCsvModal
