
import { useEffect } from 'react'

export let amplitude

const useAmplitudeInit = () => {
	useEffect(() => {
		const initAmplitude = async () => {
			amplitude = await import('@amplitude/analytics-browser')
			amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, undefined, {
				logLevel: amplitude.Types.LogLevel.Warn,
				defaultTracking: {
					sessions: true
				}
			})
		}
		initAmplitude()
	}, [])
}

export default useAmplitudeInit
