import { Button, notification } from 'antd'
import { useState } from 'react'
import PropTypes from 'prop-types'
import TextArea from '../text-area'

const InternalNotesInput = ({ id, notes, addNote, onSave }) => {
	const [isInternalNotesEditFieldVisible, setIsInternalNotesEditFieldVisible] = useState(addNote)
	const [internalNotes, setInternalNotes] = useState(notes)
	const [isSaving, setIsSaving] = useState(false)

	const saveNote = async () => {
		try {
			setIsSaving(true)
			await onSave(id, internalNotes)
			setIsInternalNotesEditFieldVisible(false)
		} catch (e) {
			notification.error({
				message: 'Unable to Save Internal Notes',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSaving(false)
		}

	}

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			{
				isInternalNotesEditFieldVisible || addNote ?
					<div style={{ width: '100%' }}>
						<TextArea
							title='Internal Notes'
							value={internalNotes}
							onChange={({ target: { value } }) => {
								setInternalNotes(value)
							}}
						/>
					</div> :
					<div style={{ width: '100%' }}>
						<h4>Internal Notes</h4>
						<span>
							{internalNotes}
						</span>
					</div>
			}
			<div style={{ marginLeft: 12 }}>
				<Button
					type='primary'
					style={{
						fontSize: 12,
						padding: '4px 12px'
					}}
					loading={isSaving}
					onClick={async () => {
						if (addNote || isInternalNotesEditFieldVisible) {
							saveNote()
						} else {
							setIsInternalNotesEditFieldVisible(true)
						}
					}}
				>
					{addNote || isInternalNotesEditFieldVisible ? 'Save' : 'Edit'}
				</Button>
			</div>
		</div>
	)
}

InternalNotesInput.propTypes = {
	id: PropTypes.string,
	notes: PropTypes.string,
	addNote: PropTypes.bool,
	onSave: PropTypes.func
}

InternalNotesInput.defaultProps = {
	id: '',
	notes: '',
	addNote: false,
	onSave: () => {}
}

export default InternalNotesInput
