import { getFormattedCurrency } from '../../utils'

export const steadFastDeliveryColumns = [
	{
		title: 'Invoice',
		key: 'Invoice',
		render: (data) => {
			return data['Invoice']
		}
	},
	{
		title: 'Name',
		key: 'Name',
		render: (data) => {
			return data['Name']
		}
	},
	{
		title: 'Address',
		key: 'Address',
		render: (data) => {
			return data['Address']
		}
	},
	{
		title: 'Phone',
		key: 'Phone',
		render: (data) => {
			return data['Phone']
		}
	},
	{
		title: 'Amount',
		key: 'Amount',
		render: (data) => {
			return getFormattedCurrency(data['Amount'])
		}
	},
	{
		title: 'Note',
		key: 'Note',
		render: (data) => {
			return data['Note']
		}
	}
]
