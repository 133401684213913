import { Select, Divider } from 'antd'
import styles from './CustomSelect.module.css'
require('./CustomSelect.less')
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { convertStringToEnum } from '../../utils'
import TextInput from '../text-input'

const { Option, OptGroup } = Select

const CustomSelect = ({
	options,
	title,
	style,
	defaultValue,
	placeholder,
	value,
	onChange,
	addItem,
	loading,
	small,
	disabled,
	labelInValue,
	allowClear,
	disabledOptions, // Map of options to disable,
	optGroupLabel,
	unclipOptions,
	popupContainer,
	showSearch,
	searchValue,
	onSearch,
	filterOption,
	filterSort
}) => {
	const [addItemInputValue, setAddItemInputValue] = useState()
	const [currentOptions, setCurrentOptions] = useState(options)

	useEffect(() => {
		setCurrentOptions(options)
	}, [options])

	const onAddItemClick = () => {
		if (addItemInputValue) {
			const newItemValue = convertStringToEnum(addItemInputValue)
			if (currentOptions.findIndex(option => option.value === newItemValue) === -1) {
				const newOptions = [...currentOptions, {
					value: newItemValue,
					label: addItemInputValue
				}]
				setCurrentOptions(newOptions)
				setAddItemInputValue(null)
			}
		}
	}

	return (
		<div className={styles.customSelectContainer}>
			<p className={styles.customSelectTitle}>{title}</p>
			<Select
				style={style}
				loading={loading}
				placeholder={placeholder}
				className={`${small ? disabled ? 'custom-select-2-small-disabled' : 'custom-select-2-small' :
					disabled ? 'custom-select-2-disabled' : 'custom-select-2'} ${title && 'select-title-visible'} ${showSearch && title && 'select-title-search-visible'}`}
				defaultValue={defaultValue}
				disabled={disabled}
				labelInValue={labelInValue}
				value={value}
				onChange={onChange}
				allowClear={allowClear}
				dropdownRender={menu => {
					return (
						<div>
							{menu}
							{
								addItem ?
									<Divider style={{ margin: '4px 0' }} /> : null
							}
							{
								addItem ?
									<div className='add-item-option'>
										<TextInput
											value={addItemInputValue}
											onChange={e => {
												let text = e.target.value.toLowerCase()
												text = text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
												setAddItemInputValue(text)
											}}
										/>
										<div className='add-item-container' onClick={onAddItemClick}>
											<PlusOutlined />
											<div className='text'>Add</div>
										</div>
									</div> : null
							}
						</div>
					)
				}}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
				showSearch={showSearch}
				searchValue={searchValue}
				onSearch={onSearch}
				filterOption={filterOption}
				filterSort={filterSort}
			>
				{
					optGroupLabel ?
						<OptGroup label={optGroupLabel}>
							{
								currentOptions.map(option => {
									return (
										<Option
											className={unclipOptions ? 'full-custom-option' : ''}
											key={option.value}
											value={option.value}
											disabled={disabledOptions[option.value]}
										>{option.label}
										</Option>
									)
								})
							}
						</OptGroup> :
						currentOptions.map(option => {
							return (
								<Option
									className={unclipOptions ? 'full-custom-option' : ''}
									key={option.value}
									value={option.value}
									disabled={disabledOptions[option.value]}
								>{option.label}
								</Option>
							)
						})
				}
			</Select>
		</div>
	)
}

CustomSelect.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.node,
	style: PropTypes.object,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]))]),
	placeholder: PropTypes.node,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]))]),
	onChange: PropTypes.func,
	addItem: PropTypes.bool,
	loading: PropTypes.bool,
	small: PropTypes.bool,
	disabled: PropTypes.bool,
	labelInValue: PropTypes.bool,
	allowClear: PropTypes.bool,
	disabledOptions: PropTypes.object,
	optGroupLabel: PropTypes.node,
	unclipOptions: PropTypes.bool,
	popupContainer: PropTypes.func,
	showSearch: PropTypes.bool,
	searchValue: PropTypes.string,
	onSearch: PropTypes.func,
	filterOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	filterSort: PropTypes.func
}

CustomSelect.defaultProps = {
	options: [],
	disabledOptions: {},
	unclipOptions: false
}

export default CustomSelect
