import PropTypes from 'prop-types'
import { Cascader, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './CustomCascader.module.css'
require('./CustomCascader.less')

const CustomCascader = ({
	options,
	defaultValue,
	placeholder,
	value,
	onChange,
	loadData,
	allowClear,
	className,
	style,
	dropdownRender,
	changeOnSelect,
	popupContainer,
	expandTrigger,
	loading,
	disabled,
	title,
	displayRender,
	error
}) => {
	return (
		title ?
			<div className={styles.CustomCascaderContainer}>
				<p className={styles.customCascaderTitle}>{title}</p>
				{
					loading ?
						<div className={styles.customCascaderLoading}>
							<Spin size='small' indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
						</div> : null
				}
				<Cascader
					changeOnSelect={changeOnSelect}
					dropdownRender={dropdownRender}
					allowClear={allowClear}
					placeholder={placeholder}
					className={`custom-cascader-with-title ${className}`}
					expandTrigger={expandTrigger}
					defaultValue={defaultValue}
					value={value}
					disabled={disabled || loading }
					options={options}
					onChange={onChange}
					loadData={loadData}
					getPopupContainer={() => popupContainer ? popupContainer() : document.body}
					displayRender={displayRender}
					style={{ ...style, width: '100%' }}
				/>
			</div> :
			<Cascader
				changeOnSelect={changeOnSelect}
				dropdownRender={dropdownRender}
				allowClear={allowClear}
				placeholder={placeholder}
				className={`custom-cascader ${className} ${error(value) ? 'error' : ''}`}
				expandTrigger={expandTrigger}
				defaultValue={defaultValue}
				value={value}
				options={options}
				onChange={onChange}
				loadData={loadData}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
				displayRender={displayRender}
				style={{ ...style, width: '100%' }}
			/>
	)
}

CustomCascader.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object),
	defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	placeholder: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	onChange: PropTypes.func,
	loadData: PropTypes.func,
	allowClear: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.object,
	dropdownRender: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	changeOnSelect: PropTypes.bool,
	popupContainer: PropTypes.func,
	expandTrigger: PropTypes.string,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	title: PropTypes.node,
	displayRender: PropTypes.func,
	error: PropTypes.func
}

CustomCascader.defaultProps = {
	options: [],
	onChange: () => {},
	loadData: () => {},
	expandTrigger: 'click',
	loading: false,
	disabled: false,
	title: '',
	error: () => false
}

export default CustomCascader
