import { Button, Modal, notification } from 'antd'
import Papa from 'papaparse'
import { InfoCircleOutlined } from '@ant-design/icons'
import styles from './ExportDeliveryOrdersModal.module.css'
import { useEffect, useMemo, useState } from 'react'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { PartnerType, dateTimeDisplayFormats } from '../../utils/constants'
import CustomSelectSecondary from '../custom-select-secondary'
import DeliveryPartnerOption from '../delivery-partner-option'
import Input from '../input'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { DeliveryOrders } from '../../services/api/delivery-orders'
import CustomTable from '../table'
import { steadFastDeliveryColumns } from './columns'

const ExportDeliveryOrdersModal = ({ visible, onCancel }) => {
	const [deliveryPartnerOptions, setDeliveryPartnerOptions] = useState([])
	const [selectedDeliveryPartner, setSelectedDeliveryPartner] = useState(null)
	const { preferences } = useSelector(state => state.authReducer)
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [isFetching, setIsFetching] = useState(false)
	const [isExporting, setIsExporting] = useState(false)
	const [isPreviewing, setIsPreviewing] = useState(false)
	const [previewData, setPreviewData] = useState([])
	const [isPreviewMode, setIsPreviewMode] = useState(false)
	const isInvalidForm = useMemo(() => {
		return !selectedDeliveryPartner || !fromDate || !toDate
	}, [fromDate, toDate, selectedDeliveryPartner])

	useEffect(() => {
		getDeliveryPartners()
	}, [])

	const getDeliveryPartners = async () => {
		try {
			setIsFetching(true)
			const { data } = await DeliveryPartners.index({ page: -1 })
			const partnerOptions = []
			for (const deliveryPartner of data.results) {
				if (deliveryPartner.type !== PartnerType.CUSTOM) {
					partnerOptions.push({ label: deliveryPartner.name, value: deliveryPartner.id, type: deliveryPartner.type })
				}
			}
			setDeliveryPartnerOptions(partnerOptions)
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Delivery Partners',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFetching(false)
		}
	}

	const isDateTimeFormat = preferences?.dateTimeDisplayFormat === dateTimeDisplayFormats.DATE_TIME
	const onPreview = async () => {
		try {
			setIsPreviewing(true)
			const response = await DeliveryOrders.fetchCSV({
				partnerId: selectedDeliveryPartner,
				fromDate: isDateTimeFormat ? fromDate : moment(fromDate).startOf('day').toDate(),
				toDate: isDateTimeFormat ? toDate : moment(toDate).endOf('day').toDate(),
				preview: true
			})
			const results = []
			const stripBom = (str) => {
				if (str.charCodeAt(0) === 0xfeff) {
					return str.slice(1)
				}
				return str
			}
			let errorMessage = ''
			Papa.parse(response.data, {
				header: true,
				download: false,
				step: (row) => {
					const data = Object.fromEntries(
						Object.entries(row.data).map(([k, v]) => [stripBom(k), v])
					)
					results.push(data)
				},
				complete: () => {
					setPreviewData(results)
					setIsPreviewMode(true)
				},
				error: () => {
					setPreviewData([])
					errorMessage = 'Failed parsing data for the preview.'
				}
			})
			if (errorMessage) {
				throw new Error(errorMessage)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Preview Delivery Orders.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsPreviewing(false)
		}
	}

	const onExport = async () => {
		try {
			setIsExporting(true)
			await DeliveryOrders.downloadCSV({
				partnerId: selectedDeliveryPartner,
				fromDate: isDateTimeFormat ? fromDate : moment(fromDate).startOf('day').toDate(),
				toDate: isDateTimeFormat ? toDate : moment(toDate).endOf('day').toDate()
			})
			onCancel(true)
		} catch (e) {
			notification.error({
				message: 'Unable to Export CSV',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsExporting(false)
		}
	}

	const renderModalFooter = () => {
		return (
			<div className={styles.buttonContainer}>
				<Button onClick={onCancel} size='small'>Cancel</Button>
				{
					isPreviewMode ?
						<Button
							size='small'
							onClick={() => setIsPreviewMode(false)}
						>
							Back
						</Button> :
						<Button
							size='small'
							disabled={isInvalidForm}
							loading={isPreviewing}
							onClick={onPreview}
						>
							Preview
						</Button>
				}
				<Button
					type='primary'
					size='small'
					loading={isExporting}
					disabled={isInvalidForm}
					onClick={onExport}
				>
					Export as CSV
				</Button>
			</div>
		)
	}

	const renderForm = () => {
		return (
			<div>
				<CustomSelectSecondary
					title='Delivery Partner'
					loading={isFetching}
					optionLabelProp='label'
					options={deliveryPartnerOptions}
					value={selectedDeliveryPartner}
					onChange={setSelectedDeliveryPartner}
					allowClear={true}
					optionRender={DeliveryPartnerOption}
				/>
				<div className={styles.formItem}>
					<p className={styles.title}>In-Transit Date Range</p>
					<div className={styles.dateRangeInputsContainer}>
						<Input
							style={{ background: '#F7F7F7', borderRadius: '0px' }}
							type='date'
							title='Start Date'
							showTime={isDateTimeFormat}
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Input
							style={{ background: '#F7F7F7', borderRadius: '0px' }}
							type='date'
							title='End Date'
							showTime={isDateTimeFormat}
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</div>
				</div>
				<div className={styles.formItem}>
					<div className={styles.infoContainer}>
						<span><InfoCircleOutlined /></span>
						<p>The exported order will not be automatically placed in the merchant panel and the delivery status will not be updated.</p>
					</div>
				</div>
			</div>
		)
	}

	const renderPreview = () => {
		return (
			<div>
				<CustomTable
					title={() => `Total Order Count: ${previewData.length}`}
					columns={steadFastDeliveryColumns}
					dataSource={previewData}
					pagination={false}
					scroll={{ y: 'calc(100vh - 340px)' }}
					size='small'
					rowKey='invoice'
				/>
			</div>
		)
	}

	return (
		<Modal
			title={isPreviewMode ? 'Preview Export Delivery Orders' : 'Export Delivery Orders'}
			width={isPreviewMode ? '85%' : '470px'}
			visible={visible}
			onCancel={onCancel}
			footer={renderModalFooter()}
		>
			{
				isPreviewMode ? renderPreview() : renderForm()
			}
		</Modal>
	)
}

export default ExportDeliveryOrdersModal
